import styled from 'styled-components';

export const Card = styled.div`
  width: 30rem;
  height: 15rem;
  background: ${(props) => `rgba(${props.theme.colors.primaryDarkRgb}, 0.9)`};

  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 2rem;

  border-radius: 0.2rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
  /* padding: 1.5rem 1rem; */

  /* display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem;
  width: 18rem;
  height: 22rem;
  background: ${(props) => `rgba(${props.theme.colors.primaryDarkRgb}, 0.9)`};
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3); */

  img {
    width: 10rem !important;
    height: 15rem !important;
    margin-right: -0.09rem;
    margin-top: -0.09rem;
    /* border-radius: 50%; */
    /* border: 2px solid var(--white); */
    object-fit: cover;
    align-self: center;
    /* margin: 1rem; */
  }

  h3 {
    font-size: 20px;
    color: var(--white);
    text-align: center;

    padding-right: 2rem;
    padding-left: 2rem;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
  }

  p {
    margin-top: 5px;
    min-height: 3rem;
    margin-bottom: 0px;
    text-align: center;
    font-size: 14px;
    color: #fff;

    padding-right: 2rem;
    padding-left: 2rem;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
  }

  .div__dados {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .div__content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .buttons {
    display: flex;
    flex: 1;
    margin-right: -0.09rem;
    margin-top: -2rem;

    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    button {
      display: flex;
      height: 3rem;
      width: 3.5rem;
      border: 0;
      border-radius: 0;
      background: ${(props) => `rgb(${props.theme.colors.primaryRgb})`};
      justify-content: center;
      align-items: center;

      color: rgba(0, 0, 0, 0.75);

      &:first-child {
        border-radius: 1rem 0 0 1rem;
      }
      &:last-child {
        border-radius: 0 0 1rem 0;
      }

      svg {
        justify-content: center;
        align-items: center;
        color: ${(props) => props.theme.colors.white} !important;
      }

      :hover {
        background: ${(props) => props.theme.colors.gray};

        &.delete {
          background: ${(props) => props.theme.colors.delete} !important;
          svg {
            color: ${(props) => props.theme.colors.white} !important;
          }
        }

        svg {
          color: ${(props) => props.theme.colors.primaryLight} !important;
        }
      }
    }
  }
`;
