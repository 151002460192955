import { addDays, format } from 'date-fns';

export function dashboard(period, enrolled) {
  // numero de inscritos
  let number_enrolled = enrolled.length;

  // numero de inscritos que participaram
  let number_enrolled_participants = enrolled.filter(
    enrollment => enrollment.participated === true,
  ).length;

  // numero de inscritos que não participaram
  let number_enrolled_not_participants =
    number_enrolled - number_enrolled_participants;

  // porcentagem de inscritos que participaram
  let percentageOfParticipants =
    ((number_enrolled_participants / number_enrolled) * 100).toFixed(2) + '%';
  percentageOfParticipants =
    percentageOfParticipants === 'NaN%' ? '0.00%' : percentageOfParticipants;

  // porcentagem de inscritos que não participaram
  let percentageOfNotParticipants =
    (
      ((number_enrolled - number_enrolled_participants) / number_enrolled) *
      100
    ).toFixed(2) + '%';
  percentageOfNotParticipants =
    percentageOfNotParticipants === 'NaN%'
      ? '0.00%'
      : percentageOfNotParticipants;

  // inscritos por dia
  let enrollmentsPerDay = []; // dia mais antigo => para o atual
  for (let index = period + 1; index >= 0; index--) {
    const enrollmentPerDay = enrolled.filter(
      enrollment =>
        format(new Date(enrollment.created_at), 'yyyy-MM-dd') ===
        format(addDays(new Date(), -index), 'yyyy-MM-dd'),
    );

    enrollmentsPerDay.push(enrollmentPerDay.length);
  }

  return {
    number_enrolled,
    number_enrolled_participants,
    number_enrolled_not_participants,
    percentageOfParticipants,
    percentageOfNotParticipants,
    enrollmentsPerDay,
  };
}
