import React, { useCallback, useEffect, useState } from 'react';

import { Container } from './styles';

// icones
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';

// dropdown
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Backdrop from '../../../components/Backdrop';

import format from 'date-fns/format';

// api
import api from '../../../services/api';

import Dialogodeconfirmacao from '../../../components/Dialogodeconfirmacao';
import Novapalestra from '../../../components/Novapalestra';

function Palestras(props) {
  const [backdrop, setBackdrop] = useState(false);
  const [confirmacao, setConfirmacao] = useState([false, '']);
  const [listaeventos, setListaeventos] = useState([]);

  const [filtro, setFiltro] = React.useState({
    tipo: '',
    descricao: '',
  });

  const [dialogoApoiador, setDialogoApoiador] = React.useState({
    status: false,
    editar: false,
    dados: '',
  });

  // chamada api
  const [talks, setTalks] = useState([]);

  const getTalks = useCallback(async () => {
    setBackdrop(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    await api
      .get(`/talks`, config)
      .then((response) => {
        response.data.sort(function (a, b) {
          return a.title.localeCompare(b.title);
        });
        // console.log(response.data);
        setTalks(response.data);
        setBackdrop(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, [props.user.token, setBackdrop]);

  const removeTalks = async (id) => {
    setBackdrop(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    await api
      .delete(`/talks/${id}`, config)
      .then((response) => {
        setBackdrop(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };
  //fim

  const atualizarEventos = useCallback(
    (e) => {
      setBackdrop(true);
      const config = {
        headers: {
          Authorization: 'Bearer ' + props.user.token,
          'Content-Type': 'application/json',
        },
      };
      api
        .get('events', config)
        .then((a) => {
          setListaeventos([...a.data.nextEvents, ...a.data.previousEvents]);
          setBackdrop(false);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    [props.user.token, setBackdrop]
  );

  const filtroAplicado = (e) => {
    if (filtro.tipo && filtro.descricao) {
      if (filtro.tipo === 'evento') {
        if (e.event_id === filtro.descricao) return e;
      }
      if (filtro.tipo === 'palestrante') {
        if (
          e.objSpeaker.name
            .toLowerCase()
            .indexOf(filtro.descricao.toLowerCase()) > -1
        )
          return e;
      }
    } else {
      return true;
    }
  };

  var lista2 = talks.filter(filtroAplicado);

  lista2.sort(function (a, b) {
    // console.log(a.title, b.title);
    return a.title.localeCompare(b.title);
  });

  useEffect(() => {
    getTalks();
    if (listaeventos.length === 0) atualizarEventos();
  }, [getTalks, listaeventos.length, atualizarEventos]);

  return (
    <Container>
      <header>
        <h1>Programações</h1>
        <p>
          Adicione ou exclua programações que poderão ser vinculadas a eventos.
        </p>
      </header>
      <div className="funcoesdefiltro">
        <div className="filtros">
          <FormControl
            variant=""
            margin="dense"
            style={{
              margin: '0rem 1rem',
              marginTop: '-0.0rem',
              width: '200px',
              textAlign: 'left',
            }}
          >
            <InputLabel id="id-selctor-zone">{'Filtrar'}</InputLabel>
            <Select
              labelId="id-selctor-zone"
              id="id-selctor-zone-select"
              value={filtro.tipo}
              onChange={(e) =>
                setFiltro({ ...filtro, tipo: e.target.value, descricao: '' })
              }
              label="Tipo"
            >
              <MenuItem value="" default>
                <em>Nenhum</em>
              </MenuItem>
              {/* <MenuItem value={'evento'}>Evento</MenuItem> */}
              <MenuItem value={'palestrante'}>Palestrante</MenuItem>
            </Select>
          </FormControl>

          {filtro.tipo === 'evento' ? (
            <FormControl
              margin="dense"
              style={{
                margin: '0rem 0rem',
                marginTop: '0.0rem',
                width: '250px',
              }}
            >
              <InputLabel id="demo-simple-select-outlined-label-tipo">
                {'Selecionar'}
              </InputLabel>
              <Select
                labelId="demo-curso-select-outlined-label-tipo"
                id="demo-simple-curso-outlined-tipo"
                value={filtro.descricao}
                onChange={(e) => {
                  setFiltro({ ...filtro, descricao: e.target.value });
                }}
                label="evento"
                // style={{padding:'0rem 1rem'}}
              >
                <MenuItem value="">
                  <em>Nenhum</em>
                </MenuItem>
                {}
                {listaeventos.map((e, i) => (
                  <MenuItem value={e.id}>
                    {e.start_date
                      ? format(new Date(e.start_date), 'dd/MM/yyyy') +
                        ' - ' +
                        e.city
                      : '(Sem Data) - ' + e.city}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <TextField
              onChange={(e) => {
                setFiltro({ ...filtro, descricao: e.target.value });
              }}
              value={filtro.descricao}
              id="outlined-basic"
              label={filtro.tipo === 'palestrante' ? 'Buscar' : ' - '}
              disabled={filtro.tipo ? false : true}
              margin="dense"
              style={{
                margin: '0rem 0rem',
                marginLeft: '0rem',
                width: '250px',
              }}
            />
          )}
        </div>

        <div className="MenuDireita">
          <button
            className="cadastroManual"
            onClick={() =>
              setDialogoApoiador({ status: true, editar: false, dados: '' })
            }
          >
            {' '}
            + Nova Programação
          </button>
        </div>
      </div>

      <div className="cards-area">
        <div className="card-title">
          <h3 style={{ flex: '0.5' }}>Tipo</h3>
          <h3 style={{ flex: '2' }}>Título</h3>
          <h3 style={{ flex: '2' }}>Palestrante/Moderador</h3>

          <h3 className="options" style={{ flex: '1', textAlign: 'right' }}>
            Opções
          </h3>
        </div>
        {lista2.length > 0 ? (
          lista2.map((talk, i) => (
            <div className={i === 0 ? 'card first' : 'card'} key={talk.id}>
              <div style={{ flex: '0.5', display: 'flex' }}>
                <span className={talk.type}>{talk.type}</span>
              </div>
              <h3>{talk.title || 'Palestra sem título'}</h3>
              <strong>
                <span>{talk.objSpeaker.name}</span>
              </strong>

              <div className="buttons">
                <button
                  className="edit"
                  onClick={async (e) => {
                    setDialogoApoiador({
                      status: true,
                      editar: true,
                      dados: talk,
                    });
                  }}
                >
                  <CreateIcon style={{ color: '#555' }} />
                </button>

                <button
                  className="delete"
                  onClick={(e) => {
                    setConfirmacao([true, talk.id]);
                  }}
                >
                  <DeleteIcon style={{ color: '#f44336' }} />
                </button>
              </div>
            </div>
          ))
        ) : (
          <h2>Nenhuma programação encontrada !</h2>
        )}
      </div>

      {confirmacao[0] ? (
        <Dialogodeconfirmacao
          tamanho="xs"
          message="Tem certeza de que deseja apagar esta palestra?"
          confirmar={async () => {
            await removeTalks(confirmacao[1]);
            await getTalks();
            setConfirmacao([false, '']);
          }}
          negar={() => setConfirmacao([false, ''])}
        />
      ) : (
        ''
      )}
      {dialogoApoiador.status ? (
        <Novapalestra
          user={props.user}
          editar={dialogoApoiador.editar}
          dados={dialogoApoiador.dados}
          id={dialogoApoiador.id}
          tamanho="xs"
          negar={() =>
            setDialogoApoiador({ status: false, editar: false, dados: '' })
          }
          atualizar={async () => {
            setDialogoApoiador({ status: false, editar: false, dados: '' });
            await getTalks();
          }}
        />
      ) : (
        ''
      )}
      {backdrop && <Backdrop />}
    </Container>
  );
}

export default Palestras;
