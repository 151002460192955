import React from 'react';
import { Container } from 'react-bootstrap';


import Menu from '../../../components/Menu';

function logado(props) {
    

      const fazerLogOff =e=>{
            props.logOff();
      }
  return (
        <Container>
              <Menu user={props.user} logOff={fazerLogOff} abrir={()=>props.abrir()}/>
        </Container>
    );
}

export default logado;