import styled from 'styled-components';

export const Container = styled.div`
  /* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  
  font-family: 'Fira Sans', sans-serif !important;

  .realizadoComSucesso {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 1rem;
    margin: auto;
    text-align: center;
    font-size: 18px;

    h3 {
      margin-top: 1rem;
    }

    .divisaoTitu {
      margin-top: 1rem;
      height: 2px;
      width: 200px;
      background-color: var(--primary);
      border-radius: 30px;
    }

    .icones {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 1rem;

      svg {
        font-size: 35px;
        color: ${(props) => props.theme.colors.primaryDark};
      }
    }

    button {
      margin-top: 2rem;
      border: 1px solid ${(props) => props.theme.colors.primaryDark};
      color: ${(props) => props.theme.colors.primaryDark};
      background-color: #ffffff;
      border-radius: 20px;
      padding: 0.5rem 2rem;
      font-size: 16px;
    }
    button:hover {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    }
  }
  form {
    display: flex;
    flex-direction: column;

    h5 {
      text-align: center;
    }
    .tipoDeInscrito {
      display: flex;
      flex-direction: row;
      margin: auto;
      margin-bottom: 3rem;

      input[type='radio'] {
        
        visibility: hidden;
        display: none;
      }

      .cadSelect {
        border-bottom: 2px solid ${(props) =>
    props.theme.colors.primaryDark} !important;
        color: var(--primary) !important;
      }

      .tipoVisitante {
        label {
          border-top: 1px solid rgba(0, 0, 0, 0.2);
          border-left: 1px solid rgba(0, 0, 0, 0.2);
          border-bottom: 2px solid rgba(0, 0, 0, 0.2);
          border-right: 1px solid rgba(0, 0, 0, 0.2);
        }
      }
      .tipoExpositor {
        label {
          border-top: 1px solid rgba(0, 0, 0, 0.2);
          
          border-bottom: 2px solid rgba(0, 0, 0, 0.2);
          border-right: 1px solid rgba(0, 0, 0, 0.2);
        }
      }
      .tipoVisitante,
      .tipoExpositor {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        justify-items: center;

        label {
          cursor: pointer;
          margin: 0rem;
          padding: 1rem 0rem;
          flex: 1;
          font-size: 18px;
          text-align: center;
          align-items: center;
          justify-content: center;
        }
        label:select {
          background-color: #000;
        }
      }
    }

    label {
      margin: 1rem 1rem 0.5rem 0.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      text-transform: uppercase;
      font-size: 14px;
      svg {
        font-size: 15px;
        margin-top: -0.1rem;
      }
    }
    .reparticao {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      input,
      select,
      label {
        flex: 1;
        padding: 0.5rem 0.5rem;
        margin: 0.5rem 0.5rem;
        font-style: italic;
        border: 1px solid #e6e6e6;
      }
    }
  }

  .cancelar {
    border: 1px solid #606062;
    color: #606062;
  }
  .confirmar {
    border-color: 1px solid ${(props) => props.theme.colors.primaryDark};
    background-color: ${(props) => props.theme.colors.primaryDark};
    color: #ffffff;
  }
  .confirmar:hover {
    background-color: ${(props) => props.theme.colors.primaryDark};
    opacity: 0.8;
    color: #ffffff;
  }

  @media only screen and (max-width: 675px) {
    margin: 0rem;
    form {
      display: flex;
      flex-direction: column;

      select {
        width: 100%;
        padding: 0rem !important;
        margin: 0.3rem 0rem !important;
      }
      .reparticao {
        flex-direction: column;

        input {
          width: 100%;
        }
      }
    }
    input {
      margin: 0.3rem 0rem !important;
      width: 100%;
    }

    .ladoalado {
      flex-direction: row !important;
      label {
        flex-direction: column !important;
      }
    }
  } */

  .div__send_confirmation {
    width: 100%;
    text-align: center;
    padding: 1rem 2rem;
    height: 100%;

    .div__contact_medias_field {
      display: flex;
      flex-direction: column;
      .div__oneRow {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
      }
    }
  }

  .div__file_field {
    display: flex;
    flex-direction: row;
    border: 1px dotted rgba(0, 0, 0, 0.4);
    border-left: 0px;
    margin-left: -1rem;
    color: rgba(0, 0, 0, 0.6);
    padding: 1rem;
    gap: 1rem;
    cursor: pointer;
    align-items: center;

    h5 {
      max-width: 50px;
      margin: 0rem;
    }
    :hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }
  .div__header_dialog {
    /* background: orange; */
    display: flex;
    padding: 1rem;
    gap: 1rem;
    align-items: center;

    h2 {
      margin: 0rem;
      color: ${(props) => props.theme.colors.primary};
    }

    .btn__return {
      display: flex;
      align-items: center;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.colors.primary};
      background: transparent;
      border: 1px solid ${(props) => props.theme.colors.primary};
      border-radius: 5rem;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      cursor: pointer;
      :hover {
        background-color: ${(props) => props.theme.colors.primary};
        color: white;
      }
    }
  }

  .div__oneColumn {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    select {
      -webkit-appearance: none;
    }
  }

  .div__oneRow {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  /* flex list  */
  .flex1 {
    flex: 1;
  }
  .flex2 {
    flex: 2;
  }
  .flex3 {
    flex: 3;
  }
  .flex4 {
    flex: 4;
  }

  form {
    max-width: 1920px;
    padding: 1rem;
    margin: auto;

    h3 {
      color: ${(props) => props.theme.colors.primary};
      margin: 0rem;
      margin-top: 2rem;
      padding: 0rem;
      font-weight: 600;
    }
    .margemDoH3 {
      height: 2px;
      /* background-color:orange; */
      width: 40px;
      align-items: flex-end;
      /* margin: auto; */
      margin-top: 0rem;
      /* margin-bottom: 4rem; */
      border-bottom: 2px dashed orange;
    }

    label {
      display: flex;
      flex-direction: column;
      color: ${(props) => props.theme.colors.primary};
      span {
        padding: 0.25rem 0.5rem;
      }
      input,
      select {
        outline-color: ${(props) => props.theme.colors.primary};
        padding: 0.75rem 1rem;
        font-size: 1rem;
        border-radius: 5rem;
        border: 0rem;
        background: rgba(0, 0, 0, 0.075);
        border: 1px solid rgba(0, 0, 0, 0.05);
        /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1); */
      }
    }

    .div__use_terms {
      display: flex;
      flex-direction: row;
      /* border: 1px dotted ${(props) => props.theme.colors.primary}; */
      border: 1px dotted rgba(0, 0, 0, 0.4);
      color: rgba(0, 0, 0, 0.6);
      padding: 1rem;
      gap: 1rem;
      cursor: pointer;
    }
    .selected_field {
      border: 1px dotted ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.primary};
      font-weight: 600;
    }

    .footer_button {
      display: flex;
      justify-content: flex-end;

      .btn__register {
        background-color: ${(props) => props.theme.colors.primary};
        color: #fff;
        border: 0rem;
        border-radius: 5rem;
        font-size: 1rem;
        padding: 0.8rem 1.25rem;
        cursor: pointer;

        :hover {
          opacity: 0.9;
        }
      }
    }
  }

  .special_field {
    label {
      color: orange;
      font-weight: 600;
    }
    select {
      border: 1px solid orange;
      outline-color: orange;
      -webkit-appearance: none;
    }
  }

  .cancelar {
    border: 1px solid #606062;
    color: #606062;
  }
  .confirmar {
    border-color: 1px solid ${(props) => props.theme.colors.primaryDark};
    background-color: ${(props) => props.theme.colors.primaryDark};
    color: #ffffff;
  }
  .confirmar:hover {
    background-color: ${(props) => props.theme.colors.primaryDark};
    opacity: 0.8;
    color: #ffffff;
  }

  @media screen and (max-width: 675px) {
    .div__oneRow {
      flex-direction: column;
    }
    .div__file_field {
      margin-left: 0rem;
      border: 1px dotted rgba(0, 0, 0, 0.4);
      border-top: 0px;
      margin-top: -0.9rem;
      justify-content: center;
      align-items: center;
      h5 {
        max-width: 500px;
      }
    }
  }
`;
