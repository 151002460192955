import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  padding: 1rem 2rem;
  min-height: ${window.innerHeight};

  header {
    padding-bottom: 1rem;
    border-bottom: 1px solid #d3d3d3;

    h1 {
      font-weight: 800;
      color: rgba(0, 0, 0, 0.8);
    }

    p {
      color: rgba(0, 0, 0, 0.7);
      font-weight: 600;
    }
  }

  .containerFilter {
    margin-bottom: 1rem;
    h2 {
      color: var(--gray-500);
      border-bottom: 1px solid var(--gray-100);
      margin-bottom: 1rem;
    }

    .filter {
      margin-top: 2rem;
      display: flex;
      padding: 0 0.5rem;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      font-family: 'Inter';
      label {
        font-weight: 100;
        font-size: 1.25rem;
      }

      .item {
        margin-right: 2rem;
      }

      .name {
        input {
          height: 2.5rem;
          padding: 0 0.5rem;

          outline: none;

          /* border: 1px solid var(--gray-200); */
          border-radius: 0.25rem;
        }
      }

      .status {
        height: 2.5rem;

        display: flex;
        align-items: center;

        .checkbox {
          display: flex;
          align-items: center;
          margin: 0.5rem;

          p {
            font-size: 1.125rem;
            margin: 0.25rem;
          }
        }

        select {
          height: 2.5rem;
          padding: 0 0.5rem;

          outline: none;

          border: 1px solid var(--gray-200);
          border-radius: 0.25rem;
        }
      }
    }
  }

  .card-area {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 2rem;
    gap: 1rem;
  }
`;
