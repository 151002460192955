import React, { useState } from 'react';
import { Container, Popmenu } from './styles';
// rotas
import { Prompt } from 'react-router-dom';
// contexto
import { useEvents } from '../../../context/eventsContext';
// material ui
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress, TextField } from '@material-ui/core';
// popover
import Popover from '@material-ui/core/Popover';
// icones
import SearchIcon from '@material-ui/icons/Search';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CloseIcon from '@material-ui/icons/Close';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TodayIcon from '@material-ui/icons/Today';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import SyncIcon from '@material-ui/icons/Sync';
// graficos
import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  LineChart,
  Line,
  ResponsiveContainer,
} from 'recharts';

// date fns
import {
  addDays,
  isAfter,
  isBefore,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachYearOfInterval,
} from 'date-fns';
// api
import API from '../../../services/api';
// temas
import { useTheme } from 'styled-components';

function Dashboard(props) {
  // tema
  const theme = useTheme();
  const { events } = useEvents();
  const [eventSelected, setEventSelected] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [dataevents, setDataevents] = useState();

  // consulta periódica
  const [synchronize, setSynchronize] = useState(false);

  // inscritos
  const [getEnrollmentsByDateInterval, setGetEnrollmentsByDateInterval] =
    useState([]);
  const [dadosenrollmentsdate, setDadosenrollmentsdate] = useState({
    end_date: new Date().toISOString().split('T')[0],
    start_date: new Date(new Date().setDate(new Date().getDate() - 10))
      .toISOString()
      .split('T')[0],
    month: false,
    day: true,
  });

  // popover
  const [auxiliar, setAuxiliar] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClickPop = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePop = () => {
    setAnchorEl(null);
    setTimeout(() => setAuxiliar(), 100);
  };
  const openPop = anchorEl;
  const id = openPop ? 'simple-popover' : undefined;
  // requisições à API
  const config = {
    headers: {
      Authorization: 'Bearer ' + props.user.token,
      'Content-Type': 'application/json',
    },
  };

  // buscar dados do evento
  const getDataEvent = async (get_sync) => {
    !get_sync && setIsFetching(true);

    await API.get(`/dashboard/event-data/${eventSelected?.id}`, config)
      .then((res) => {
        setDataevents(res.data);
      })
      .catch((error) => {
        console.log('[ERROR] => ', error.response.data);
      })
      .finally(() => setIsFetching(false));
  };

  // buscar inscritos do evento
  async function GET_ENROLLMENTS_AMOUNT_BY_DATE(selected_date) {
    setIsFetching(true);
    await API.get(
      `/dashboard/event-enrollments/${eventSelected.id}?start_date=${
        selected_date
          ? selected_date.start_date
          : dadosenrollmentsdate.start_date
      }&end_date=${
        selected_date ? selected_date.end_date : dadosenrollmentsdate.end_date
      }&month=${
        selected_date ? selected_date.month : dadosenrollmentsdate.month
      }&day=${selected_date ? selected_date.day : dadosenrollmentsdate.day}`,
      config
    )
      .then((res) => {
        setGetEnrollmentsByDateInterval(res.data);
      })
      .catch((error) => {
        console.log('erro recibos:', error.response);
      })
      .finally(() => setIsFetching(false));
  }
  // buscar dados de forma periódica
  const getDataEventSync = () => {
    var aux = setInterval(async () => {
      if (
        isAfter(new Date(), new Date(dadosenrollmentsdate.start_date)) &&
        isBefore(
          new Date(),
          addDays(new Date(dadosenrollmentsdate.end_date), 1)
        )
      ) {
        await API.get(
          `/dashboard/event-enrollments/${eventSelected.id}?end_date=${
            // new Date().toISOString().split('T')[0]
            new Date(new Date().setDate(new Date().getDate()))
              .toISOString()
              .split('T')[0]
          }&start_date=${
            // new Date().toISOString().split('T')[0]
            new Date(new Date().setDate(new Date().getDate()))
              .toISOString()
              .split('T')[0]
          }&month=${dadosenrollmentsdate.month}&day=${
            dadosenrollmentsdate.day
          }`,
          config
        )
          .then((res) => {
            var auxIdx = getEnrollmentsByDateInterval.indexOf(
              getEnrollmentsByDateInterval.find(
                (a) => a.date === res.data[0].date
              )
            );
            var auxArray = getEnrollmentsByDateInterval;
            auxArray[auxIdx] = res.data[0];

            setGetEnrollmentsByDateInterval(auxArray);
          })
          .catch((error) => {
            console.log('erro recibos:', error.response);
          });
      }

      await getDataEvent({ get_sync: true });
    }, 5 * 1000);

    setSynchronize(true);
    props.idinterval.current = aux;
  };
  // cancelar busca de dados de forma periódica
  const cancelDataEventSync = () => {
    setSynchronize(false);
    props.cancelDataSync();
  };

  // gerar array com intervalo entre datas
  const getintervalformap = (metod, data) => {
    switch (metod) {
      case 'day':
        return eachDayOfInterval({
          start: addDays(new Date(data?.start_date), 1),
          end: addDays(new Date(data?.end_date), 1),
        });
      case 'month':
        return eachMonthOfInterval({
          start: addDays(new Date(data?.start_date), 1),
          end: addDays(new Date(data?.end_date), 1),
        });
      case 'year':
        return eachYearOfInterval({
          start: addDays(new Date(data?.start_date), 1),
          end: addDays(new Date(data?.end_date), 1),
        });
      default:
        return eachDayOfInterval({
          start: addDays(new Date(data?.start_date), 1),
          end: addDays(new Date(data?.end_date), 1),
        });
    }
  };

  // submeter evento selecionado
  const submitGetEvent = async (event) => {
    event.preventDefault();
    cancelDataEventSync();
    eventSelected && getDataEvent();
    eventSelected && GET_ENROLLMENTS_AMOUNT_BY_DATE();
  };

  // obter porcentagem do valor
  const getPercent = (value, base) => {
    return '(' + ((value / base) * 100).toFixed(2) + '%)';
  };

  return (
    <Container>
      <Prompt
        when={synchronize}
        message={(location, action) => {
          return cancelDataEventSync();
        }}
      />
      <header>
        <h1>Dashboard</h1>
        <p>
          Obtenha <i>feedback</i> dos eventos cadastrados na plataforma.
        </p>
      </header>
      <content>
        <div className="div__part1">
          <form onSubmit={submitGetEvent}>
            <Autocomplete
              id="combo-box-demo"
              className="autoComplete"
              options={events}
              value={eventSelected}
              getOptionLabel={(event) => event.option}
              style={{ width: '600px' }}
              noOptionsText={'Evento não encontrado'}
              onChange={(event, newValue) => {
                setEventSelected(newValue);
                setDataevents();
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecionar evento"
                  variant="standard"
                />
              )}
              clearText="Limpar"
            />

            <button type="submit" className="searchButton">
              <SearchIcon className="searchButtonIcon" />
              Consultar
            </button>
          </form>
        </div>
        {eventSelected && dataevents && (
          <div className="div__part2">
            <div className="div__oneRow" style={{ minWidth: '850px' }}>
              <div className="div__titleEventField">
                <div className="div__infoEventField">
                  <h1>
                    <EventAvailableIcon />
                    {eventSelected?.option_long}
                  </h1>
                  <span>
                    Você está obtendo informações do evento selecionado acima.
                  </span>
                </div>
                <div className="div__eventFieldSync">
                  <button
                    type="button"
                    className={
                      synchronize ? 'btn__syncData_play' : 'btn__syncData'
                    }
                    onClick={() =>
                      synchronize ? cancelDataEventSync() : getDataEventSync()
                    }
                  >
                    <SyncIcon />{' '}
                    {synchronize ? 'Parar sincronização' : 'Sincronizar'}
                  </button>
                </div>
              </div>
            </div>
            <div
              className="div__oneRow"
              style={{ minWidth: '850px', padding: '5px' }}
            >
              <div className="div__deck">
                <div className="div__card" name="card1">
                  <div className="div__oneColumn">
                    <p>{dataevents?.total_enrollments}</p>
                    <h3>Total de</h3>
                    <h3>Inscritos</h3>
                  </div>
                  <div className="div__oneColumn">
                    <ListAltIcon />
                  </div>
                </div>
                <div className="div__card" name="card2">
                  <div className="div__oneColumn">
                    <p>{dataevents?.total_presents}</p>
                    <span>
                      {getPercent(
                        dataevents.total_presents,
                        dataevents.total_enrollments
                      )}
                    </span>
                    <h3>Presentes</h3>
                  </div>
                  <div className="div__oneColumn">
                    <DoneAllIcon />
                  </div>
                </div>
                <div className="div__card" name="card3">
                  <div className="div__oneColumn">
                    <p>{dataevents?.total_not_presents}</p>
                    <span>
                      {getPercent(
                        dataevents.total_not_presents,
                        dataevents.total_enrollments
                      )}
                    </span>
                    <h3>Não presentes</h3>
                  </div>
                  <div className="div__oneColumn">
                    <CloseIcon />
                  </div>
                </div>
                <div className="div__card" name="card1">
                  <div className="div__oneColumn">
                    <p>{dataevents?.total_exhibitors}</p>
                    <h3>Total de</h3>
                    <h3>Expositores</h3>
                  </div>
                  <div className="div__oneColumn">
                    <PermContactCalendarIcon />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="div__oneRow"
              style={{ margin: '1rem 0rem', minWidth: '850px' }}
            >
              <div
                className="div__oneColumn"
                style={{
                  flex: 1.05,
                  borderTop: '1px dotted rgba(0,0,0,0.3)',
                  paddingTop: '1rem',
                }}
              >
                <div
                  className="div__deck"
                  style={{
                    flexDirection: 'column',
                    padding: '5px',
                  }}
                >
                  <div className="div__card" name="card1 cardColumn lateralCol">
                    <div className="div__oneColumn">
                      <p>{dataevents?.total_supports}</p>
                      <h3>Total de</h3>
                      <h3>Apoiadores</h3>
                    </div>
                    <div className="div__oneColumn">
                      <SupervisorAccountIcon />
                    </div>
                  </div>
                  <div className="div__card" name="card1 cardColumn lateralCol">
                    <div className="div__oneColumn">
                      <p>{dataevents?.total_sponsors}</p>
                      <h3>Total de</h3>
                      <h3>Patrocinadores</h3>
                    </div>
                    <div className="div__oneColumn">
                      <StarOutlinedIcon />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="div__oneColumn"
                style={{ flex: 3.2, width: '100% !important' }}
              >
                <div className="div__oneColumn columnGraf">
                  <div className="top_button">
                    <button
                      aria-describedby={id}
                      onClick={handleClickPop}
                      name="dadosreceiptdate"
                    >
                      <TodayIcon style={{ margin: '0rem 0.25rem' }} /> Opções{' '}
                      <ArrowDropDownIcon />
                    </button>
                  </div>

                  <h2>
                    Inscritos por{' '}
                    {dadosenrollmentsdate.day
                      ? 'dia'
                      : dadosenrollmentsdate.month
                      ? 'mês'
                      : 'ano'}{' '}
                  </h2>

                  <ResponsiveContainer
                    width="100%"
                    minHeight="234px"
                    style={{
                      flex: 1,
                      maxWidth: '780px',
                    }}
                    // aspect={7.0 / 2.0}
                  >
                    <LineChart
                      // height={250}
                      data={getintervalformap(
                        dadosenrollmentsdate.day === true
                          ? 'day'
                          : dadosenrollmentsdate.month === true
                          ? 'month'
                          : 'year',
                        dadosenrollmentsdate
                      ).map((day, idx) => {
                        let aux =
                          '' + new Date(day).toISOString().split('T')[0];
                        return {
                          date: day.toLocaleDateString(
                            'pt-BR',
                            dadosenrollmentsdate.day
                              ? {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: '2-digit',
                                }
                              : dadosenrollmentsdate.month
                              ? {
                                  month: '2-digit',
                                  year: 'numeric',
                                }
                              : {
                                  year: 'numeric',
                                }
                          ),
                          amount:
                            getEnrollmentsByDateInterval?.find(
                              (a) =>
                                a.date ===
                                (a.date.length === 10
                                  ? aux
                                  : a.date.length === 7
                                  ? aux.slice(0, 7)
                                  : aux.slice(0, 4))
                            )?.amount || 0,
                        };
                      })}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="amount"
                        name="Inscritos"
                        stroke={theme.colors.primaryDark}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        )}
      </content>

      {/* componentes auxiliares  */}
      {/* projetos e orçamentos por periodo de tempo  */}
      <Popover
        id={id}
        open={openPop?.name === 'dadosreceiptdate'}
        anchorEl={anchorEl}
        onClose={handleClosePop}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Popmenu>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              cancelDataEventSync();

              GET_ENROLLMENTS_AMOUNT_BY_DATE({
                end_date: auxiliar?.end_date
                  ? auxiliar.end_date
                  : dadosenrollmentsdate?.end_date,
                start_date: auxiliar?.start_date
                  ? auxiliar.start_date
                  : dadosenrollmentsdate?.start_date,
                month: auxiliar?.month
                  ? auxiliar.month
                  : auxiliar?.change
                  ? false
                  : dadosenrollmentsdate?.month,
                day: auxiliar?.day
                  ? auxiliar.day
                  : auxiliar?.change
                  ? false
                  : auxiliar?.changeMonth
                  ? false
                  : dadosenrollmentsdate?.day,
              });

              setDadosenrollmentsdate({
                end_date: auxiliar?.end_date
                  ? auxiliar.end_date
                  : dadosenrollmentsdate?.end_date,
                start_date: auxiliar?.start_date
                  ? auxiliar.start_date
                  : dadosenrollmentsdate?.start_date,
                month: auxiliar?.month
                  ? auxiliar.month
                  : auxiliar?.change
                  ? false
                  : dadosenrollmentsdate?.month,
                day: auxiliar?.day
                  ? auxiliar.day
                  : auxiliar?.change
                  ? false
                  : auxiliar?.changeMonth
                  ? false
                  : dadosenrollmentsdate?.day,
              });
              handleClosePop();
            }}
          >
            <input
              type="date"
              placeholder="Data inicial"
              value={
                auxiliar?.start_date
                  ? auxiliar.start_date
                  : dadosenrollmentsdate.start_date
              }
              max={
                auxiliar?.end_date
                  ? auxiliar.end_date
                  : dadosenrollmentsdate.end_date
              }
              onChange={(event) => {
                setAuxiliar({
                  ...auxiliar,
                  start_date: new Date(event.target.value)
                    .toISOString()
                    .split('T')[0],
                });
              }}
            />
            <input
              type="date"
              placeholder="Data final"
              value={
                auxiliar?.end_date
                  ? auxiliar.end_date
                  : dadosenrollmentsdate.end_date
              }
              min={
                auxiliar?.start_date
                  ? auxiliar.start_date
                  : dadosenrollmentsdate.start_date
              }
              onChange={(event) => {
                setAuxiliar({
                  ...auxiliar,
                  end_date: new Date(event.target.value)
                    .toISOString()
                    .split('T')[0],
                });
              }}
            />
            <select
              value={
                !auxiliar?.month && !auxiliar?.day && !auxiliar?.change
                  ? dadosenrollmentsdate.month && dadosenrollmentsdate.day
                    ? 'day'
                    : dadosenrollmentsdate.day
                    ? 'day'
                    : dadosenrollmentsdate.month
                    ? 'month'
                    : 'year'
                  : auxiliar?.month && auxiliar?.day
                  ? 'day'
                  : auxiliar?.day
                  ? 'day'
                  : auxiliar?.month
                  ? 'month'
                  : 'year'
              }
              onChange={(event) => {
                switch (event.target.value) {
                  case 'day':
                    setAuxiliar({
                      ...auxiliar,
                      day: true,
                      month: false,
                    });
                    break;
                  case 'month':
                    setAuxiliar({
                      ...auxiliar,
                      day: false,
                      month: true,
                      changeMonth: true,
                    });
                    break;
                  case 'year':
                    setAuxiliar({
                      ...auxiliar,
                      day: false,
                      month: false,
                      change: true,
                    });
                    break;
                  default:
                    setAuxiliar({
                      ...auxiliar,
                      day: true,
                      month: false,
                    });
                    break;
                }
              }}
            >
              <option value="" disabled>
                Filtrar por
              </option>
              <option value="day">Dia</option>
              <option value="month">Mês</option>
              <option value="year">Ano</option>
            </select>
            <button
              className={'destaque'}
              name="delay_in_one_day"
              // onClick={selectProjectDelayed}
            >
              Consultar
            </button>
          </form>
        </Popmenu>
      </Popover>
      {/* backdrop */}
      {isFetching && (
        <div className="fundo_black">
          <CircularProgress color="inherit" size={50} />
        </div>
      )}
    </Container>
  );
}

export default Dashboard;
