import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import api from '../../services/api';

import { Container } from './styles';
import Alert from '@material-ui/lab/Alert';

import 'date-fns';
// editor de texto
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default function ModalEnviarPesquisa({
  open,
  user,
  handleClose,
  eventId,
}) {
  const [messageError, setMessageError] = React.useState('');
  const [verificar, setVerificar] = React.useState(false);

  const [dados, setDados] = React.useState({
    description: '',
  });
  const [textdescription, setTextdescription] = React.useState({
    description: EditorState.createEmpty(),
  });

  const onEditorStateChange = (editorState) => {
    setTextdescription({
      ...textdescription,
      description: editorState,
    });
    setDados({
      ...dados,
      description: draftToHtml(
        convertToRaw(textdescription.description.getCurrentContent()),
      ),
    });
  };

  // chamadas api
  const sendSurvey = async (type) => {
    let postRequest = {
      event_id: Number(eventId),
      message: dados.description,
    };

    dados.description = HTMLToText(postRequest.message);

    //console.log('dados enviados: ', message);

    const config = {
      headers: {
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      },
    };

    if (
      !postRequest.message ||
      // eslint-disable-next-line no-use-before-define
      postRequest.message.replace(/[<p></p>/\n&nbs;]/g, '') === ''
    ) {
      setMessageError('Mensagem não pode ser vazia');
    } else {
      setMessageError('');
      if (!verificar) {
        setVerificar(true);
        return;
      }
      //console.log(postRequest.message);
      await api
        .post('/surveys', postRequest, config)
        .then((a) => {
          //console.log(a.data);
          handleClose();
          setMessageError('');
        })
        .catch((error) => {
          console.log(error);
          const message = error.response.data.messages
            ? error.response.data.messages[0].message
            : error.response.data.message;
          setMessageError(message);
        });
    }
  };

  //fim

  const HTMLToText = (string) => {
    let a = string;
    a = a.replace(/<p>/g, '');
    a = a.replace(/<\/p>/g, '\n');
    a = a.replace(/<br \/>/g, '\n');

    a = a.replace(/<strong>/g, ' *');
    a = a.replace(/<\/strong>/g, '* ');

    a = a.replace(/<em>/g, ' _');
    a = a.replace(/<\/em>/g, '_ ');

    a = a.replace(/<del>/g, ' ~');
    a = a.replace(/<\/del>/g, '~ ');

    //console.log(a);
    const data = new DOMParser().parseFromString(a, 'text/html');
    return data.body.textContent || '';
  };

  const TextToHTML = (html) => {
    let a = html;
    a = a.replace(/\n/g, '<br/>');

    a = a.replace(/ \*/g, ' <strong>');
    a = a.replace(/\* /g, '</strong> ');

    a = a.replace(/ _/g, ' <em>');
    a = a.replace(/_ /g, '</em> ');

    a = a.replace(/ ~/g, ' <del>');
    a = a.replace(/~ /g, '</del> ');

    //console.log('<p>' + a + '</p>');
    return '<p>' + a + '</p>';
  };

  return (
    <Dialog
      open={open}
      scroll={'body'}
      // onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <Container>
        <form>
          <DialogTitle id="max-width-dialog-title">
            Pesquisa de satisfação
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {verificar ? (
                <div className="subtitulo">
                  <h4>Atenção! A pesquisa só pode ser enviada UMA VEZ.</h4>
                  <br />
                  <h5>Verifique abaixo a mensagem que será enviada:</h5>
                  <br />
                  <div
                    className="content__description"
                    dangerouslySetInnerHTML={{
                      __html:
                        '<p><p>Olá (Nome do Participante), gostaríamos de agradecer pela sua presença no evento de (Local do Evento) da ' +
                        process.env.REACT_APP_NAME_SITE +
                        ' no dia (Data do Evento).</p><br/>' +
                        '<p>' +
                        HTMLToText(dados.description).replace(/\n/g, '<br/>') +
                        '</p>',
                    }}
                  ></div>

                  <br />
                  <h5>
                    Verifique abaixo Como a mensagem aparecerá no Whastsapp:
                  </h5>
                  <br />
                  <div
                    className="content__description"
                    dangerouslySetInnerHTML={{
                      __html:
                        '<p><p>Olá (Nome do Participante), gostaríamos de agradecer pela sua presença no evento de (Local do Evento) da ' +
                        process.env.REACT_APP_NAME_SITE +
                        ' no dia (Data do Evento).</p><br/>' +
                        '<p>' +
                        TextToHTML(dados.description) +
                        '</p>',
                    }}
                  ></div>
                </div>
              ) : (
                <div>
                  <div className="subtitulo">
                    Escreva abaixo a mensagem que será enviada pelo Whatsapp
                    para os participantes que estiveram presentes no envento:
                  </div>
                  <div className="formulario">
                    <div
                      style={{
                        border: '1px solid rgba(0,0,0,0.2)',
                        margin: '2rem 0rem',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <Editor
                        toolbar={{
                          options: [
                            //'fontSize',
                            'inline',
                            'emoji',
                            'history',
                            // 'list',
                            // 'textAlign',
                            // 'colorPicker',
                            // 'image',
                            // 'link',

                            // 'blockType',
                            // 'fontFamily',
                            // 'embedded',
                            // 'remove',
                          ],
                          inline: {
                            options: [
                              'bold',
                              'italic',
                              'strikethrough',
                              // 'underline',
                              // 'monospace',
                              // 'superscript',
                              // 'subscript',
                            ],
                          },
                        }}
                        defaultEditorState={EditorState.createWithContent(
                          ContentState.createFromBlockArray(
                            convertFromHTML(dados.description),
                          ),
                        )}
                        // editorState={textdescription.description}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={onEditorStateChange}
                        placeholder="Escreva aqui sua mensagem..."
                      />
                    </div>
                  </div>
                </div>
              )}
            </DialogContentText>
          </DialogContent>
          {messageError && (
            <Alert
              style={{ margin: '1rem 2rem' }}
              variant="outlined"
              severity="error"
            >
              {messageError.replace(/[.!]/g, '')}. Corrija e tente novamente.
            </Alert>
          )}
          <DialogActions>
            <Button
              onClick={() => {
                setVerificar(false);
                handleClose();
              }}
              color=""
              variant="contained"
            >
              Cancelar
            </Button>

            <Button
              onClick={async () => {
                await sendSurvey();
              }}
              style={{ backgroundColor: 'var(--primary)', color: '#fff' }}
              variant="contained"
            >
              Enviar agora
            </Button>
          </DialogActions>
        </form>
      </Container>
    </Dialog>
  );
}
