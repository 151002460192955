const Ma = (props) => {
  return (
    <svg
      width={200}
      height={200}
      viewBox="0 0 52.917 52.917"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        style={{
          fill: 'red',
          fillOpacity: 1,
          strokeWidth: 0.326052,
        }}
        d="M21.778.801c-.581.027-1.004.916-1.536 2.95a72.598 72.598 0 0 1-1.677 5.379c-1.197 3.277-5.61 10.112-7.022 10.875-2.29 1.237-4.016 3.31-2.755 3.31.943 0 3.73 1.68 4.088 2.464.482 1.057.486 5.36.007 7.744-.358 1.783-.34 1.88.558 3.07 1.315 1.744 1.76 2.023 3.252 2.035 1.642.013 2.103.455 2.103 2.019 0 1.192-.178 1.487-1.404 2.331-.324.223-.712.955-.864 1.627-.248 1.102-.17 1.348.794 2.502.709.848 1.146 1.788 1.296 2.789.194 1.293.375 1.573 1.262 1.95.57.241 1.137.34 1.26.218.287-.282.321-2.966.066-5.245-.165-1.472-.059-2.065.644-3.587.463-1.002.996-2.44 1.185-3.193.508-2.03 1.605-3.143 3.6-3.655 1.337-.342 2.201-.894 4.013-2.556l2.31-2.12 2.503.21 2.504.208.35-1.002c.288-.826.237-1.215-.292-2.202-.82-1.53-.81-1.71.2-3.707.757-1.492.806-1.808.49-3.068-.674-2.674-.46-4.487.8-6.778.856-1.556 1.38-2.156 2.037-2.32 1.428-.356 3.004-1.477 3.004-2.138 0-.454-.314-.66-1.223-.803-.672-.106-2.076-.642-3.119-1.19-1.471-.774-2.128-.948-2.934-.779-.571.12-1.665.297-2.43.393-.908.115-1.508.391-1.724.795-.182.34-.656.62-1.053.623-.888.006-1.357.31-1.633 1.055-.242.656-1.22.754-1.795.18-.634-.634-.451-1.77.459-2.852 1.214-1.443.9-2.699-1.177-4.714-1.372-1.33-1.871-1.605-3.177-1.744-.853-.09-1.796-.378-2.096-.64-.312-.271-.579-.422-.818-.434a.81.81 0 0 0-.056 0zm-1.18 18.917h1.455l1.092 2.437 1.09-2.437h1.455v4.617l1.705-4.617h1.442l1.793 4.857h-1.294l-.337-.982h-1.8l-.336.982h-2.42v-3.252l-.9 2.11h-.864l-.9-2.11v3.252h-1.181zm7.501 1.243-.596 1.743h1.193z"
      />
    </svg>
  );
};

export default Ma;
