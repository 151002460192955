import styled from 'styled-components';

import bgImage from '../../../assets/background/default-event.jpg';

export const Container = styled.div`
  .fundo_black {
    background: rgba(0, 0, 0, 0.5);
    color: #fff;

    position: absolute;
    z-index: 9999999999999999999999999999999;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pagina {
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : '#fff'};
    // backgroundColor:'transparent',
    border: 0px;
    border-radius: 6px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    //  max-width:1000px;
    margin: 0.5rem 0.5rem;
    padding: 0.5rem 1.5rem;
    flex: ${(props) => (props.flex ? props.flex : 1)};
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;

    .header {
      padding-bottom: 1rem;
      border-bottom: 1px solid #d3d3d3;

      h1 {
        font-weight: 800;
        color: rgba(0, 0, 0, 0.8);
      }

      p {
        color: rgba(0, 0, 0, 0.7);
        font-weight: 600;
      }
    }

    .corpo {
      margin: 1rem;
      display: flex;
      flex-direction: row;

      div {
        flex: 1;
        padding: 0.1rem 0.5rem;

        .MuiAutocomplete-clearIndicator {
          display: none;
        }

        h2 {
          display: flex;
          flex-direction: row;
          /* justify-content: center; */
          align-items: center;
          svg {
            margin: 0rem 0.3rem;
          }
        }
      }
    }

    .div__comparacao {
      /* background-color: red; */

      .div__linha {
        display: flex;
        flex-direction: row;
        margin: 0.25rem 0rem;
        font-size: 1.2rem;
        padding: 0.5rem;

        .image_evento1 {
          /* background-color: red; */
          background-image: url(${bgImage});
          background-size: cover;
          background-position: center;
          height: 200px;
          margin: 0.1rem;
          padding: 0rem;
          /* border-radius: 1rem 1rem 0 0; */
          border-radius: 1rem;
        }
        .image_evento2 {
          /* background-color: red; */
          padding: 0rem;
          background-image: url(${bgImage});
          background-size: cover;
          background-position: center;
          height: 200px;
          margin: 0.1rem;
          /* border-radius: 1rem 1rem 0 0; */
          border-radius: 1rem;
        }
        .gradiente {
          display: flex;
          flex: 1;
          flex-direction: column;
          background: linear-gradient(
            to right bottom,
            var(--primary),
            rgba(var(--primary-rgb), 0.7)
          );
          height: 100%;
          width: 100%;
          align-items: center;
          justify-content: center;
          /* border-radius: 1rem 1rem 0 0; */
          border-radius: 1rem;
          position: relative;
          padding: 0.1rem;

          .titulo {
            color: #fff;
            font-size: 2rem;
            font-weight: 400;
            text-align: center;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.6);
          }
          .descricao {
            color: #fff;
            font-size: 1.4rem;
            font-weight: 100;

            text-align: center;
          }
        }

        .div__coluna {
          flex: 1;
        }
        .div__resultados {
          flex: 2;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .indicador {
          display: flex;
          justify-content: center;
          align-items: center;

          .neutro {
            color: rgba(0, 0, 0, 0.4);
          }
          .baixa {
            color: rgb(196, 48, 43, 0.7);
          }
          .alta {
            color: rgba(119, 221, 119);
          }

          svg {
            font-size: 3rem;
          }
        }
      }

      .div__linha:nth-child(even) {
        background: rgba(0, 0, 0, 0.1);
        .div__coluna_titulo {
          flex: 1;
          font-weight: 600;
          /* background-color: #CCC; */
          display: flex;
          align-items: center;
          padding: 0rem 1rem;
          font-size: 14px;
        }
      }
      .div__linha:nth-child(odd) {
        background: #fff;

        .div__coluna_titulo {
          flex: 1;
          font-weight: 600;
          /* background-color: rgba(0, 0, 0, 0.2); */
          display: flex;
          align-items: center;
          padding: 0rem 1rem;
          font-size: 14px;
        }
      }
    }

    .div__coluna {
      flex: 1;
      padding: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
