const Rn = (props) => {
  return (
    <svg
      width={200}
      height={200}
      viewBox="0 0 52.917 52.917"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        style={{
          fill: 'red',
          strokeWidth: 2.20155,
        }}
        d="M69.729 41.035c-1.924.03-4.454.537-8.208 1.483-5.595 1.409-10.173 3.81-10.173 5.334 0 1.524-1.487 4.004-3.303 5.511-1.816 1.508-3.303 4.62-3.303 6.918 0 4.385-9.189 20.32-13.119 22.748-1.26.779-2.291 3.17-2.291 5.313 0 7.107-13.173 24.463-18.566 24.463-3.173 0-5.869 2.027-7.524 5.66-2.417 5.305-2.265 5.566 2.4 4.156 3.993-1.206 4.447-.947 2.301 1.309-3.36 3.533 2.35 8.513 9.88 8.619 5.688.08 21.58-9.72 23.384-14.422.658-1.716 3.456-3.143 6.219-3.168 2.762-.027 9.34-2.01 14.619-4.404 5.278-2.395 11.47-4.354 13.76-4.354 6.49 0 5.164 9.291-2.381 16.695-3.6 3.532-6.57 8.651-6.604 11.375-.033 2.725-1.053 4.954-2.263 4.954-1.211 0-2.202 1.6-2.202 3.558 0 2.481 1.341 3.209 4.409 2.406 2.706-.707 5.174.218 6.392 2.395 1.571 2.807 3.064 3.074 7.15 1.28 10.221-4.49 23.88-.404 23.88 7.142 0 4.496 3.902 3.674 5.431-1.143.729-2.296 1.418-8.816 1.531-14.488.149-7.393 1.492-11.508 4.743-14.537 5.293-4.932 11.548-5.486 14.052-1.244 2.43 4.115 12.204 6.28 30.381 6.73 8.476.21 16.897.995 18.713 1.744 6.713 2.769 13.21 3.48 13.21 1.45 0-1.15 1.42-1.8 3.157-1.444 3.634.745 3.814-1.506.737-9.262-1.202-3.027-4.406-14.54-7.121-25.585-2.716-11.046-7.313-24.361-10.213-29.59-4.552-8.206-6.757-9.885-16.12-12.262-6.71-1.703-20.074-2.43-35.052-1.902-21.288.749-24.933.337-30.223-3.43-3.308-2.356-8.757-4.283-12.107-4.283-3.35 0-7.783-1.532-9.85-3.403-1.783-1.613-3.254-2.361-5.726-2.322zm29.335 34.309h11.1c1.516 0 2.813.083 3.904.256a8.468 8.468 0 0 1 3.077 1.113 6.6 6.6 0 0 1 2.287 2.248c.574.907.857 2.041.857 3.42 0 1.895-.438 3.45-1.322 4.644-.873 1.195-2.125 2.184-3.756 2.977l7.285 9.355V75.344h7.906l9.356 14.697V75.344h6.072v25.648h-6.379L128.56 83.387v17.605h-12.863l-7-9.404h-3.046v9.404h-6.586V75.344zm6.586 4.71v6.91h2.03c1.056 0 1.94-.045 2.652-.146.712-.103 1.313-.343 1.795-.71.46-.357.787-.75.994-1.174.218-.437.326-1.011.326-1.735 0-.643-.13-1.196-.394-1.656-.265-.46-.718-.82-1.362-1.084a4.793 4.793 0 0 0-1.566-.336 26.525 26.525 0 0 0-2.08-.068h-2.395z"
        transform="scale(.26458)"
      />
    </svg>
  );
};

export default Rn;
