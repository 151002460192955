// stateicons
import AC from '../utils/Svglist/AC';
import AL from '../utils/Svglist/AL';
import AM from '../utils/Svglist/AM';
import AP from '../utils/Svglist/AP';
import BA from '../utils/Svglist/BA';
import CE from '../utils/Svglist/CE';
import DF from '../utils/Svglist/DF';
import ES from '../utils/Svglist/ES';
import GO from '../utils/Svglist/GO';
import MA from '../utils/Svglist/MA';
import MG from '../utils/Svglist/MG';
import MS from '../utils/Svglist/MS';
import MT from '../utils/Svglist/MT';
import PA from '../utils/Svglist/PA';
import PB from '../utils/Svglist/PB';
import PE from '../utils/Svglist/PE';
import PI from '../utils/Svglist/PI';
import PR from '../utils/Svglist/PR';
import RJ from '../utils/Svglist/RJ';
import RN from '../utils/Svglist/RN';
import RO from '../utils/Svglist/RO';
import RR from '../utils/Svglist/RR';
import RS from '../utils/Svglist/RS';
import SC from '../utils/Svglist/SC';
import SE from '../utils/Svglist/SE';
import SP from '../utils/Svglist/SP';
import TO from '../utils/Svglist/TO';

export const getStateMap = (valueState) => {
  switch (valueState) {
    case 'AC':
      return <AC />;
    case 'AL':
      return <AL />;
    case 'AM':
      return <AM />;
    case 'AP':
      return <AP />;
    case 'BA':
      return <BA />;
    case 'CE':
      return <CE />;
    case 'DF':
      return <DF />;
    case 'ES':
      return <ES />;
    case 'GO':
      return <GO />;
    case 'MA':
      return <MA />;
    case 'MG':
      return <MG />;
    case 'MS':
      return <MS />;
    case 'MT':
      return <MT />;
    case 'PA':
      return <PA />;
    case 'PB':
      return <PB />;
    case 'PE':
      return <PE />;
    case 'PI':
      return <PI />;
    case 'PR':
      return <PR />;
    case 'RJ':
      return <RJ />;
    case 'RN':
      return <RN />;
    case 'RO':
      return <RO />;
    case 'RR':
      return <RR />;
    case 'RS':
      return <RS />;
    case 'SC':
      return <SC />;
    case 'SE':
      return <SE />;
    case 'SP':
      return <SP />;
    case 'TO':
      return <TO />;
    default:
      return '';
  }
};
