const Pr = (props) => {
  return (
    <svg
      width={200}
      height={200}
      viewBox="0 0 52.917 52.917"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        style={{
          fill: 'red',
          fillOpacity: 1,
          strokeWidth: 0.480012,
        }}
        d="M11.292 10.401 9.26 11.89c-1.118.82-2.573 2.3-3.235 3.292a54.845 54.845 0 0 1-2.287 3.156c-.968 1.208-1.102 1.983-1.243 7.2-.141 5.21-.272 5.969-1.2 6.973L.253 33.638l1.422-.166c1.23-.144 1.569.094 2.511 1.754.6 1.056 1.092 2.263 1.1 2.682.012 1.182 2.191 1.908 6.939 2.309 2.391.202 4.822.62 5.4.929.578.31 1.873.762 2.88 1.004 1.005.243 2.683.403 3.728.358 1.602-.07 1.946-.272 2.182-1.283.225-.964.716-1.294 2.493-1.68 1.217-.264 2.63-.859 3.137-1.32 1.213-1.103 7.69-1.228 8.102-.158.412 1.075 1.39 1.223 2.887.437.929-.487 2.28-.66 4.136-.531 2.452.171 2.79.085 3.069-.797.172-.544.842-1.685 1.489-2.535 1.481-1.945 1.27-2.57-1.102-3.239-1.672-.471-1.875-.684-1.738-1.835.152-1.29.126-1.302-2.727-1.302-3.13 0-3.048.077-3.638-3.432-.162-.963-.769-2.352-1.347-3.088-.709-.9-1.154-2.337-1.368-4.406-.175-1.688-.552-3.305-.838-3.591-.286-.286-2.311-.606-4.5-.711-2.826-.137-4.517-.482-5.845-1.192-1.606-.86-2.955-1.03-9.6-1.223zm6.244 12.49h3.035c.454 0 .836.042 1.146.122.314.077.59.193.83.35.289.189.509.43.659.725.154.294.23.648.23 1.06 0 .321-.055.634-.168.941a2.098 2.098 0 0 1-.48.77 2.925 2.925 0 0 1-.956.638c-.348.144-.783.216-1.305.216h-1.148v2.329h-1.843zm6.194 0h3.093c.423 0 .786.025 1.09.073.304.048.59.152.855.312.27.16.481.368.638.624.16.253.24.572.24.956 0 .528-.122.96-.369 1.292-.243.333-.593.61-1.048.83l2.388 3.064h-2.253l-1.95-2.623h-.849v2.623H23.73zm1.835 1.318v1.924h.566c.295 0 .541-.015.74-.044a1.06 1.06 0 0 0 .5-.196c.127-.1.22-.207.277-.326.061-.121.09-.284.091-.484a.917.917 0 0 0-.11-.462.738.738 0 0 0-.378-.303 1.355 1.355 0 0 0-.437-.09 7.679 7.679 0 0 0-.58-.02zm-6.186.019v2.141h.53c.313 0 .57-.02.771-.058.205-.039.377-.116.515-.231a.868.868 0 0 0 .253-.365 1.48 1.48 0 0 0 .082-.522.835.835 0 0 0-.163-.519.882.882 0 0 0-.378-.308 1.668 1.668 0 0 0-.562-.125c-.183-.01-.426-.013-.73-.013z"
      />
    </svg>
  );
};

export default Pr;
