const Df = (props) => {
  return (
    <svg
      width={200}
      height={200}
      viewBox="0 0 52.917 52.917"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        style={{
          fill: 'red',
          stroke: 'none',
          strokeWidth: '1.38862px',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeOpacity: 1,
        }}
        d="M181.904 45.166 17.748 46.207l-1.389 24.988s-5.53 3.88-6.941 6.246c-.587 2.585 0 7.635 0 7.635 2.042 2.656 4.212 5.206 6.941 7.29L4.906 105.9l.696 18.393c.23 6.122 2.101 12.112 2.427 18.047l-4.164 5.207c-.94 2.489-1.061 4.875-1.388 7.287l189.492-.346-.346-2.082 4.164-3.125-9.717-14.228 4.858-11.106-4.512-11.8 11.107-15.618-3.818-35.4-11.8-15.963zM76.146 85.877h10.247c2.302 0 4.28.196 5.949.588 1.668.379 3.077.926 4.215 1.635a12.723 12.723 0 0 1 4.593 4.812c1.127 2.01 1.684 4.384 1.684 7.115 0 2.63-.604 4.99-1.803 7.077-1.201 2.073-2.721 3.67-4.554 4.783a13.949 13.949 0 0 1-4.534 1.744c-1.643.328-3.588.488-5.84.488h-9.957V85.877zm27.467.004h20.24v5.46h-12.996v5.253h12.05v5.47h-12.05v12.06h-7.244V85.88zm-20.18 5.267v17.68h1.833c1.732 0 3.006-.069 3.816-.22.809-.165 1.6-.46 2.371-.878 1.329-.758 2.303-1.774 2.922-3.039.62-1.277.926-2.85.926-4.722 0-1.86-.333-3.439-1.016-4.754-.666-1.328-1.742-2.364-3.209-3.11a8.124 8.124 0 0 0-2.293-.748c-.771-.139-1.95-.209-3.517-.209h-1.832z"
        transform="scale(.26458)"
      />
    </svg>
  );
};

export default Df;
