import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

// utils images
import imageUrl from '../../utils/imageUrl';

// components
import Backdrop from '../Backdrop';

import { POST_NEW_AUDITORIUM, EDIT_AUDITORIUM } from '../../services/endpoints';

//campo de data
import 'date-fns';

import { Container } from './styles';

// tema
import { useTheme } from 'styled-components';
import { DropzoneArea } from 'material-ui-dropzone';
import { acceptedFilesTypes } from '../../utils/acceptedFilesTypes';

export default function Novoauditorio(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [backdrop, setBackdrop] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState(props.tamanho);

  // auditorium data
  const [dataAuditorium, setDataAuditorium] = React.useState({
    id: props?.dados?.id,
    event_id: props.event_id,
    name: props.editar ? props?.dados?.name : '',
    description: props.editar ? props?.dados?.description : '',
    image: '',
    image_preview_old: props.editar ? props?.dados?.image : '',
  });
  // fim

  const handleClose = () => {
    setOpen(false);
    props.negar();
  };

  function handleSubmit(event) {
    event.preventDefault();

    props.editar
      ? EDIT_AUDITORIUM(
          dataAuditorium,
          props.success,
          setBackdrop,
          props.user.token
        )
      : POST_NEW_AUDITORIUM(
          dataAuditorium,
          props.success,
          setBackdrop,
          props.user.token
        );
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        scroll={'body'}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <Container>
          <form onSubmit={handleSubmit}>
            <DialogTitle id="max-width-dialog-title">
              {props.editar ? 'Editar Auditório' : '+ Novo Auditório'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div className="subtitulo">
                  Preencha corretamente os campos abaixo para{' '}
                  {props.editar ? 'editar' : 'adicionar'} o auditório.
                </div>
                <div className="div__body_form">
                  <TextField
                    // required
                    id="standard-basic-name"
                    className="campo"
                    label="Nome do auditório"
                    onChange={(e) =>
                      setDataAuditorium({
                        ...dataAuditorium,
                        name: e.target.value,
                      })
                    }
                    value={dataAuditorium.name}
                  />
                  <TextField
                    multiline
                    minRows={4}
                    id="standard-basic-description"
                    className="campo"
                    label="Descrição do auditório"
                    onChange={(e) =>
                      setDataAuditorium({
                        ...dataAuditorium,
                        description: e.target.value,
                      })
                    }
                    value={dataAuditorium.description}
                  />

                  <DropzoneArea
                    onChange={(files) =>
                      setDataAuditorium({ ...dataAuditorium, image: files[0] })
                    }
                    acceptedFiles={acceptedFilesTypes}
                    filesLimit={'1'}
                    dropzoneText="Arraste a imagem para este campo ou clique aqui para buscar"
                    maxFileSize="6000000"
                    getFileAddedMessage={(fileName) =>
                      `Arquivo ${fileName} adicionado com sucesso.`
                    }
                    getFileRemovedMessage={(fileName) =>
                      `Arquivo ${fileName} removido.`
                    }
                    getFileLimitExceedMessage={(filesLimit) =>
                      `Número máximo de ${filesLimit} arquivos excedido.`
                    }
                    getDropRejectMessage={() =>
                      `Tipo de arquivo não permitido.`
                    }
                  />

                  {props.editar && dataAuditorium.image_preview_old && (
                    <div className="imagematual">
                      <h3>Imagem atual</h3>
                      <img
                        alt="imagem_atual"
                        src={imageUrl(dataAuditorium.image_preview_old)}
                        style={{ width: '100%' }}
                      />
                    </div>
                  )}
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => props.negar()}
                color=""
                variant="contained"
              >
                Cancelar
              </Button>
              <Button
                style={{
                  backgroundColor: theme.colors.primaryDark,
                  color: '#fff',
                }}
                type="submit"
                variant="contained"
              >
                {props.editar ? 'Editar' : 'Adicionar'}
              </Button>
            </DialogActions>
          </form>
        </Container>
      </Dialog>
      {backdrop && <Backdrop />}
    </React.Fragment>
  );
}
