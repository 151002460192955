export const interispTheme = {
  title: 'interisp',

  colors: {
    primary: '#6106D3',
    primaryRgb: '97, 6, 211',
    primaryDark: '#2e0363',
    primaryDarkRgb: '46, 3, 99',
    primaryLight: '#9d52fa',
    primaryLightRgb: '157, 82, 250',

    secondary: 'orange',
    secondaryRgb: '255,165,0',
    // secondary: '#2e0994',
    // secondaryRgb: '46, 9, 148',

    success: '#25d366',
    delete: '#f44336',

    white: '#fff',
    gray: '#ddd',
    black: '#121214',
  },
};
