import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { Link } from 'react-router-dom';

//campo de data
import 'date-fns';

import api from '../../services/api';

import { Container } from './styles';

export default function MaxWidthDialog(props) {
  const [open, setOpen] = React.useState(true);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState(props.tamanho);
  const [selecionado, setSelecionado] = React.useState();
  const [dadosapi, setDadosapi] = React.useState([]);
  const [dados] = React.useState(props.dados ? props.dados : []);

  // campo de data

  const handleClose = () => {
    setOpen(false);
    props.negar();
  };

  const funedit = (e) => {
    let aux = props.dados;
    aux.push(selecionado);
    props.editar(aux);
    props.novos(selecionado);

    props.negar();
  };

  const enviarForm = useCallback(
    async (type) => {
      const config = {
        headers: {
          Authorization: 'Bearer ' + props.user.token,
        },
      };

      await api
        .get(`/exhibitors`, config)
        .then(async (a) => {
          setDadosapi(a.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    [props.user.token]
  );

  React.useEffect(() => {
    enviarForm();
  }, [enviarForm]);

  const verificarApoiador = (e) => {
    // console.log(dados);
    var aux = 0;
    dados.map(
      (a, i) =>
        (e ? e.id : '') ===
          (a.objExhibitor ? a.objExhibitor.id : a.id ? a.id : '') &&
        (aux = aux + 1)
    );
    if (aux > 0) return false;
    if (aux === 0) return true;
  };

  var dados2 = dadosapi.filter(verificarApoiador);

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        scroll={'body'}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <Container>
          <DialogTitle id="max-width-dialog-title">
            + Vincular Novo Expositor
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div className="subtitulo">
                Selecione abaixo o expositor que deseja vincular a este evento.
              </div>
              {/* <div className="divisaoEtapas">
                <div className={fluxo==1? "subDiv sd1 ativo":"subDiv sd1"} onClick={()=>setFluxo(1)}>1</div>
                <div className={fluxo==2? "subDiv sd1 ativo":"subDiv sd1"}>2</div>
                <div className={fluxo==3? "subDiv sd1 ativo":"subDiv sd1"}>3</div>
                <div className={fluxo==41? "subDiv sd1 ativo":"subDiv sd1"}>4</div>
            </div> */}
              <div className="formulario">
                <div className="parte1">
                  {/* <label>Selecione abaixo a programação que deseja aproveitar</label> */}
                  <FormControl
                    variant=""
                    margin="dense"
                    className="typebusca"
                    style={{ margin: '1rem' }}
                  >
                    <InputLabel id="id-selctor-zone">
                      {'Selecione o expositor'}
                    </InputLabel>
                    <Select
                      labelId="id-selctor-zone"
                      id="id-selctor-zone-select"
                      value={selecionado}
                      onChange={(e) => setSelecionado(e.target.value)}
                      label="Tipo"
                      // style={{ height:'40px',}}
                      margin="dense"
                    >
                      {/* <MenuItem value="" default>
                            <em>Nenhum</em>
                        </MenuItem>                   */}
                      {dados2.map((a, i) => (
                        <MenuItem value={a} default>
                          {a.id + ' - ' + a.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="botoes">
                    <button
                      disabled={selecionado ? false : true}
                      style={{ pointerEvents: selecionado ? '' : 'none' }}
                      onClick={() => funedit()}
                    >
                      Vincular
                    </button>
                  </div>
                  Ou
                  <br />
                  <br />
                  <div className="botoes">
                    <Link to="/expositores">+ Novo Expositor</Link>
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.negar()} color="" variant="contained">
              Cancelar
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
    </React.Fragment>
  );
}
