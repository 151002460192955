import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  // baseURL: 'https://api-expoisp.luandeveloper.online/',
  // baseURL: `https://api.expoisp.com.br`,
  // baseURL: `http://192.168.0.145:3333`,
});

export default api;
