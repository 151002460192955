import styled from 'styled-components';

export const Card = styled.div`
  width: 24rem;
  height: 11rem;
  background: white;

  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 2rem;
  margin-top: 1rem;

  border-radius: 0.2rem;
  box-shadow: ${(props) =>
    props.selected
      ? 'inset 0px 0px 0px 0px rgba(255,165,0,0.5), 0px 0px 10px 1px rgba(255,165,0,0.1)'
      : '0px 0px 10px rgba(0, 0, 0, 0.05)'};
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 1.5rem 1rem;
  padding-top: 2rem;

  .div__content {
    display: flex;
    flex-direction: row-reverse;
  }

  .div__svg_map {
    svg {
      path {
        /* fill: rgba(0, 0, 0, 0.3) !important; */

        fill: ${(props) =>
          props.selected
            ? ' rgba(255,165,0,1) !important'
            : 'rgba(0, 0, 0, 0.3) !important'};
      }
      width: 100%;
      height: auto;
    }
    flex: 1;
  }

  .div__event_today {
    display: ${(props) => (props.selected ? 'flex' : 'none')};
    flex-direction: center;
    align-items: center;
    background-color: rgba(255, 165, 0);
    top: -1px;
    right: -1px;
    position: absolute;
    color: #fff;
    padding: 0.2rem 0.25rem;
    border-radius: 0px 0rem 0px 0.5rem;
    font-size: 0.8rem;
    text-transform: uppercase;
    gap: 0.2rem;
    font-family: 'Fira Sans', 'Poppins', sans-serif;
    font-weight: 600;

    svg {
      font-size: 1.1rem;
    }
  }

  .div__event_nacional {
    display: ${(props) => (props.nacional ? 'flex' : 'none')};
    flex-direction: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.primaryLight};
    top: -1px;
    left: -1px;

    position: absolute;
    color: #fff;
    padding: 0.2rem 0.25rem;
    border-radius: 0px 0rem 0.5rem 0px;
    font-size: 0.8rem;
    text-transform: uppercase;
    gap: 0.2rem;
    font-family: 'Fira Sans', 'Poppins', sans-serif;
    font-weight: 600;

    svg {
      font-size: 1.1rem;
    }
  }

  .div__local_img {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;

    svg {
      font-size: 3.5rem;
      color: rgba(0, 0, 0, 0.3);
    }
  }

  .div__about_event {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;

    h4 {
      color: ${(props) =>
        props.selected
          ? props.theme.colors.secondary
          : `rgba(${props.theme.colors.primaryRgb}, 0.8)`};
      margin: 0rem;
      display: flex;
      align-items: center;

      svg {
        margin-right: 0.15rem;
        width: 25px;
      }
    }
    p {
      margin: 0rem;
      display: flex;
      align-items: center;
      font-weight: 600;
      color: rgba(${(props) => props.theme.colors.blackRgb}, 0.7);

      svg {
        margin-right: 0.25rem;
        font-size: 1rem;
        width: 25px;
        color: ${(props) =>
          props.selected ? 'orange' : props.theme.colors.primaryLight};
      }
    }
    span {
      margin: 0rem;
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      font-weight: 600;
      color: rgba(${(props) => props.theme.colors.blackRgb}, 0.7);

      svg {
        margin-right: 0.25rem;
        font-size: 1rem;
        width: 25px;
        color: ${(props) =>
          props.selected ? 'orange' : props.theme.colors.primaryLight};
      }
    }
  }

  .buttons {
    display: flex;
    flex: 1;
    margin-right: -1rem;

    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    a {
      margin: 0;
      padding: 0;
      z-index: 5;
      overflow: hidden;

      &:first-child {
        border-radius: 1rem 0 0 1rem;
      }
      &:last-child {
        border-radius: 0 0 1rem 0;
      }
    }

    button {
      display: flex;
      height: 3rem;
      width: 3.5rem;
      border: 0;
      border-radius: 0;
      background: ${(props) => `rgb(${props.theme.colors.primaryRgb})`};
      justify-content: center;
      align-items: center;

      svg {
        justify-content: center;
        align-items: center;
        color: ${(props) => props.theme.colors.white} !important;
      }

      :hover {
        background: ${(props) => props.theme.colors.gray};

        &.delete {
          background: ${(props) => props.theme.colors.delete} !important;
          svg {
            color: ${(props) => props.theme.colors.white} !important;
          }
        }

        svg {
          color: ${(props) => props.theme.colors.primaryLight} !important;
        }
      }
    }
  }
`;
