import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  /* background-color: var(--background); */

  display: flex;
  justify-content: center;
  align-items: center;

  background: url(${(props) => props.imgBackground}) no-repeat;
  background-size: cover;
  background-position: center;

  position: relative;

  .container {
    background-color: var(--white);
    border-radius: 0.4rem;
    box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.25);
    padding: 1rem 2rem 2rem 2rem;
    min-width: 400px;
    max-width: 450px;

    .container__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        max-width: 200px;
        margin: 2rem 0;
      }

      h1 {
        letter-spacing: 0.1em;
        text-align: center;
        color: #121214;
      }
    }

    .container__form {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;

      .div__input {
        :first-child {
          margin-top: 0rem;
        }

        margin-top: 1rem;
        display: flex;
        flex-direction: column;

        label {
          font-weight: 500;
          font-size: 1.125rem;
          line-height: 1.375rem;
          display: flex;
          align-items: center;
          letter-spacing: 0.1em;

          margin-bottom: 0.5rem;
        }

        input {
          border: 1.5px solid #b3b3b3;
          border-radius: 10px;

          height: 3.75rem;

          display: flex;
          justify-content: center;
          align-items: center;

          padding: 0 1rem;
          font-weight: 400;
          font-size: 1.125rem;

          ::-webkit-input-placeholder {
            font-size: 1rem;
            line-height: 2.1rem;

            opacity: 0.8;
          }
        }

        button {
          margin-top: 2rem;
          background-color: ${(props) => props.theme.colors.primaryDark};
          color: var(--gray-50);
          height: 3.75rem;

          display: flex;
          justify-content: center;
          align-items: center;

          border: none;
          border-radius: 10px;

          font-weight: bold;
          font-size: 1.5rem;
          line-height: 1.5rem;
          letter-spacing: 0.1em;

          cursor: pointer;
        }
      }

      a {
        margin-top: 0.5rem;
        color: var(--dark-blue);
        font-weight: 600;
        text-decoration: none;

        :hover {
          text-decoration: underline;
        }
      }
    }

    .container__error {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-1.5rem, 1rem);

      overflow: hidden;

      animation: move 1s ease-out;

      @keyframes move {
        to {
          transform: translate(-1.5rem, 1rem);
        }
        from {
          transform: translate(20rem, 1rem);
        }
      }

      .content {
        position: relative;

        background-color: var(--orange);
        color: var(--white);
        width: 300px;
        height: 75px;
        border-radius: 4px;
        padding: 1rem 0.875rem;

        span {
          font-weight: bold;
          font-size: 1.25rem;
          line-height: 1.5rem;
          display: flex;
          align-items: center;
          text-align: center;
        }

        img {
          cursor: pointer;

          position: absolute;
          top: 10%;
          right: 2%;

          :hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }
`;
