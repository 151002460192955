import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  // backgroundColor:'transparent',
  border: 0px;
  border-radius: 6px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  //  max-width:1000px;
  margin: auto;
  padding: 2rem;

  header {
    padding-bottom: 1rem;
    border-bottom: 1px solid #d3d3d3;

    h1 {
      font-weight: 800;
      color: rgba(0, 0, 0, 0.8);
    }

    p {
      color: rgba(0, 0, 0, 0.7);
      font-weight: 600;
    }
  }

  .funcoesdefiltro {
    margin: 1rem 0rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .MenuDireita {
    display: flex;
    flex-direction: row;
    margin: 0.5rem;

    .cadastroManual {
      background-color: ${(props) => props.theme.colors.primaryDark};
      /* margin: 0.8rem auto; */
      align-self: center;
      border: 0px;
      color: #fff;
      padding: 1rem 1rem;
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      border-radius: 0.5rem;
      img {
        width: 20px;
        margin-right: 0.5rem;
      }
    }
    .cadastroManual:hover {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    }
  }

  .cards-area {
    display: flex;
    flex: 1;
    margin-top: 2rem;
    gap: 2rem;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
`;
