import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';

import { DropzoneArea } from 'material-ui-dropzone';
import { Container } from './styles';

// temas
import { useTheme } from 'styled-components';

// editor de texto
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import api from '../../services/api';
import { acceptedFilesTypes } from '../../utils/acceptedFilesTypes';

export default function MaxWidthDialog(props) {
  const theme = useTheme();
  const [open] = React.useState(true);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState(props.tamanho);
  const [messageError, setMessageError] = React.useState('');

  const [dados, setDados] = React.useState({
    name: props.editar ? props.dados.name : '',
    formation: props.editar ? props.dados.formation : '',
    description: props.editar ? props.dados.description : '',
    image: props.editar ? props.dados.image_url : '',
    site_link: props.editar ? props.dados.site_link : '',
    instagram_link: props.editar ? props.dados.instagram_link : '',
    facebook_link: props.editar ? props.dados.facebook_link : '',
    linkedin_link: props.editar ? props.dados.linkedin_link : '',
  });
  const [textdescription, setTextdescription] = React.useState({
    description: EditorState.createEmpty(),
  });

  const onEditorStateChange = (editorState) => {
    setTextdescription({
      ...textdescription,
      description: editorState,
    });
    setDados({
      ...dados,
      description: draftToHtml(
        convertToRaw(textdescription.description.getCurrentContent())
      ),
    });
  };

  const [files, setFiles] = React.useState([]);
  const handleChange = (e) => {
    setFiles(e);
  };

  const enviarForm = async (type) => {
    let exhibitorRequest = {
      name: dados.name,
      formation: dados.formation,
      description: dados.description,
      site_link: dados.site_link,
      instagram_link: dados.instagram_link,
      facebook_link: dados.facebook_link,
      linkedin_link: dados.linkedin_link,
    };

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'multipart/form-data',
      },
    };

    const data = new FormData();

    exhibitorRequest.name && data.append('name', exhibitorRequest.name);
    exhibitorRequest.formation &&
      data.append('formation', exhibitorRequest.formation);
    data.append('description', exhibitorRequest.description);
    exhibitorRequest.site_link &&
      data.append('site_link', exhibitorRequest.site_link);
    exhibitorRequest.instagram_link &&
      data.append('instagram_link', exhibitorRequest.instagram_link);
    exhibitorRequest.facebook_link &&
      data.append('facebook_link', exhibitorRequest.facebook_link);
    exhibitorRequest.linkedin_link &&
      data.append('linkedin_link', exhibitorRequest.linkedin_link);
    data.append('image', files[0] ? files[0] : null);

    props.editar
      ? await api
          .put(`/speakers/${props.dados.id}`, data, config)
          .then((a) => {
            // console.log(a.data);

            // handleClose();
            setMessageError('');
            props.atualizar();
            //setLoad(false);
          })
          .catch((error) => {
            console.log(error.response);
            const message = error.response.data.messages
              ? error.response.data.messages[0].message
              : error.response.data.message;
            setMessageError(message);
          })
      : await api
          .post('/speakers', data, config)
          .then((a) => {
            // console.log(a.data);

            // handleClose();
            setMessageError('');
            props.atualizar();
            //setLoad(false);
          })
          .catch((error) => {
            console.log(error.response);
            console.log(error.response);
            const message = error.response.data.messages
              ? error.response.data.messages[0].message
              : error.response.data.message;
            setMessageError(message);
          });
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        scroll={'body'}
        //onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <Container>
          <form>
            <DialogTitle id="max-width-dialog-title">
              {props.editar ? 'Editar Pessoa' : '+ Nova Pessoa'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div className="subtitulo">
                  Preencha corretamente os campos abaixo para{' '}
                  {props.editar ? 'editar' : 'adicionar'} a pessoa.
                </div>
                <div className="formulario">
                  <p style={{ fontSize: '16px' }}>{props.message}</p>
                  <TextField
                    required
                    id="standard-basic"
                    className="campo"
                    label="Nome"
                    onChange={(e) =>
                      setDados({ ...dados, name: e.target.value })
                    }
                    value={dados.name}
                  />
                  <TextField
                    required
                    id="standard-basic"
                    className="campo"
                    label="Formação"
                    onChange={(e) =>
                      setDados({ ...dados, formation: e.target.value })
                    }
                    value={dados.formation}
                  />

                  <TextField
                    id="standard-basic"
                    className="campo"
                    label="Website"
                    onChange={(e) =>
                      setDados({ ...dados, site_link: e.target.value })
                    }
                    value={dados.site_link}
                  />
                  <TextField
                    id="standard-basic"
                    className="campo"
                    label="Link do instagram"
                    onChange={(e) =>
                      setDados({ ...dados, instagram_link: e.target.value })
                    }
                    value={dados.instagram_link}
                  />
                  <TextField
                    id="standard-basic"
                    className="campo"
                    label="Link do Facebook"
                    onChange={(e) =>
                      setDados({ ...dados, facebook_link: e.target.value })
                    }
                    value={dados.facebook_link}
                  />
                  <TextField
                    id="standard-basic"
                    className="campo"
                    label="Link do Linkedin"
                    onChange={(e) =>
                      setDados({ ...dados, linkedin_link: e.target.value })
                    }
                    value={dados.linkedin_link}
                  />

                  <div style={{ marginTop: '1rem', marginLeft: '1rem' }}>
                    Descrição *
                  </div>
                  <div
                    style={{
                      border: '1px solid rgba(0,0,0,0.2)',
                      margin: '2rem 0rem',
                      padding: '5px',
                      borderRadius: '5px',
                    }}
                  >
                    <Editor
                      defaultEditorState={
                        props.editar
                          ? EditorState.createWithContent(
                              ContentState.createFromBlockArray(
                                convertFromHTML(dados.description)
                              )
                            )
                          : textdescription.description
                      }
                      // editorState={textdescription.description}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      onEditorStateChange={onEditorStateChange}
                      style={{ minHeight: '500px' }}
                    />
                  </div>

                  <br />
                  <DropzoneArea
                    onChange={handleChange}
                    acceptedFiles={acceptedFilesTypes}
                    filesLimit={'1'}
                    dropzoneText="Arraste a imagem para este campo ou clique aqui para buscar"
                    maxFileSize="6000000"
                    getFileAddedMessage={(fileName) =>
                      `Arquivo ${fileName} adicionado com sucesso.`
                    }
                    getFileRemovedMessage={(fileName) =>
                      `Arquivo ${fileName} removido.`
                    }
                    getFileLimitExceedMessage={(filesLimit) =>
                      `Número máximo de ${filesLimit} arquivos excedido.`
                    }
                    getDropRejectMessage={() =>
                      `Tipo de arquivo não permitido.`
                    }
                  />

                  {props.editar ? (
                    <div className="imagematual">
                      <h3>Imagem atual</h3>
                      <img src={dados.image} alt="image_atual" />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </DialogContentText>
            </DialogContent>

            {messageError && (
              <Alert
                style={{ margin: '1rem 2rem' }}
                variant="outlined"
                severity="error"
              >
                {messageError.replace('!', '')}. Corrija e tente novamente.
              </Alert>
            )}

            <DialogActions>
              <Button
                onClick={() => props.negar()}
                color=""
                variant="contained"
              >
                Cancelar
              </Button>
              <Button
                onClick={() => enviarForm()}
                style={{
                  backgroundColor: theme.colors.primaryDark,
                  color: '#fff',
                }}
                variant="contained"
              >
                {props.editar ? 'Editar' : 'Adicionar'}
              </Button>
            </DialogActions>
          </form>
        </Container>
      </Dialog>
    </React.Fragment>
  );
}
