import styled from 'styled-components';

export const Container = styled.div`
  .header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    padding: 0.1rem 0.5rem;

    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    .left {
      display: flex;

      .icon {
        transform: ${(props) =>
          props.active
            ? 'rotate(90deg) translateX(-20%) translateY(+10%)'
            : ''};
        transition: 0.3s;
        transition-timing-function: ease-in;
      }

      span {
        font-size: 1.5rem;
        margin-right: 1rem;
      }
    }
  }

  .content {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    .a {
      display: flex;
    }

    display: ${(props) => (props.active ? 'flex' : 'none')};

    table {
      flex: 1;
      margin: 0.5rem 2.25rem;
      border-collapse: collapse;

      tbody {
        font-size: 1rem;
        tr {
          th {
            padding: 0.25rem 0.5rem;
            text-align: right;
          }

          td {
            input {
              margin: 0.5rem;
              padding: 0.4rem 0.75rem;

              border: 1px solid rgba(0, 0, 0, 0.5);
              border-radius: 0.25rem;
            }

            select {
              margin: 0.5rem;
              padding: 0.4rem 0.75rem;
              background: #fff;

              border: 1px solid rgba(0, 0, 0, 0.5);
              border-radius: 0.25rem;
            }
          }
        }
      }
    }

    .message {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .content__image {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        height: 200px;
      }

      button {
        margin-top: 0.25rem;
        width: 200px;

        padding: 0.25rem 0.5rem;
      }
    }

    .content__description {
      padding: 0 3rem;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 1.5rem;
      }

      p {
        font-size: 1rem;
        text-align: justify;
      }
    }
  }

  .button {
    display: flex;
    justify-content: center;

    .enviar-pesquisa {
      padding: 0.8rem 1rem;
      background: ${(props) => props.theme.colors.primaryDark};
      border: 0;
      outline: none;
      margin-bottom: -0.2rem;

      color: #fff;
      font-size: 1rem;
      border-radius: 5px 5px 0rem 0rem !important;
    }
    .cancel {
      padding: 0.8rem 1rem;
      margin-bottom: -0.2rem;
      border-radius: 5px 5px 0rem 0rem !important;
      border: 0;
      outline: none;
      background: rgba(0, 0, 0, 0.1);
      color: #3f51b5;
      text-decoration: none;
      margin-left: 1rem;
    }
  }

  .campoDeEdicao {
    width: 100%;
    .header2 {
      width: 100%;
      margin: 0rem 1rem;

      .cadastroManual {
        float: right;
        background-color: ${(props) => props.theme.colors.primaryDark};
        /* margin: 0.8rem auto; */
        align-self: center;
        border: 0px;
        color: #fff;
        padding: 1.15rem 1.25rem;
        display: flex;
        flex-direction: row;
        justify-items: center;
        align-items: center;
        margin-bottom: 1rem;
        margin-right: 2rem;
        margin-top: 1rem;
        img {
          width: 20px;
          margin-right: 0.5rem;
        }
      }
      .cadastroManual:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
      }
    }
    .ul {
      display: flex;
      flex-direction: column;
      width: 100%;
      .li {
        display: flex;
        flex-direction: row;
        width: 95%;
        margin: 0.05rem auto;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        /* padding: 1rem; */
        div {
          padding: 1rem 0.5rem;
        }
        .hora {
          /* text-align:left; */
          font-size: 14px;
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100% !important;
            height: auto;
          }
        }
        .descr {
          padding: 1rem !important;

          display: flex;
          flex-direction: column;
          justify-content: center;

          div {
            display: flex;
            flex-direction: column;
            label {
              font-weight: 600;
              font-size: 16px;
            }
            a {
              display: flex;
              flex-direction: row;
              /* justify-content:center; */
              color: #000;
              svg {
                margin-right: 0.25rem;
              }
            }
            a:hover {
              opacity: 0.8;
            }
            margin: 0rem;
            padding: 0rem;
          }
        }
        .campoMenu {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .botoes {
            padding: 0.5rem;
          }
          .btnedit {
            bottom: 0.5rem;
            right: 1rem;
            padding: 0.5rem;
          }
          .btnedit:hover {
            background-color: #6c6c6c;
            svg {
              color: #fff;
            }
          }
          .btndel {
            margin-right: 0.5rem;
          }
          .btndel:hover {
            background-color: #f44336;
            svg {
              color: #fff;
            }
          }
        }
      }
    }
  }
`;
