const Es = (props) => {
  return (
    <svg
      width={200}
      height={200}
      viewBox="0 0 52.917 52.917"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        style={{
          fill: 'red',
          strokeWidth: 0.592484,
        }}
        d="M30.875.864h-.078c-.807.012-1.461.264-1.997.749-.627.567-1.555.87-2.064.676-.76-.292-.813-.146-.297.817.555 1.038.437 1.171-1.04 1.171-.916 0-2.258.416-2.983.923-1.489 1.043-1.779 3.225-.428 3.225.489 0 .889.492.889 1.094 0 .602.418 1.931.928 2.955 1.068 2.14.736 3.056-1.111 3.07-.715.005-1.032.205-.706.444.326.24 1.095 1.425 1.71 2.635 1.434 2.82 1.009 5.672-1.221 8.197-.92 1.043-1.595 2.165-1.5 2.494.096.328-.444 1.562-1.2 2.74-.755 1.18-1.66 2.677-2.012 3.329-.458.851-1.371 1.238-3.243 1.375-1.588.115-2.807.55-3.123 1.114-.808 1.444-.765 6.912.06 7.583.4.326.792 1.48.87 2.563.165 2.293.243 2.357 3.912 3.28 3.604.905 6.74 1.007 7.668.25.405-.331 1.024-1.668 1.374-2.97.482-1.79 1.061-2.528 2.374-3.024.99-.373 1.737-1.091 1.737-1.67 0-.558.493-1.171 1.095-1.362 1.122-.357 2.46-2.653 2.46-4.223 0-.484.4-1.033.889-1.22.49-.189.889-1.069.889-1.967 0-1.836 2.796-6.018 4.98-7.449 2.166-1.42 2.517-3.302 2.362-12.669l-.141-8.54-3.975-2.557C34.787 1.86 32.52.872 30.875.864Zm5.754 15.804c.482 0 .957.047 1.426.142.472.092.88.208 1.224.35v1.686h-.202a3.84 3.84 0 0 0-1.086-.59 3.677 3.677 0 0 0-1.308-.24c-.157 0-.315.01-.472.033a1.76 1.76 0 0 0-.447.118.851.851 0 0 0-.33.226.482.482 0 0 0-.137.34c0 .193.073.342.22.447.148.101.427.195.836.28l.772.162c.25.053.517.124.802.216.56.184.973.435 1.239.753.268.314.403.724.403 1.228v.001c0 .721-.307 1.307-.92 1.76-.61.449-1.439.673-2.487.673a7.088 7.088 0 0 1-1.588-.157 8.388 8.388 0 0 1-1.264-.408v-1.755h.207c.39.311.826.55 1.308.717.485.168.95.251 1.396.251.115 0 .265-.01.452-.029.187-.02.34-.053.458-.099.144-.058.262-.132.354-.22.095-.09.142-.22.142-.394a.52.52 0 0 0-.206-.413c-.135-.118-.334-.208-.596-.27a16.093 16.093 0 0 0-.875-.182 7.019 7.019 0 0 1-.86-.221c-.616-.2-1.06-.47-1.332-.812-.27-.344-.403-.77-.403-1.278 0-.681.304-1.237.914-1.666.613-.433 1.4-.65 2.36-.65zm-9.354.132h5.295v1.416h-3.417v1.264h3.17v1.416h-3.17v1.809h3.417v1.416h-5.295z"
      />
    </svg>
  );
};

export default Es;
