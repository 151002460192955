import styled from 'styled-components';

export const Container = styled.div`
  .subtitulo {
    font-size: 16px;
  }

  form {
    .chkbox__img_mobile {
      display: flex;
      align-items: center;
      padding: 1rem;

      background: rgba(0, 0, 0, 0.1);
      cursor: pointer;

      input {
        margin-right: 1rem;
      }
    }
    .chkbox__checked {
      background: ${(props) => props.theme.colors.primaryDark};
      color: white;
    }
  }

  .formulario {
    display: flex !important;
    flex-direction: column !important;

    .div__delede_field {
      color: var(--error);
      background: lightpink;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 0.5rem;
      margin-top: 1rem;

      button {
        background-color: var(--error);
        color: white;
        border: 0px;
        padding: 0.5rem 1rem;
        font-weight: 400;

        :hover {
          opacity: 0.8;
        }
      }
    }

    .campo,
    .apresentarHome {
      margin: 0.5rem 1rem;
    }

    .imagematual {
      text-align: center;
      width: 100%;
      h3 {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 500;
        margin: 1rem 0rem;
        text-align: left;
      }
      img {
        width: 75% !important;
        margin: auto;
      }
    }
  }
`;
