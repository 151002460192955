const Pi = (props) => {
  return (
    <svg
      width={200}
      height={200}
      viewBox="0 0 52.917 52.917"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        style={{
          fill: 'red',
          fillOpacity: 1,
          strokeWidth: 0.381753,
        }}
        d="M37.06.315c-.723 0-1.122.195-1.122.551 0 .873-1.565 2.416-3.435 3.387-3.072 1.596-3.946 4.317-3.079 9.608.254 1.545.178 2.153-.404 3.24-.82 1.528-.908 3.054-.279 4.795.528 1.458.204 2.974-.862 4.04-.601.601-1.205.766-2.567.704-3.728-.172-4.052-.071-6.101 1.894-1.257 1.206-2.748 2.172-4.164 2.701-2.687 1.004-3.423 1.631-3.748 3.192-.137.66-.845 2.532-1.57 4.16-.727 1.628-1.207 3.144-1.068 3.369.14.225.363 1.974.498 3.888l.247 3.48 1.217.315c.722.186 1.318.636 1.467 1.106.139.436.795 1.028 1.457 1.316.662.289 1.252.53 1.31.54.472.066 5.812-2.791 6.371-3.409.443-.489.778-1.565.884-2.836.203-2.451 1.548-4.259 2.896-3.893 3.8 1.03 5.195 1.135 6.544.492.747-.356 1.768-.647 2.27-.647.589 0 1.861-.872 3.601-2.47 1.48-1.357 3.392-2.91 4.248-3.45 1.965-1.241 2.124-1.684 1.51-4.24-.468-1.952-.448-2.146.329-3.197 1.043-1.411 1.035-1.682-.083-2.757-1.502-1.443-2.082-4.161-2.89-13.58-.347-4.032-.57-5.023-1.597-7.056-1.035-2.052-1.138-2.514-.773-3.48.53-1.402.302-1.763-1.106-1.763Zm-9.05 28.276h2.414c.361 0 .664.032.911.095.25.061.47.155.66.28.23.15.405.342.525.576.122.235.183.516.183.845 0 .254-.045.503-.134.747-.089.242-.216.446-.382.611-.227.224-.48.394-.76.509-.277.114-.623.171-1.038.171h-.913v1.853H28.01zm4.67 0h3.33V29.6h-.93v3.67h.93v1.008h-3.33v-1.009h.932V29.6h-.931zm-3.204 1.063v1.702h.42c.249 0 .454-.015.615-.046a.871.871 0 0 0 .408-.183.694.694 0 0 0 .203-.29c.043-.115.064-.253.064-.416a.664.664 0 0 0-.129-.412.705.705 0 0 0-.302-.245 1.326 1.326 0 0 0-.447-.1c-.145-.007-.338-.01-.58-.01z"
      />
    </svg>
  );
};

export default Pi;
