const Mt = (props) => {
  return (
    <svg
      width={200}
      height={200}
      viewBox="0 0 52.917 52.917"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        style={{
          fill: 'red',
          fillOpacity: 1,
          strokeWidth: 0.259645,
        }}
        d="M16.904 2.084c-.31-.001-.644 1.239-.658 2.586-.008.74-.217 1.871-.463 2.514l-.447 1.168L8.3 8.258l-7.034-.095-.114 3.014c-.063 1.658-.063 3.724 0 4.59l.113 1.575 1.85-.06c2.083-.067 4.576.59 5.036 1.326.183.295.201.798.053 1.458-.167.738-.127 1.209.149 1.762.211.423.377 1.485.377 2.417 0 1.517-.094 1.802-1.076 3.27-.592.885-1.312 1.872-1.603 2.193-.29.321-.397.584-.238.584.159 0 .36.555.448 1.234.088.678.329 1.616.535 2.085.207.47.376 1.433.376 2.142 0 1.15-.06 1.29-.565 1.29h-.564l.57.607c.353.375.677 1.217.852 2.207l.282 1.6h4.111c2.495 0 4.175.103 4.274.263.09.144-.024.637-.252 1.094-.807 1.617.234 4.355 1.656 4.355.232 0 .85.292 1.375.649 1.296.88 2.164.85 2.652-.093.211-.408.711-.87 1.11-1.027 2.415-.944 4.274-.869 5.763.233.682.504 1.19.635 2.58.662.952.018 2.165.117 2.695.22.772.152 1.1.08 1.658-.357 1-.788 1.791-.71 2.173.214.174.417.24 1 .146 1.295-.094.295-.098.654-.008.798.089.145.512.262.941.262h.779l-.174-1.764c-.163-1.65-.13-1.816.505-2.57.374-.444 1.3-1.724 2.057-2.845.758-1.12 1.934-2.458 2.612-2.972.679-.513 1.409-1.273 1.623-1.687.513-.99 1.362-1.888 2.202-2.324.792-.413 1.133-1.193 1.502-3.448.148-.904.556-2.25.907-2.993.623-1.317.635-1.454.49-5.903l-.146-4.554.973-2.92c.535-1.607.931-2.957.88-3-.177-.15-8.473-.658-15.28-.936a1055.71 1055.71 0 0 1-10.69-.488l-3.809-.205-1.696-1.35c-2.313-1.84-3.019-2.73-3.019-3.801 0-.865-.894-3.66-1.32-4.122-.036-.04-.074-.058-.112-.059zm7.039 22.874H25.1l.87 1.94.868-1.94h4.545v.749h-1.208v3.119h-.998v-3.12h-1.18v3.12h-.993v-2.59l-.717 1.68H25.6l-.716-1.68v2.59h-.94z"
      />
    </svg>
  );
};

export default Mt;
