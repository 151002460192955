import styled from 'styled-components';

export const Conteudo = styled.div`
  h3 {
    font-family: 'Fira Sans', sans-serif !important;
    font-size: 18px;
  }
  .linhaInfo {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-left: 1rem;
    font-size: 14px;

    label {
      font-weight: 800;
    }
  }
  h4 {
    font-family: 'Fira Sans', sans-serif !important;
    font-weight: 300;
    margin-top: 1rem;
    padding: 0.5rem 0rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .campoBaixar {
    display: flex;
    justify-items: center;
    align-content: center;
    align-items: center;
  }
  .botaoBaixarExcel {
    background-color: #217346;
    margin: 1rem auto;
    align-self: center;
    border: 0px;
    color: #fff;
    padding: 1rem 1.25rem;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    img {
      width: 20px;
      margin-right: 0.5rem;
    }
  }
  .botaoBaixarExcel:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  }
`;
