import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  // backgroundColor:'transparent',
  border: 0px;
  border-radius: 6px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  //  max-width:1000px;
  margin: auto;
  padding: 2rem;

  header {
    padding-bottom: 1rem;
    border-bottom: 1px solid #d3d3d3;

    h1 {
      font-weight: 800;
      color: rgba(0, 0, 0, 0.8);
    }

    p {
      color: rgba(0, 0, 0, 0.7);
      font-weight: 600;
    }
  }

  .funcoesdefiltro {
    margin: 1rem 0rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .MenuDireita {
    display: flex;
    flex-direction: row;
    margin: 0.5rem;

    .cadastroManual {
      background-color: ${(props) => props.theme.colors.primaryDark};
      /* margin: 0.8rem auto; */
      align-self: center;
      border: 0px;
      color: #fff;
      padding: 1.15rem 1.25rem;
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      img {
        width: 20px;
        margin-right: 0.5rem;
      }
    }
    .cadastroManual:hover {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    }
  }

  .campoGeral {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    .card {
      display: flex;
      flex-direction: row;
      max-width: 360px;
      min-width: 360px;
      flex: 1;
      height: 150px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
      margin: 1rem 1rem 2rem 1rem;
      border-radius: 5px;
      border-radius: 0.5rem;
      overflow: hidden;

      .campo1 {
        flex: 1;
        background-color: rgba(255, 255, 255, 1);
        /* background-color: red; */
        display: flex;
        justify-content: center;
        justify-items: center;
        align-items: center;
        height: 150px;
        border-radius: 5px;

        img {
          border-radius: 5px;
          width: 100%;
          height: auto;
          max-height: 100%;
        }
      }
      .campo2 {
        border-radius: 5px;
        flex: 2;
        background-color: ${(props) => props.theme.colors.primaryDark};
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 13px;
        height: 150px;
        color: var(--white);

        .infor {
          padding: 1rem;
          height: 150px;
          label {
            font-size: 14px;
            font-weight: 600;
            color: var(--white);
          }
          a {
            /* text-decoration: none; */
            color: var(--white);
          }
          .criado {
            font-size: 12px;
            opacity: 0.8;
          }
        }

        .campoMenu {
          bottom: 0px;
          /* text-align: right; */
          /* margin-top: -1rem; */
          padding: 0px;
          position: relative !important;
          right: 0px;

          .botoes {
            margin: 0rem;
            position: absolute;
            background-color: var(--white);
            border-radius: 0rem;
            /* margin-right: -0.5rem;
            margin-bottom: -0.5rem; */
          }
          .btnedit {
            bottom: 0rem;
            right: 2.5rem;
            padding: 0.75rem 0.5rem;
            border-radius: 0.5rem 0 0 0;
          }
          .btnedit:hover {
            background-color: #a7a7a7;
            svg {
              color: #fff;
            }
          }
          .btndel {
            bottom: 0rem;
            right: 0rem;
            padding: 0.75rem 0.5rem;
          }
          .btndel:hover {
            background-color: #f44336;
            svg {
              color: #fff;
            }
          }
        }
      }
    }
  }
`;
