import React, { useCallback } from 'react';

import { Container, Body } from './styles';
// api
import API from '../../../services/api';

// backdrop
import Backdrop from '../../../components/Backdrop';

// editor de texto
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';

function Exportardados(props) {
  const [editarmsg1, setEditarmsg1] = React.useState(false);
  const [editarmsg2, setEditarmsg2] = React.useState(false);

  const [msgnational, setMsgnational] = React.useState();
  const [setMsgexpedition] = React.useState();
  const [backdrop, setBackdrop] = React.useState(true);
  // primeiro editor
  const [dados, setDados] = React.useState({
    description: '',
  });
  const [id1, setId1] = React.useState('')
  const [id2, setId2] = React.useState('')
  const [textdescription, setTextdescription] = React.useState({
    description: EditorState.createEmpty(),
  });
  // segundo editor
  const [dados2, setDados2] = React.useState({
    description: '',
  });
  const [textdescription2, setTextdescription2] = React.useState({
    description: EditorState.createEmpty(),
  });

  const atualizarMensagens = useCallback(
    (e) => {
      setBackdrop(true);

      const config = {
        headers: {
          Authorization: 'Bearer ' + props.user.token,
          'Content-Type': 'application/json',
        },
      };
      API.get('/messages-accreditation', config)
        .then((a) => {
          // console.log(a.data);
          var aux = a.data.find((a) => a.type === 'national' && true);
          var aux2 = a.data.find((a) => a.type === 'expedition' && true);

          setMsgnational(aux);

          if(aux) {
            setDados({
              ...dados,
              description: '<div>' + aux.message + '</div>',
            });
            setId1(aux.id)
          }
          if(aux2) {
            setDados2({
              ...dados2,
              description: '<div>' + aux2.message + '</div>',
            });
            setId2(aux2.id)
          }

        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setBackdrop(false));
    },
    [dados, dados2, props.user.token, setMsgexpedition, msgnational],
  );

  const novaMensagem = (e) => {
    setBackdrop(true);

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    var aux = e === 1 ? dados.description : e === 2 ? dados2.description : null;

    var typeMessage = e === 1 ? 'national' : e === 2 ? 'expedition' : null;
  
    API.post(`/messages-accreditation/`, { message: aux, type: typeMessage }, config)
      .then((a) => {
        atualizarMensagens();
        e === 1 ? setEditarmsg1(false) : setEditarmsg2(false);
        setBackdrop(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const editarMensagem = (e) => {
    setBackdrop(true);

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    var aux = e === 1 ? dados.description : e === 2 ? dados2.description : null;

    var id = e === 1 ? id1 : e === 2 ? id2 : null;

    var typeMessage = e === 1 ? 'expedition' : e === 2 ? 'national' : null;

    API.put(`/messages-accreditation/${id}`, { message: aux, type: typeMessage }, config)
      .then((a) => {
        atualizarMensagens();
        e === 1 ? setEditarmsg1(false) : setEditarmsg2(false);
        setBackdrop(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const onEditorStateChange = useCallback((editorState) => {
    var aux = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    setDados({
      ...dados,
      description: aux,
    });

    setTextdescription({
      ...textdescription,
      description: editorState,
    });
  }, []);

  const onEditorStateChange2 = (editorState) => {
    var aux = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    setDados2({
      ...dados2,
      description: aux,
    });

    setTextdescription2({
      ...textdescription2,
      description: editorState,
    });
  };

  const HTMLToText = (string) => {
    let a = string;
    a = a.replace(/<p>/g, '');
    a = a.replace(/<\/p>/g, '\n');
    a = a.replace(/<br \/>/g, '\n');

    a = a.replace(/<strong>/g, ' *');
    a = a.replace(/<\/strong>/g, '* ');

    a = a.replace(/<em>/g, ' _');
    a = a.replace(/<\/em>/g, '_ ');

    a = a.replace(/<del>/g, ' ~');
    a = a.replace(/<\/del>/g, '~ ');

    //console.log(a);
    return a;
  };

  React.useEffect(
    (e) => {
      if (!msgnational) atualizarMensagens();
    },
    [],
  );


  return (
    <Container>
      <header>
        <h1>Alterar Mensagens</h1>
        <p>
          Altere o corpo da mensagem enviada aos participantes no momento de
          inscrição.
        </p>
      </header>
      <Body>
        <details open>
          <summary>Eventos Nacionais</summary>
          <div className="content">
            {dados.description ? (
              <div
                className="content__description"
                dangerouslySetInnerHTML={{
                  __html:
                    '<p><p style="color: rgba(0,0,0,0.6)">Olá *(Nome do Participante)*, gostaríamos de agradecer pela sua presença no evento de *(Local do Evento)* da ' +
                    process.env.REACT_APP_NAME_SITE +
                    ' no dia *(Data do Evento)*.</p><br/>' +
                    '<p>' +
                    HTMLToText(dados.description) +
                    '</p><br/>' +
                    '<p style="color: rgba(0,0,0,0.6)"> Segue abaixo a data e o código de inscrição do evento:</p><p style="color: rgba(0,0,0,0.6)">Data: *(dia)*, *(mês)* - *(ano)* - *(hora/min)*</p> <p style="color: rgba(0,0,0,0.6)">Cód. Inscrição: *(código)*</p>',
                }}
              ></div>
            ) : (
              <div
                className="content__description"
                dangerouslySetInnerHTML={{
                  __html:
                    '<p><p style="color: rgba(0,0,0,0.6)">Olá *(Nome do Participante)*, gostaríamos de agradecer pela sua presença no evento de *(Local do Evento)* da ' +
                    process.env.REACT_APP_NAME_SITE +
                    ' no dia *(Data do Evento)*.</p><br/>' +
                    '<p>' +
                    '<span style="color:red">(sua mensagem aqui)</span>' +
                    '</p><br/>' +
                    '<p style="color: rgba(0,0,0,0.6)"> Segue abaixo a data e o código de inscrição do evento:</p><p style="color: rgba(0,0,0,0.6)">Data: *(dia)*, *(mês)* - *(ano)* - *(hora/min)*</p> <p style="color: rgba(0,0,0,0.6)">Cód. Inscrição: *(código)*</p>',
                }}
              ></div>
            )}

            {editarmsg1 && !backdrop && (
              <div className="formulario">
                <div
                  style={{
                    border: '1px solid rgba(0,0,0,0.2)',
                    margin: '2rem 0rem',
                    padding: '5px',
                    borderRadius: '5px',
                  }}
                >
                  <Editor
                    toolbar={{
                      options: [
                        //'fontSize',
                        'inline',
                        'emoji',
                        'history',
                        // 'list',
                        // 'textAlign',
                        // 'colorPicker',
                        // 'image',
                        // 'link',

                        // 'blockType',
                        // 'fontFamily',
                        // 'embedded',
                        // 'remove',
                      ],
                      inline: {
                        options: [
                          'bold',
                          'italic',
                          'strikethrough',
                          // 'underline',
                          // 'monospace',
                          // 'superscript',
                          // 'subscript',
                        ],
                      },
                    }}
                    defaultEditorState={EditorState.createWithContent(
                      ContentState.createFromBlockArray(
                        convertFromHTML(dados && dados?.description),
                      ),
                    )}
                    editorState={textdescription.description}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={onEditorStateChange}
                    placeholder="Escreva aqui sua mensagem..."
                  />
                </div>
              </div>
            )}
            <div className="action__buttons">
              {editarmsg1 && (
                <button
                  className="button__cancel"
                  onClick={() => setEditarmsg1(false)}
                >
                  Cancelar
                </button>
              )}
              <button
                className="button__save"
                onClick={() =>
                  editarmsg1 ? (id1 ? editarMensagem(1): novaMensagem(1)) : setEditarmsg1(true)
                }
              >
                {editarmsg1 ? 'Salvar' : 'Editar'}
              </button>
            </div>
          </div>
        </details>
        <details>
          <summary>Eventos Regional</summary>
          <div className="content">
            {dados2.description ? (
              <div
                className="content__description"
                dangerouslySetInnerHTML={{
                  __html:
                    '<p><p style="color: rgba(0,0,0,0.6)">Olá *(Nome do Participante)*, gostaríamos de agradecer pela sua presença no evento de *(Local do Evento)* da ' +
                    process.env.REACT_APP_NAME_SITE +
                    ' no dia *(Data do Evento)*.</p><br/>' +
                    '<p>' +
                    HTMLToText(dados2.description) +
                    '</p><br/>' +
                    '<p style="color: rgba(0,0,0,0.6)"> Segue abaixo a data e o código de inscrição do evento:</p><p style="color: rgba(0,0,0,0.6)">Data: *(dia)*, *(mês)* - *(ano)* - *(hora/min)*</p> <p style="color: rgba(0,0,0,0.6)">Cód. Inscrição: *(código)*</p>',
                }}
              ></div>
            ) : (
              <div
                className="content__description"
                dangerouslySetInnerHTML={{
                  __html:
                    '<p><p style="color: rgba(0,0,0,0.6)">Olá *(Nome do Participante)*, gostaríamos de agradecer pela sua presença no evento de *(Local do Evento)* da ' +
                    process.env.REACT_APP_NAME_SITE +
                    ' no dia *(Data do Evento)*.</p><br/>' +
                    '<p>' +
                    '<span style="color:red">(sua mensagem aqui)</span>' +
                    '</p><br/>' +
                    '<p style="color: rgba(0,0,0,0.6)"> Segue abaixo a data e o código de inscrição do evento:</p><p style="color: rgba(0,0,0,0.6)">Data: *(dia)*, *(mês)* - *(ano)* - *(hora/min)*</p> <p style="color: rgba(0,0,0,0.6)">Cód. Inscrição: *(código)*</p>',
                }}
              ></div>
            )}

            {editarmsg2 && !backdrop && (
              <div className="formulario">
                <div
                  style={{
                    border: '1px solid rgba(0,0,0,0.2)',
                    margin: '2rem 0rem',
                    padding: '5px',
                    borderRadius: '5px',
                  }}
                >
                  <Editor
                    toolbar={{
                      options: [
                        //'fontSize',
                        'inline',
                        'emoji',
                        'history',
                        // 'list',
                        // 'textAlign',
                        // 'colorPicker',
                        // 'image',
                        // 'link',

                        // 'blockType',
                        // 'fontFamily',
                        // 'embedded',
                        // 'remove',
                      ],
                      inline: {
                        options: [
                          'bold',
                          'italic',
                          'strikethrough',
                          // 'underline',
                          // 'monospace',
                          // 'superscript',
                          // 'subscript',
                        ],
                      },
                    }}
                    defaultEditorState={EditorState.createWithContent(
                      ContentState.createFromBlockArray(
                        convertFromHTML(dados2 && dados2.description),
                      ),
                    )}
                    editorState={textdescription2.description}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={onEditorStateChange2}
                    placeholder="Escreva aqui sua mensagem..."
                  />
                </div>
              </div>
            )}
            <div className="action__buttons">
              {editarmsg2 && (
                <button
                  className="button__cancel"
                  onClick={() => setEditarmsg2(false)}
                >
                  Cancelar
                </button>
              )}
              <button
                className="button__save"
                onClick={() =>
                  editarmsg2 ? (id2 ? editarMensagem(2): novaMensagem(2)) : setEditarmsg2(true)
                }
              >
                {editarmsg2 ? 'Salvar' : 'Editar'}
              </button>
            </div>
          </div>
        </details>
      </Body>

      {backdrop ? <Backdrop /> : ''}
    </Container>
  );
}

export default Exportardados;
