import React, { useEffect, useState } from 'react';
import { Container, Deck, Card } from './styles';

import api from '../../../services/api';

// icones
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';

import Dialogodeconfirmacao from '../../../components/Dialogodeconfirmacao';

import { useEvents } from '../../../context/eventsContext';
import Modalcriarevento from '../../../components/Modalcriarevento';
import imageUrl from '../../../utils/imageUrl';
import { toast } from 'react-toastify';
import Novobannerhome from '../../../components/Novobannerhome';

const Banners = (props) => {
  const { getData } = useEvents();

  const [listbanners, setListbanners] = React.useState([]);

  const [confirmDelete, setConfirmDelete] = useState([false, '']);

  function getBanners() {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
      },
      params: {
        show_in_home: 'true',
      },
    };

    api
      .get('/banners', config)
      .then((response) => {
        console.log(response.data.length);
        console.log(response.data);
        setListbanners(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const deleteBanner = (id) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
      },
    };

    api
      .delete(`banners/${id}`, config)
      .then((a) => {
        setConfirmDelete([false, '']);
        getBanners();
        toast.success('Banner deletado com sucesso.');
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getBanners();
  }, []);

  return (
    <Container>
      <header>
        <h1>Banners HomePage {process.env.REACT_APP_NAME_SITE}</h1>
        <p>Lista de banners publicados para HomePage.</p>
      </header>

      <div className="newBanner">
        <div className="btnNew">
          <Novobannerhome
            user={props.user}
            getBanner={getBanners}
            setValue={setListbanners}
          />
        </div>
      </div>

      <Deck>
        {listbanners
          ? listbanners?.map((banner) => (
              <Card
                className="card"
                key={banner.id}
                imgUrl={() => imageUrl(banner?.image_web)}
              >
                <div className="div__p1">
                  <div className="containerBanner" />
                  <div className="div__action_buttons">
                    <button
                      type="button"
                      className="button__del"
                      onClick={() => setConfirmDelete([true, banner.id])}
                    >
                      <DeleteIcon /> Deletar
                    </button>
                  </div>
                </div>
                <div className="div__link">
                  <a href={banner.link} target="_blank">
                    {' '}
                    <LinkIcon />
                    {banner?.link?.length > 40
                      ? banner?.link.slice(0, 40) + '...'
                      : banner?.link}
                  </a>
                </div>
              </Card>
            ))
          : 'Nenhum Banner Cadastrado!'}
      </Deck>

      {confirmDelete[0] ? (
        <Dialogodeconfirmacao
          tamanho="xs"
          message="Tem certeza de que deseja apagar este banner?"
          confirmar={() => deleteBanner(confirmDelete[1])}
          negar={() => setConfirmDelete([false, ''])}
        />
      ) : (
        ''
      )}
    </Container>
  );
};

export default Banners;
