import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  padding: 1rem 2rem;
  min-height: ${window.innerHeight};

  h1 {
    display: flex;
    flex-direction: row;
    /* justify-content:center; */
    align-items: center;

    .rascu {
      font-weight: 100;
      margin-left: 1rem;
      font-size: 12px;
      background-color: rgba(255, 237, 0, 0.5);
      padding: 0.2rem;
      color: rgb(150, 75, 0);
      border-radius: 5px;
    }
    .publi {
      font-weight: 100;
      margin-left: 1rem;
      font-size: 12px;
      background-color: #3f6a69;
      padding: 0.2rem;
      color: #fff;
      border-radius: 5px;
    }
  }
`;
