import { useEffect, useRef, useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Container } from './styles';

// services
import apiIBGEStates from '../../services/apiIBGEStates';
import apiIBGECidades from '../../services/apiIBGECidades';

// icones
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import AttachFileIcon from '@material-ui/icons/AttachFile'; // file icon

// mask
//@ts-ignore
import { mask } from 'remask';

// mascara
import InputMask from 'react-input-mask';
import api from '../../services/api';
import { toast } from 'react-toastify';

export default function ScrollDialog(props) {
  const [open, setOpen] = useState(props.onInscricao);
  const [scroll] = useState('paper');
  const [registered, setRegistered] = useState(false);
  // const [dataconfig, setDataconfig] = useState();
  const [liststates, setListstates] = useState([]);
  const [listcities, setListcities] = useState([]);

  const [userdata, setUserdata] = useState({
    is_exhibitor: props.edicao ? props.edicao.is_exhibitor : 'false',
    agree_privacy_policies: props.edicao
      ? String(props.edicao.agree_privacy_policies)
      : 'false',
    allow_use_image: props.edicao
      ? String(props.edicao.allow_use_image)
      : 'false',
    another_indication: props.edicao ? props.edicao?.another_indication : '',
    another_type_inscription: props.edicao
      ? props.edicao.another_type_inscription
      : '',
    city: props.edicao ? props.edicao.city : '',
    company_size: props.edicao ? props.edicao.company_size : '',
    complement: props.edicao ? props.edicao.complement : '',
    contracted_link: props.edicao ? props.edicao.contracted_link : '',
    cpf: props.edicao ? props.edicao.cpf : '',
    credential_company: props.edicao ? props.edicao.credential_company : '',
    credential_name: props.edicao ? props.edicao.credential_name : '',
    credential_role: props.edicao ? props.edicao.credential_role : '',
    district: props.edicao ? props.edicao.district : '',
    email: props.edicao ? props.edicao.email : '',
    event_id: props.edicao
      ? props.edicao.event_id
      : props?.eventId || undefined,
    has_asn: props.edicao ? props.edicao.has_asn : 'false',
    has_fiber_optic: props.edicao ? String(props.edicao.has_fiber_optic) : '',
    has_service_tv_iptv: props.edicao
      ? String(props.edicao.has_service_tv_iptv)
      : '',
    house_number: props.edicao ? props.edicao.house_number : '',
    name: props.edicao ? props.edicao.name : '',
    phone: props.edicao ? props.edicao.phone : '',
    state: props.edicao ? props.edicao.state : '',
    type_inscription: props.edicao ? props.edicao.type_inscription : '',
    who_indicated: props.edicao ? props.edicao.who_indicated : '',
  });

  function handleInputChange(event) {
    if (event.target.name === 'state') {
      setListcities([]);
      getCities(event.target.value);
    }
    switch (event.target.name) {
      case 'cpf':
        setUserdata({
          ...userdata,
          [event.target.name]: mask(event.target.value, '999.999.999-99'),
        });
        break;
      case 'phone':
        setUserdata({
          ...userdata,
          [event.target.name]: mask(event.target.value, [
            '(99) 9999-9999',
            '(99) 99999-9999',
          ]),
        });
        break;
      case 'allow_use_image':
        setUserdata({
          ...userdata,
          [event.target.name]:
            userdata.allow_use_image === 'true' ? 'false' : 'true',
        });
        break;
      case 'agree_privacy_policies':
        setUserdata({
          ...userdata,
          [event.target.name]:
            userdata.agree_privacy_policies === 'true' ? 'false' : 'true',
        });
        break;
      case 'credential_name':
        setUserdata({
          ...userdata,
          [event.target.name]: event.target.value.slice(0, 16),
        });
        break;
      case 'credential_company':
        setUserdata({
          ...userdata,
          [event.target.name]: event.target.value.slice(0, 16),
        });
        break;
      case 'credential_role':
        setUserdata({
          ...userdata,
          [event.target.name]: event.target.value.slice(0, 16),
        });
        break;
      default:
        setUserdata({
          ...userdata,
          [event.target.name]: event.target.value,
        });
    }
  }

  const handleClose = () => {
    setOpen(false);
    props.offInscricao();
  };

  // get states
  function getStates() {
    apiIBGEStates
      .get('')
      .then((response) => {
        setListstates(response.data);
      })
      .catch((error) => console.log('[ERROR]=> ', error.response.data));
  }
  // get cities
  function getCities(ufstate) {
    apiIBGECidades
      .get(`${ufstate}/municipios?orderBy=nome`)
      .then((response) => {
        setListcities(response.data);
      })
      .catch((error) => console.log('[ERROR]=> ', error.response));
  }

  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    getStates();
    props.edicao && getCities(userdata.state);
  }, []);

  const config = {
    headers: {
      Authorization: 'Bearer ' + props.user.token,
      'Content-Type': 'application/json',
    },
  };

  async function SET_NEW_ENROLLMENT(e) {
    e.preventDefault();

    let dataAux = userdata;
    userdata?.agree_privacy_policies === 'true'
      ? (dataAux.agree_privacy_policies = true)
      : (dataAux.agree_privacy_policies = false);
    userdata?.allow_use_image === 'true'
      ? (dataAux.allow_use_image = true)
      : (dataAux.allow_use_image = false);

    await api
      .post(`/enrollments`, dataAux)
      .then((response) => {
        toast.success('Cadastro realizada com sucesso!');
        handleClose();
      })
      .catch((error) => {
        const message = error.response.data.messages
          ? error.response.data.messages[0].message
          : error.response.data.message;
        toast.error(message);
      });
  }

  async function SET_EDIT_ENROLLMENT(e) {
    e.preventDefault();

    let dataAux = userdata;
    userdata?.agree_privacy_policies === 'true'
      ? (dataAux.agree_privacy_policies = true)
      : (dataAux.agree_privacy_policies = false);
    userdata?.allow_use_image === 'true'
      ? (dataAux.allow_use_image = true)
      : (dataAux.allow_use_image = false);

    await api
      .put(`/enrollments/${props.edicao.id}`, dataAux, config)
      .then((a) => {
        toast.success('Atualização realizada com sucesso!');
        handleClose();
      })
      .catch((error) => {
        const message = error.response.data.messages
          ? error.response.data.messages[0].message
          : error.response.data.message;
        toast.error(message);
      });
  }

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        scroll={'body'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullScreen={window.innerWidth > 675 ? false : true}
      >
        <Container>
          <form
            onSubmit={(event) =>
              props.edicao
                ? SET_EDIT_ENROLLMENT(event)
                : SET_NEW_ENROLLMENT(event)
            }
          >
            <DialogTitle id="scroll-dialog-title">Inscrição</DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                <div className="div__oneColumn">
                  {/* select event  */}
                  <div className="div__oneRow">
                    <div className="div__oneColumn flex1 special_field">
                      <label>
                        <span>Qual evento deseja se cadastrar?*</span>
                        <select
                          name="event_id"
                          placeholder="selecione o evento"
                          //@ts-ignore
                          value={userdata?.event_id}
                          disabled={props.eventId ? true : false}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">selecione o evento</option>
                          {props.listaeventos?.length > 0 &&
                            props.listaeventos?.map((dataEventUnic) => (
                              <option
                                key={dataEventUnic.id}
                                value={dataEventUnic.id}
                              >
                                {dataEventUnic?.city +
                                  '/' +
                                  dataEventUnic?.state +
                                  ' - ' +
                                  (dataEventUnic?.start_date &&
                                    new Date(
                                      // @ts-ignore
                                      dataEventUnic?.start_date,
                                    ).toLocaleString('pt-BR', {
                                      day: '2-digit',
                                      month: 'long',
                                      year: 'numeric',
                                    }))}
                              </option>
                            ))}
                        </select>
                      </label>
                    </div>
                  </div>
                  {/* General informations  */}
                  <h3>Informações gerais</h3>
                  <div className="margemDoH3" />
                  {/* cpf and name  */}
                  <div className="div__oneRow">
                    <div className="div__oneColumn flex1">
                      <label>
                        <span>CPF*</span>
                        <input
                          name="cpf"
                          type="text"
                          placeholder="preencha com seu CPF"
                          //@ts-ignore
                          value={userdata.cpf}
                          onChange={handleInputChange}
                          required
                        />
                      </label>
                    </div>
                    <div className="div__oneColumn flex3">
                      <label>
                        <span>Nome completo*</span>
                        <input
                          type="text"
                          name="name"
                          placeholder="preencha com seu nome"
                          value={userdata.name}
                          //@ts-ignore
                          onChange={handleInputChange}
                          required
                        />
                      </label>
                    </div>
                  </div>
                  {/* email and phone number  */}
                  <div className="div__oneRow">
                    <div className="div__oneColumn flex3">
                      <label>
                        <span>E-mail*</span>
                        <input
                          name="email"
                          type="email"
                          placeholder="preencha com seu e-mail"
                          value={userdata.email}
                          //@ts-ignore
                          onChange={handleInputChange}
                          required
                        />
                      </label>
                    </div>
                    <div className="div__oneColumn flex1">
                      <label>
                        <span>Telefone (Whatsapp)*</span>
                        <input
                          type="text"
                          name="phone"
                          placeholder="preencha com seu Whatsapp"
                          value={userdata.phone}
                          onChange={handleInputChange}
                          required
                        />
                      </label>
                    </div>
                  </div>
                  {/* state, city and district  */}
                  <div className="div__oneRow">
                    <div className="div__oneColumn flex1">
                      <label>
                        <span>Estado*</span>
                        <select
                          name="state"
                          placeholder="escolha o estado"
                          value={userdata.state}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">Escolha seu estado</option>
                          {liststates.map((state) => (
                            <option key={state.id} value={state.sigla}>
                              {state.sigla}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                    <div className="div__oneColumn flex2">
                      <label>
                        <span>Cidade*</span>
                        <select
                          name="city"
                          placeholder="preencha com sua cidade"
                          value={userdata.city}
                          onChange={handleInputChange}
                          disabled={listcities.length > 0 ? false : true}
                          required
                        >
                          <option value="">Escolha sua cidade</option>
                          {listcities.map((city) => (
                            <option key={city.id} value={city.nome}>
                              {city.nome}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                    <div className="div__oneColumn flex1">
                      <label>
                        <span>Bairro*</span>
                        <input
                          name="district"
                          placeholder="preencha com seu bairro"
                          value={userdata.district}
                          //@ts-ignore
                          onChange={handleInputChange}
                          required
                        />
                      </label>
                    </div>
                  </div>
                  {/* where did you hear about us */}
                  <div className="div__oneRow">
                    <div className="div__oneColumn flex1">
                      <label>
                        <span>Como nos conheceu?*</span>
                        <select
                          name="who_indicated"
                          placeholder="como nos conheceu"
                          required
                          value={userdata.who_indicated}
                          onChange={handleInputChange}
                        >
                          <option value="">como nos conheceu</option>
                          <option value={`Site INTER'ISP`}>
                            Site INTER&apos;ISP
                          </option>
                          <option value={`Instagram INTER'ISP`}>
                            Instagram INTER&apos;ISP
                          </option>
                          <option value={`Facebook INTER'ISP`}>
                            Facebook INTER&apos;ISP
                          </option>
                          <option value="other">Outro</option>
                        </select>
                      </label>
                    </div>
                    <div className="div__oneColumn flex2">
                      <label>
                        <span>Onde?</span>
                        <input
                          name="another_indication"
                          value={userdata.another_indication}
                          //@ts-ignore
                          onChange={handleInputChange}
                          placeholder={
                            userdata.who_indicated === 'other'
                              ? 'nos fale como'
                              : ' - '
                          }
                          disabled={
                            userdata.who_indicated === 'other' ? false : true
                          }
                        />
                      </label>
                    </div>
                  </div>

                  {/* Company information  */}
                  <h3>Informações da empresa</h3>
                  <div className="margemDoH3" />
                  {/* has fiber optical, has sva, company size, link quantity */}
                  <div
                    className="div__oneRow"
                    style={{ alignItems: 'end', marginBottom: '1rem' }}
                  >
                    <div className="div__oneColumn flex1">
                      <label>
                        <span>Tem fibra óptica FTTx?*</span>
                        <select
                          name="has_fiber_optic"
                          placeholder="tem fibra óptica?"
                          value={userdata.has_fiber_optic}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">selecione</option>
                          <option value="true">Sim</option>
                          <option value="false">Não</option>
                        </select>
                      </label>
                    </div>

                    <div className="div__oneColumn flex1">
                      <label>
                        <span>Tem serviços de telefonia, IPTV, etc?*</span>
                        <select
                          name="has_service_tv_iptv"
                          placeholder="tem estes serviços?"
                          value={userdata.has_service_tv_iptv}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">selecione</option>
                          <option value="true">Sim</option>
                          <option value="false">Não</option>
                        </select>
                      </label>
                    </div>

                    <div className="div__oneColumn flex1">
                      <label>
                        <span>Porte de clientes*</span>
                        <select
                          name="company_size"
                          placeholder="porte de clientes"
                          value={userdata.company_size}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">selecione</option>
                          <option value={'Até 100 clientes'}>
                            Até 100 clientes
                          </option>
                          <option value={'Até 500 clientes'}>
                            Até 500 clientes
                          </option>
                          <option value={'Até 1.000 clientes'}>
                            Até 1.000 clientes
                          </option>
                          <option value={'Até 2.000 clientes'}>
                            Até 2.000 clientes
                          </option>
                          <option value={'Até 5.000 clientes'}>
                            Até 5.000 clientes
                          </option>
                          <option value={'Até 10.000 clientes'}>
                            Até 10.000 clientes
                          </option>
                          <option value={'Mais de 10.000 clientes'}>
                            Mais de 10.000 clientes
                          </option>
                          <option value={' Não se aplica'}>
                            Não se aplica
                          </option>
                        </select>
                      </label>
                    </div>
                    <div className="div__oneColumn flex1">
                      <label>
                        <span>Quantidade de link*</span>
                        <select
                          name="contracted_link"
                          placeholder="porte de clientes"
                          value={userdata.contracted_link}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">selecione</option>
                          <option value={'Até 100MB'}>Até 100MB</option>
                          <option value={'Até 500MB'}>Até 500MB</option>
                          <option value={'Até 1GB'}>Até 1GB</option>
                          <option value={'Até 5GB'}>Até 5GB</option>
                          <option value={'Até 10GB'}>Até 10GB</option>
                          <option value={'Até 30GB'}>Até 30GB</option>
                          <option value={'Mais de 30GB'}>Mais de 30GB</option>
                          <option value={'Não se aplica'}>Não se aplica</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  {/* type enroollments and other type enrollments  */}
                  <div className="div__oneRow">
                    <div className="div__oneColumn flex1">
                      <label>
                        <span>Tipo de inscrição*</span>
                        <select
                          name="type_inscription"
                          placeholder="selecione tipo de inscrição"
                          required
                          value={userdata.type_inscription}
                          onChange={handleInputChange}
                        >
                          <option value="">selecione tipo de inscrição</option>
                          <option value="Provedor de internet">
                            Provedor de internet
                          </option>
                          <option value="Operador de link">
                            Operador de link
                          </option>
                          <option value="Integrador">Integrador</option>

                          <option value="Fabricante/Importador">
                            Fabricante/Importador
                          </option>
                          <option value="Instalação/Manutenção">
                            Instalação/Manutenção
                          </option>
                          <option value="Orgão governamental">
                            Orgão governamental
                          </option>
                          <option value="Instituição de ensino">
                            Instituição de ensino
                          </option>
                          <option value="Distribuidor/Revendedor">
                            Distribuidor/Revendedor
                          </option>
                          <option value="other">Outro</option>
                        </select>
                      </label>
                    </div>
                    <div className="div__oneColumn flex2">
                      <label>
                        <span>Em caso de outro, qual?</span>
                        <input
                          name="another_type_inscription"
                          value={userdata.another_type_inscription}
                          onChange={handleInputChange}
                          placeholder={
                            userdata.type_inscription === 'other'
                              ? 'nos informe qual'
                              : ' - '
                          }
                          disabled={
                            userdata.type_inscription === 'other' ? false : true
                          }
                        />
                      </label>
                    </div>
                  </div>

                  {/* Second Subsection  */}
                  <h3>Dados para credencial</h3>
                  <div className="margemDoH3" />
                  {/* credential name, credential company, credential role  */}
                  <div className="div__oneRow">
                    <div className="div__oneColumn flex1">
                      <label>
                        <span>Nome*</span>
                        <input
                          name="credential_name"
                          placeholder="informe seu nome"
                          value={userdata.credential_name}
                          onChange={handleInputChange}
                          required
                        />
                      </label>
                    </div>
                    <div className="div__oneColumn flex1">
                      <label>
                        <span>Empresa*</span>
                        <input
                          name="credential_company"
                          placeholder="informe sua empresa"
                          value={userdata.credential_company}
                          onChange={handleInputChange}
                          required
                        />
                      </label>
                    </div>
                    <div className="div__oneColumn flex1">
                      <label>
                        <span>Cargo*</span>
                        <input
                          name="credential_role"
                          placeholder="informe seu cargo"
                          value={userdata.credential_role}
                          onChange={handleInputChange}
                          required
                        />
                      </label>
                    </div>
                  </div>
                  <div className="div__oneRow" style={{ marginTop: '2rem' }}>
                    <div className="div__oneRow">
                      <label
                        className={
                          userdata.agree_privacy_policies === 'true'
                            ? 'div__use_terms selected_field flex2'
                            : 'div__use_terms flex2'
                        }
                      >
                        <input
                          name="agree_privacy_policies"
                          type="checkbox"
                          required
                          checked={
                            userdata.agree_privacy_policies === 'true'
                              ? true
                              : false
                          }
                          onChange={handleInputChange}
                        />
                        Declaro que li e aceito as políticas de uso.*
                      </label>
                      <div
                        className="div__file_field"
                        onClick={() =>
                          window?.open(
                            'https://interisp.com.br/termos',
                            '_blank',
                          )
                        }
                      >
                        <h5>Políticas de uso</h5>
                        <AttachFileIcon />
                      </div>
                    </div>
                    <div className="div__oneRow">
                      <label
                        className={
                          userdata.allow_use_image === 'true'
                            ? 'div__use_terms selected_field flex3'
                            : 'div__use_terms flex3'
                        }
                      >
                        <input
                          name="allow_use_image"
                          type="checkbox"
                          required
                          onChange={handleInputChange}
                          checked={
                            userdata.allow_use_image === 'true' ? true : false
                          }
                        />
                        Autorizo o uso de minha imagem em canais de divulgação
                        do evento {`INTER'ISP`}.*
                      </label>
                      <div
                        className="div__file_field"
                        onClick={() =>
                          window?.open(
                            'https://interisp.com.br/privacidade',
                            '_blank',
                          )
                        }
                      >
                        <h5>Uso de imagem</h5>
                        <AttachFileIcon />
                      </div>
                    </div>
                  </div>
                </div>
                <p>* Campos obrigatórios</p>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                className="cancelar"
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                // color="primary"
                className="confirmar"
                // disabled={emProcesso}
              >
                {props.edicao ? 'Atualizar' : 'Inscrever-se'}
              </Button>
            </DialogActions>
          </form>
        </Container>
      </Dialog>
    </div>
  );
}
