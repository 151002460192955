import styled from 'styled-components';

export const Container = styled.div`
  .subtitulo {
    font-size: 16px;
  }

  .formulario {
    display: flex !important;
    flex-direction: column !important;

    .cidade-uf {
      display: flex !important;
      flex-direction: row !important;
    }

    .local-data {
      display: flex !important;
      flex-direction: row !important;
      margin-bottom: 2rem;
    }

    .campo,
    .apresentarHome {
      margin: 0.5rem 1rem;
    }

    .imagematual {
      text-align: center;
      h3 {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 500;
        margin: 1rem 0rem;
        text-align: left;
      }
      img {
        margin: auto;
      }
    }
  }

  //Butão [adicionar evento]
  .Button {
    background-color: #245c9d;
    color: #fff;
    font-weight: bold;
  }
  .Button:hover {
    background-color: #245c9d;
    color: #fff;
  }

  //Butão Modal

  .modal .paper .buttonSave .buttonModal {
    .MuiButton-contained {
      background-color: #245c9d;
      color: #fff;
    }
  }
`;
