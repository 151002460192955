import React, { useCallback, useState } from 'react';

import { Container } from './styles';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

// backdrop
import Backdrop from '../../../components/Backdrop';

// icons
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

// api
import API from '../../../services/api';

import Dialogodeconfirmacao from '../../../components/Dialogodeconfirmacao';
import Novousuario from '../../../components/Novousuario';
import Novasenha from '../../../components/Novasenha';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Configuracoes(props) {
  const [update, setUpdate] = useState(false);
  const [users, setUsers] = useState([]);
  const [editar, setEditar] = useState();

  const [user] = useState(props.user);
  const [backdrop, setBackdrop] = React.useState(false);
  const [newUser, setNewUser] = React.useState(false);
  const [mudarsenha, setMudarsenha] = React.useState({
    status: '',
    id: '',
  });

  const [dadosedit, setDadosedit] = React.useState({});

  const [dados, setDados] = useState({
    name: props.user.name,
    email: props.user.email,
    password: '',
    is_admin: props.user.is_admin,
    id: props.user.id,
  });

  const [alerta, setAlerta] = React.useState({
    open: false,
    status: '',
    message: '',
  });

  const [confirmacao, setConfirmacao] = useState([false, '']);

  const handleChange = (e) => {
    setDados({ ...dados, [e.target.name]: e.target.value });
  };

  const atualiarUsuario = (e) => {
    e.preventDefault();
    setBackdrop(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    API.put(`/users/${props.user.id}`, dados, config)
      .then((a) => {
        // console.log("Atualização: ",a.data);
        setUpdate(false);
        setAlerta({
          open: true,
          status: 'success',
          message: 'Dados alterados com sucesso! Novo login será solicitado.',
        });
        setTimeout(() => {
          props.logOff();
        }, 3000);
        // setBackdrop(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const atualizarListaUsuarios = useCallback(
    (e) => {
      setBackdrop(true);
      const config = {
        headers: {
          Authorization: 'Bearer ' + props.user.token,
          'Content-Type': 'application/json',
        },
      };

      API.get(`/users`, config)
        .then((a) => {
          setUsers(a.data);
          setBackdrop(false);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    [props.user.token]
  );

  const deletaruser = (e) => {
    setBackdrop(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    API.delete(`/users/${e}`, config)
      .then((a) => {
        setAlerta({
          open: true,
          status: 'success',
          message: 'Usuário deletado com sucesso.',
        });
        setBackdrop(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  React.useEffect(() => {
    if (users.length === 0) atualizarListaUsuarios();
  }, [atualizarListaUsuarios, users.length]);

  return (
    <Container>
      <header>
        <h1>Configurações de conta</h1>
        <p>Configure os dados de acesso da sua conta.</p>
      </header>

      <div className="myaccount">
        <div className="header">
          <p>Minha Conta</p>
          {update ? (
            <button disabled style={{ pointerEvents: 'none' }}>
              <EditIcon style={{ color: 'transparent' }} />
            </button>
          ) : (
            <button onClick={() => setUpdate(!update)}>
              <EditIcon />
            </button>
          )}
        </div>
        <div className="itens">
          <form onSubmit={atualiarUsuario}>
            <table>
              <tbody>
                <tr>
                  <th>Nome: </th>
                  <td>
                    {update ? (
                      <input
                        type="text"
                        name="name"
                        value={dados.name}
                        onChange={handleChange}
                      />
                    ) : (
                      dados.name
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Email: </th>
                  <td>
                    {update ? (
                      <input
                        type="text"
                        name="email"
                        value={dados.email}
                        onChange={handleChange}
                      />
                    ) : (
                      dados.email
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Função: </th>
                  <td>
                    {update ? (
                      <select
                        required
                        style={{ flex: 1 }}
                        onChange={handleChange}
                        name="is_admin"
                        value={dados.is_admin}
                        disabled={true}
                      >
                        <option value="true">Administrador</option>
                        <option value="false">Moderador</option>
                      </select>
                    ) : dados.is_admin ? (
                      'Administrador'
                    ) : (
                      'Moderador'
                    )}
                  </td>
                </tr>
                <tr>
                  <th>{update ? '' : 'Senha:'} </th>
                  <td>
                    {update ? (
                      ''
                    ) : (
                      <>
                        <label
                          className="linkSenha"
                          onClick={() =>
                            setMudarsenha({ status: true, id: dados.id })
                          }
                        >
                          editar senha
                        </label>
                      </>
                    )}
                  </td>
                </tr>

                <tr>
                  <th></th>
                  {update ? (
                    <td className="buttons">
                      <button className="update" type="submit">
                        Atualizar
                      </button>

                      <span onClick={() => setUpdate(false)}>Cancelar</span>
                    </td>
                  ) : (
                    ''
                  )}
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>

      {props.user.is_admin ? (
        <div className="othersaccount">
          <div className="header">
            <p>Outras Contas</p>
            <button onClick={() => setNewUser(true)}>Nova Conta</button>
          </div>
          <div className="itens">
            <table>
              <thead>
                <tr>
                  <th style={{ flex: 2 }}>Nome</th>
                  <th style={{ flex: 2 }}>Email</th>
                  <th style={{ flex: 1 }}>Usuário</th>
                  <th style={{ flex: 1 }}>Senha</th>
                  <th className="option">Opções</th>
                </tr>
              </thead>
              <tbody>
                {users.map((usuario, i) =>
                  usuario.id !== user.id ? (
                    <tr key={i}>
                      <td style={{ flex: 2 }}>{usuario.name}</td>
                      <td style={{ flex: 2 }}>{usuario.email}</td>
                      <td style={{ flex: 1 }}>
                        {usuario.is_admin ? 'Admin' : 'Moderador'}
                      </td>
                      <td style={{ flex: 1 }}>
                        <label
                          className="linkSenha"
                          onClick={() =>
                            setMudarsenha({ status: true, id: usuario.id })
                          }
                        >
                          (editar senha)
                        </label>
                      </td>
                      <td className="option">
                        <IconButton
                          onClick={() => {
                            setEditar(true);
                            setDadosedit(usuario);
                            setNewUser(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => setConfirmacao([true, usuario.id])}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </td>
                    </tr>
                  ) : (
                    ''
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ''
      )}

      {alerta.open ? (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={(e) => setAlerta({ open: false, status: '', message: '' })}
        >
          <Alert
            onClose={(e) => setAlerta({ open: false, status: '', message: '' })}
            severity={alerta.status}
          >
            {alerta.message}
          </Alert>
        </Snackbar>
      ) : (
        ''
      )}

      {confirmacao[0] ? (
        <Dialogodeconfirmacao
          tamanho="xs"
          message="Tem certeza de que deseja deletar este usuário?"
          confirmar={() => {
            deletaruser(confirmacao[1]);
            setConfirmacao([false, '']);
            atualizarListaUsuarios();
          }}
          negar={() => setConfirmacao([false, ''])}
        />
      ) : (
        ''
      )}
      {backdrop ? <Backdrop /> : ''}
      {newUser ? (
        <Novousuario
          editar={editar}
          dados={dadosedit}
          alerta={(e) =>
            setAlerta({
              open: e.open,
              status: e.status,
              message: e.message,
            })
          }
          negar={() => {
            setNewUser(false);
            atualizarListaUsuarios();
            setDadosedit({});
          }}
          user={props.user}
        />
      ) : (
        ''
      )}
      {mudarsenha.status && (
        <Novasenha
          user={user}
          negar={() => setMudarsenha({ status: '', id: '' })}
          dados={mudarsenha}
          alerta={(e) =>
            setAlerta({
              open: e.open,
              status: e.status,
              message: e.message,
            })
          }
        />
      )}
    </Container>
  );
}

export default Configuracoes;
