import React, { useCallback, useEffect, useState } from 'react';

import { Container } from './styles';

// icons
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
// dropdown
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import format from 'date-fns/format';
import Backdrop from '../../../components/Backdrop';

// api
import api from '../../../services/api';

import Dialogodeconfirmacao from '../../../components/Dialogodeconfirmacao';
import Novoapoiador from '../../../components/Novoapoiador';

function Configuracoes(props) {
  const [backdrop, setBackdrop] = useState(false);
  const [confirmacao, setConfirmacao] = useState([false, '']);

  const [filtro, setFiltro] = React.useState({
    tipo: '',
    descricao: '',
  });

  const [dialogoApoiador, setDialogoApoiador] = React.useState({
    status: false,
    editar: false,
    dados: '',
  });

  const listEvents = useCallback(() => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
      },
    };

    api
      .get('/events', config)
      .then((response) => {
        let events = response.data;

        setEventos([...events.nextEvents, ...events.previousEvents]);
        // console.log(events);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.user.token]);

  // chamada api
  const [supporters, setSupporters] = useState([]);
  const [dadosfilter, setDadosfilter] = useState([]);
  const [eventos, setEventos] = useState([]);

  const getSupporters = useCallback(async () => {
    setBackdrop(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    await api
      .get(`/supporters`, config)
      .then((response) => {
        response.data.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
        setSupporters(response.data);
        setBackdrop(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, [props.user.token, setBackdrop]);

  const removeSupporters = async (id) => {
    setBackdrop(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    await api
      .delete(`/supporters/${id}`, config)
      .then((response) => {
        setBackdrop(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const aplicarFiltro = async (e) => {
    setFiltro({ ...filtro, descricao: e.target.value });

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    if (e) {
      await api
        .get(`/related-support-event/${e.target.value}`, config)
        .then((response) => {
          setDadosfilter(response.data);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    } else {
      setDadosfilter([]);
    }
  };

  useEffect(() => {
    getSupporters();
    if (eventos.length === 0) listEvents();
  }, [eventos.length, getSupporters, listEvents]);

  //fim

  return (
    <Container>
      <header>
        <h1>Apoiadores</h1>
        <p>
          Adicione ou exclua apoiadores que poderão ser vinculados a eventos.
        </p>
      </header>
      <div className="funcoesdefiltro">
        <div className="filtros">
          <FormControl
            variant=""
            margin="dense"
            style={{
              margin: '0rem 1rem',
              marginTop: '-0.0rem',
              width: '200px',
              textAlign: 'left',
            }}
          >
            <InputLabel id="id-selctor-zone">{'Filtrar'}</InputLabel>
            <Select
              labelId="id-selctor-zone"
              id="id-selctor-zone-select"
              value={filtro.tipo}
              onChange={(e) =>
                setFiltro({ ...filtro, tipo: e.target.value, descricao: '' })
              }
              label="Tipo"
            >
              <MenuItem value="" default>
                <em>Nenhum</em>
              </MenuItem>
              <MenuItem value={'evento'}>Evento</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            variant=""
            margin="dense"
            style={{
              margin: '0rem 1rem',
              marginTop: '-0.0rem',
              width: '200px',
              textAlign: 'left',
            }}
          >
            <InputLabel id="id-selctor-zone">
              {' '}
              {filtro.tipo === 'evento' ? 'Selecionar' : '-'}
            </InputLabel>
            <Select
              labelId="id-selctor-zone"
              id="id-selctor-zone-select"
              value={filtro.descricao}
              onChange={(e) => aplicarFiltro(e)}
              label="Estado"
              disabled={filtro.tipo === 'evento' ? false : true}
            >
              <MenuItem value="" default>
                <em>Nenhum</em>
              </MenuItem>
              {eventos.map((a, i) => (
                <MenuItem key={i} value={a.id}>
                  {format(new Date(a.start_date), 'dd/MM/yyyy')} - {a.city} -{' '}
                  {a.state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="MenuDireita">
          <button
            className="cadastroManual"
            onClick={() =>
              setDialogoApoiador({ status: true, editar: false, dados: '' })
            }
          >
            {' '}
            + Novo Apoiador
          </button>
        </div>
      </div>

      <div className="campoGeral">
        {!filtro.descricao && supporters.length > 0
          ? supporters.map((a, i) => (
              <div className="card">
                <div className="campo1">
                  <img src={a.image_url} alt="imagem" />
                </div>
                <div className="campo2">
                  <div className="infor">
                    <div className="linha">
                      <label>Nome:</label>
                      <br /> {a.name.slice(0, 44)}
                      {a.name.length > 23 ? '(...) ' : ''}
                    </div>
                    <div className="linha">
                      <label>Link:</label>{' '}
                      <a href={a.link} target="_blank" rel="noreferrer">
                        {a.link.slice(0, 23)}
                        {a.link.length > 23 ? '(...) ' : ''}
                      </a>
                    </div>
                    <div className="linha">
                      <label>Destaque na home:</label>{' '}
                      {a.inHome ? 'Sim' : 'Não'}
                    </div>
                    <div className="linha criado">
                      {' '}
                      Cadastrado em <br />
                      {format(new Date(a.created_at), 'dd/MM/yyyy')}
                    </div>
                  </div>
                  <div className="campoMenu">
                    <IconButton
                      className="botoes btnedit"
                      onClick={() =>
                        setDialogoApoiador({
                          status: true,
                          editar: true,
                          dados: a,
                          id: a.id,
                        })
                      }
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      className="botoes btndel"
                      onClick={() => setConfirmacao([true, a.id])}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            ))
          : !filtro.descricao && <h2>Nenhum apoiador encontrado !</h2>}

        {filtro.descricao && dadosfilter.length > 0
          ? dadosfilter.map((a, i) => (
              <div className="card">
                <div className="campo1">
                  <img src={a.objSupport.image_url} alt="imagem" />
                </div>
                <div className="campo2">
                  <div className="infor">
                    <div className="linha">
                      <label>Nome:</label>
                      <br /> {a.objSupport.name.slice(0, 44)}
                      {a.objSupport.name.length > 23 ? '(...) ' : ''}
                    </div>
                    <div className="linha">
                      <label>Link:</label>{' '}
                      <a
                        href={a.objSupport.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {a.objSupport.link.slice(0, 23)}
                        {a.objSupport.link.length > 23 ? '(...) ' : ''}
                      </a>
                    </div>
                    <div className="linha">
                      <label>Destaque na home:</label>{' '}
                      {a.objSupport.inHome ? 'Sim' : 'Não'}
                    </div>
                    <div className="linha criado">
                      {' '}
                      Cadastrado em <br />
                      {format(new Date(a.objSupport.created_at), 'dd/MM/yyyy')}
                    </div>
                  </div>
                  <div className="campoMenu">
                    <IconButton
                      className="botoes btnedit"
                      onClick={() =>
                        setDialogoApoiador({
                          status: true,
                          editar: true,
                          dados: a,
                          id: a.objSupport.id,
                        })
                      }
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      className="botoes btndel"
                      onClick={() => setConfirmacao([true, a.objSupport.id])}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            ))
          : filtro.descricao && <h2>Nenhum apoiador encontrado !</h2>}
      </div>

      {confirmacao[0] ? (
        <Dialogodeconfirmacao
          tamanho="xs"
          message="Tem certeza de que deseja apagar este apoiador?"
          confirmar={async () => {
            await removeSupporters(confirmacao[1]);
            await getSupporters();
            setConfirmacao([false, '']);
          }}
          negar={() => setConfirmacao([false, ''])}
        />
      ) : (
        ''
      )}
      {dialogoApoiador.status ? (
        <Novoapoiador
          user={props.user}
          editar={dialogoApoiador.editar}
          dados={dialogoApoiador.dados}
          id={dialogoApoiador.id}
          tamanho="xs"
          negar={() =>
            setDialogoApoiador({ status: false, editar: false, dados: '' })
          }
          atualizar={async () => {
            setDialogoApoiador({ status: false, editar: false, dados: '' });
            await getSupporters();
          }}
        />
      ) : (
        ''
      )}
      {backdrop && <Backdrop />}
    </Container>
  );
}

export default Configuracoes;
