import React, { useCallback, useEffect, useState } from 'react';

import { Container, Post } from './styles';

// icons
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import Backdrop from '../../../components/Backdrop';
// api
import api from '../../../services/api';

import ModalAtualizarBlog from '../../../components/ModalAtualizarBlog';
import Dialogodeconfirmacao from '../../../components/Dialogodeconfirmacao';

import { isAfter } from 'date-fns';
import { isBefore } from 'date-fns/esm';

function Blog(props) {
  const [posts, setPosts] = useState([]);
  const [post, setPost] = useState({});
  const [backdrop, setBackdrop] = useState(false);

  const [confirmacao, setConfirmacao] = React.useState([false, '']);

  // chamadas api

  const updatePosts = useCallback(
    async (e) => {
      setBackdrop(true);
      const config = {
        headers: {
          Authorization: 'Bearer ' + props.user.token,
          'Content-Type': 'application/json',
        },
      };

      await api
        .get(`/posts`, config)
        .then((response) => {
          setPosts(response.data);
          setBackdrop(false);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    [props.user.token, setBackdrop],
  );

  const deletePost = async (id) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'multipart/form-data',
      },
    };

    await api
      .delete(`/posts/${id}`, config)
      .then((a) => {})
      .catch((error) => {
        console.log(error.response);
      });
  };
  //fim

  // modal
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPost({});
  };
  // fim

  useEffect(() => {
    updatePosts();
  }, [updatePosts]);

  return (
    <Container>
      {/* {backdrod ? <Backdrop /> : ''} */}

      <header>
        <h1>Postagens do Blog</h1>
        <p>
          Nesta página é possível visualizar, excluir e adicionar os postagens
          ao blog do site.
        </p>
      </header>

      <div className="container__button">
        <button
          onClick={() => {
            setIsEditing(false);
            handleOpen();
            setPost(null);
          }}
        >
          Adicionar Postagem
        </button>
      </div>
      <br />
      <br />
      <div className="posts">
        {posts ? (
          posts.map((post, i) => (
            <Post background={post.image_url} key={i}>
              <div className="post">
                <div className="gradient">
                  <div className="postInfo">
                    <div className="postCats">
                      <span className="postCat">{post.theme}</span>
                    </div>
                    <span className="postTitle">{post.title}</span>
                    <span className="postDate">
                      {new Date(post.published_data).toLocaleDateString(
                        'pt-BR',
                        {
                          dateStyle: 'long',
                        },
                      )}
                    </span>
                    <div
                      className="postDesc"
                      dangerouslySetInnerHTML={{
                        __html: post.description.replace(/\n/g, '<br />'),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="buttons">
                {isAfter(new Date(post.published_data), new Date()) ? (
                  <div className={post.is_published ? 'agend' : 'rascu'}>
                    {post.is_published ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <QueryBuilderIcon
                          style={{ fontSize: '14px', marginRight: '0.3rem' }}
                        />
                        Agendado
                      </div>
                    ) : (
                      'Rascunho'
                    )}
                  </div>
                ) : (
                  <div className={post.is_published ? 'publi' : 'rascu'}>
                    {post.is_published ? 'Publicado' : 'Rascunho'}
                  </div>
                )}

                <div style={{ display: 'flex' }}>
                  <button
                    className="delete"
                    onClick={(e) => {
                      setConfirmacao([true, post.id]);
                    }}
                  >
                    <DeleteIcon style={{ color: '#f44336' }} />
                  </button>

                  {post.is_published &&
                  isBefore(new Date(post.published_data), new Date()) ? (
                    <button className="view">
                      <a
                        target="_blank"
                        href={`http://https://expedicao.interbrasil.com.br/.com.br/blog/${post.id}`}
                        rel="noreferrer"
                      >
                        <VisibilityIcon style={{ color: '#555' }} />
                      </a>
                    </button>
                  ) : (
                    ''
                  )}

                  <button
                    className="edit"
                    onClick={async () => {
                      await setPost(post);
                      setIsEditing(true);
                      handleOpen();
                    }}
                  >
                    <CreateIcon style={{ color: '#555' }} />
                  </button>
                </div>
              </div>
            </Post>
          ))
        ) : (
          <h1>Nenhuma postagem foi adicionada até o momento</h1>
        )}
      </div>

      {open && (
        <ModalAtualizarBlog
          isEditing={isEditing}
          open={open}
          handleClose={handleClose}
          post={post}
          user={props.user}
          updatePosts={updatePosts}
        />
      )}

      {confirmacao[0] ? (
        <Dialogodeconfirmacao
          tamanho="xs"
          message="Tem certeza de que deseja deletar esta postagem?"
          confirmar={async () => {
            await deletePost(confirmacao[1]);
            setConfirmacao([false, '']);
            await updatePosts();
          }}
          negar={() => setConfirmacao([false, ''])}
        />
      ) : (
        ''
      )}
      {backdrop && <Backdrop />}
    </Container>
  );
}

export default Blog;
