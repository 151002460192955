import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

// components
import { Backdrop, FormHelperText } from '@material-ui/core';

// api
import api from '../../services/api';

//campo de data
import 'date-fns';
import { isBefore } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';

import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';

import { Container } from './styles';

// tema
import { useTheme } from 'styled-components';
import { SET_NEW_SCHEDULE, EDIT_SCHEDULE } from '../../services/endpoints';
import SimpleBackdrop from '../Backdrop';

// context
import { EdicaoEvento } from '../../context/edicaoEvento';
import { Autocomplete } from '@material-ui/lab';

export default function MaxWidthDialog(props) {
  const { getDataAuditoriums, listAuditoriums, setListAuditoriums } =
    useContext(EdicaoEvento);
  const queryNumber = Number(window.location.pathname.split('/')[2]);
  const [auditoriumSelected, setAuditoriumSelected] = useState(
    props.editar ? props.dados.auditorium_id : undefined,
  );
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [backdrop, setBackdrop] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState(props.tamanho);

  const [speakers, setSpeakers] = React.useState([]);
  const [talks, setTalks] = React.useState([]);

  // dados form programacao
  const [typeShedule, setTypeShedule] = React.useState(
    props.editar ? (props.dados.talk_id ? 'palestra_existente' : 'geral') : '',
  );
  const [dataShedule, setDataShedule] = React.useState({
    date: props.editar
      ? new Date(props.dados.date)
      : new Date(props.event.start_date),
    title: props.editar ? props.dados.title : '',
    talk_id: props.editar ? props.dados.talk_id : null,
    talk: props.editar ? props.dados.talk : null,
    event_id: props.editar ? props.dados.event_id : props.event.id,
    is_published: true,
  });
  // fim

  const handleClose = () => {
    setOpen(false);
    props.negar();
  };

  React.useEffect(() => {
    if (speakers.length === 0) getSpeakers();
    if (talks.length === 0) getTalks();
  });

  useEffect(() => {
    if (listAuditoriums?.length === 0) getDataAuditoriums(queryNumber);
  }, [listAuditoriums?.length, queryNumber]);

  const getSpeakers = async (e) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    await api
      .get(`/speakers`, config)
      .then((response) => {
        setSpeakers(response.data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const getTalks = async (e) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    await api
      .get(`/talks`, config)
      .then((response) => {
        setTalks(response.data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  return (
    <React.Fragment>
      {backdrop ? <Backdrop /> : ''}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        scroll={'body'}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <Container>
          <DialogTitle id="max-width-dialog-title">
            {props.editar ? 'Editar Programação' : '+ Nova Programação'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div className="subtitulo">
                Preencha corretamente os campos abaixo para{' '}
                {props.editar ? 'editar' : 'adicionar'} a programação.
              </div>
              <form>
                <br />
                <FormControl
                  variant=""
                  margin="dense"
                  className="typebusca"
                  style={{ margin: 'auto', width: '90%' }}
                >
                  <InputLabel id="id-selctor-zone">
                    Selecione o tipo de cronograma
                  </InputLabel>
                  <Select
                    labelId="id-selctor-zone"
                    id="id-selctor-zone-select"
                    value={typeShedule}
                    onChange={(e) => {
                      setTypeShedule(e.target.value);
                    }}
                    label="Tipo"
                    // style={{ height:'40px',}}
                    margin="dense"
                  >
                    <MenuItem value="" default>
                      <em>Selecionar</em>
                    </MenuItem>
                    <MenuItem value={'geral'} default>
                      Geral
                    </MenuItem>
                    <MenuItem value={'palestra_existente'}>
                      Programação
                    </MenuItem>
                    {/* <MenuItem value={'nova_pcpe'}>
                      Nova Palestra Com Palestrante Existente
                    </MenuItem>
                    <MenuItem value={'nova_pcnp'}>
                      Nova Palestra Com Novo Palestrante
                    </MenuItem> */}
                  </Select>
                </FormControl>
                <br />
                <Divider />
                <div className="camposPreencher">
                  {typeShedule === 'geral' && (
                    <Geral
                      dataShedule={dataShedule}
                      setDataShedule={setDataShedule}
                      props={props}
                      listAuditoriums={listAuditoriums}
                      setListAuditoriums={setListAuditoriums}
                      setAuditoriumSelected={setAuditoriumSelected}
                      auditoriumSelected={auditoriumSelected}
                    />
                  )}
                  {typeShedule === 'palestra_existente' && (
                    <PalestraExistente
                      dataShedule={dataShedule}
                      setDataShedule={setDataShedule}
                      talks={talks}
                      props={props}
                      listAuditoriums={listAuditoriums}
                      setListAuditoriums={setListAuditoriums}
                      setAuditoriumSelected={setAuditoriumSelected}
                      auditoriumSelected={auditoriumSelected}
                    />
                  )}
                  {/* {typeShedule === 'nova_pcpe' && (
                    <Palestra
                      dataTalk={dataTalk}
                      setDataTalk={setDataTalk}
                      speakers={speakers}
                    />
                  )}
                  {typeShedule === 'nova_pcnp' && (
                    <Palestrante
                      dataSpeaker={dataSpeaker}
                      setDataSpeaker={setDataSpeaker}
                      imgSpeaker={imgSpeaker}
                      seImgSpeaker={seImgSpeaker}
                    />
                  )} */}
                </div>
              </form>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.negar()} color="" variant="contained">
              Cancelar
            </Button>
            <Button
              onClick={() =>
                props.editar
                  ? EDIT_SCHEDULE(
                      props.dados.id,
                      { ...dataShedule, auditorium_id: auditoriumSelected },
                      props.setSuccess,
                      setBackdrop,
                      props.user.token,
                    )
                  : SET_NEW_SCHEDULE(
                      { ...dataShedule, auditorium_id: auditoriumSelected },
                      props.setSuccess,
                      setBackdrop,
                      props.user.token,
                    )
              }
              style={{
                backgroundColor: theme.colors.primaryDark,
                color: '#fff',
              }}
              variant="contained"
            >
              {props.editar ? 'Editar' : 'Adicionar'}
            </Button>
          </DialogActions>
        </Container>
        {backdrop && <SimpleBackdrop />}
      </Dialog>
    </React.Fragment>
  );
}

function Geral({
  dataShedule,
  setDataShedule,
  props,

  listAuditoriums,
  setListAuditoriums,
  setAuditoriumSelected,
  auditoriumSelected,
}) {
  return (
    <>
      <br />
      <h2>Programação Geral</h2>
      <FormControl required style={{ width: '100%' }} margin="">
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
          <DateTimePicker
            label={'Horário do acontecimento'}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
            minDate={new Date(props.event.start_date)}
            maxDate={
              props.event.end_date
                ? new Date(props.event.end_date)
                : new Date(props.event.start_date)
            }
            format="dd/MM/yyyy HH:mm"
            required
            ampm={false}
            value={dataShedule.date}
            // format="hh:mm"
            onChange={(e) => setDataShedule({ ...dataShedule, date: e })}
            style={{ width: '100%' }}
            margin={'normal'}
          />
        </MuiPickersUtilsProvider>

        <TextField
          required
          id="standard-margin-name"
          label="Título do Acontecimento"
          defaultValue={dataShedule.title}
          onChange={(e) =>
            setDataShedule({ ...dataShedule, title: e.target.value })
          }
          style={{ width: '100%' }}
          // className={classes.textField}
          variant="standard"
        />
        <FormHelperText id="my-helper-text">
          Exemplos: Abertura, Credenciamento, Enceramento.
        </FormHelperText>
      </FormControl>

      <FormControl style={{ width: '100%' }} margin="">
        <InputLabel id="demo-simple-select-label">Auditório</InputLabel>
        <Select
          labelId="id-selctor-zone"
          id="id-selctor-zone-select"
          value={auditoriumSelected}
          onChange={(e, newValue) => {
            setAuditoriumSelected(e.target.value);
          }}
          label="Auditório"
          style={{ height: '40px' }}
          margin="dense"
        >
          <MenuItem
            value={undefined}
            style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'left',
              flexWrap: 'wrap',
            }}
          >
            <div>Nenhum</div>{' '}
          </MenuItem>

          {listAuditoriums.length > 0 &&
            listAuditoriums?.map((auditorium, i) => (
              <MenuItem
                key={i}
                value={auditorium.id}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left',
                  flexWrap: 'wrap',
                }}
              >
                <div>{i + 1 + ' - ' + auditorium.name}</div>{' '}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
}

function PalestraExistente({
  dataShedule,
  setDataShedule,
  talks,
  props,

  listAuditoriums,
  setListAuditoriums,
  setAuditoriumSelected,
  auditoriumSelected,
}) {
  return (
    <div>
      <h2>Programação com programa existente</h2>

      <FormControl required style={{ width: '100%' }} margin="">
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
          <DateTimePicker
            label={'Horário da Palestra/Painel'}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
            minDate={new Date(props.event.start_date)}
            maxDate={
              props.event.end_date
                ? new Date(props.event.end_date)
                : new Date(props.event.start_date)
            }
            format="dd/MM/yyyy HH:mm"
            ampm={false}
            required
            value={dataShedule.date}
            // format="hh:mm"
            onChange={(e) => setDataShedule({ ...dataShedule, date: e })}
            style={{ width: '100%' }}
            margin={'normal'}
          />
        </MuiPickersUtilsProvider>
      </FormControl>

      {/* <FormControl required style={{ width: '100%' }} margin="">
        <InputLabel id="demo-simple-select-label">Palestra/Painel</InputLabel>
        <Select
          labelId="id-selctor-zone"
          id="id-selctor-zone-select"
          value={dataShedule.talk_id}
          onChange={(e) => {
            let newTalk = talks.find((talk) => talk.id === e.target.value);
            setDataShedule({
              ...dataShedule,
              talk_id: e.target.value,
              talk: newTalk,
            });
          }}
          label="Palestra"
          style={{ height: '40px' }}
          margin="dense"
        >
          {talks?.length > 0 &&
            talks.map((talk, i) => (
              <MenuItem
                key={i}
                value={talk.id}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left',
                  flexWrap: 'wrap',
                }}
              >
                
                <div>{i + 1 + ' - ' + talk.title}</div>{' '}
                <small>{talk.objSpeaker.name}</small>
              </MenuItem>
            ))}
        </Select>
      </FormControl> */}

      <Autocomplete
        value={dataShedule.talk ? dataShedule.talk : ''}
        onChange={(event, newValue) => {
          newValue === null
            ? setDataShedule({
                ...dataShedule,
                talk_id: null,
                talk: null,
              })
            : setDataShedule({
                ...dataShedule,
                talk_id: newValue.id,
                talk: newValue,
              });
        }}
        id="controllable-states-demo"
        options={talks}
        getOptionLabel={(option) =>
          option === '' ? '' : `${option?.title} -> ${option?.objSpeaker?.name}`
        }
        getOptionSelected={(option, value) => {
          return option.id === value.id;
        }}
        // style={{ width: 300 }}
        renderInput={(params) => (
          <TextField {...params} label="Palestra/Painel" margin="normal" />
        )}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
      />

      <FormControl style={{ width: '100%' }} margin="">
        <InputLabel id="demo-simple-select-label">Auditório</InputLabel>
        <Select
          labelId="id-selctor-zone"
          id="id-selctor-zone-select"
          value={auditoriumSelected}
          onChange={(e, newValue) => {
            setAuditoriumSelected(e.target.value);
          }}
          label="Auditório"
          style={{ height: '40px' }}
          margin="dense"
        >
          <MenuItem
            value={undefined}
            style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'left',
              flexWrap: 'wrap',
            }}
          >
            <div>Nenhum</div>{' '}
          </MenuItem>

          {listAuditoriums.length > 0 &&
            listAuditoriums?.map((auditorium, i) => (
              <MenuItem
                key={i}
                value={auditorium.id}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left',
                  flexWrap: 'wrap',
                }}
              >
                {/*console.log(talk)*/}
                <div>{i + 1 + ' - ' + auditorium.name}</div>{' '}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}

// eslint-disable-next-line no-lone-blocks
{
  /* <Button
onClick={() => {
  if (typeShedule === 'geral' && dataShedule.title.length > 0) {
    props.setDadosNovos([...props.dadosNovos, dataShedule]);

    let dadosPreviaOrdenado = [
      ...props.dadosPrevia,
      dataShedule,
    ].sort(function (a, b) {
      if (isBefore(new Date(a.date), new Date(b.date))) {
        return -1;
      } else {
        return 1;
      }
    });

    // console.log(dadosPreviaOrdenado);

    props.setDadosPrevia(dadosPreviaOrdenado);
    // setOpen(false);
    props.negar();
  }
  if (
    typeShedule === 'palestra_existente' &&
    dataShedule.talk_id
  ) {
    props.setDadosNovos([...props.dadosNovos, dataShedule]);

    let dadosPreviaOrdenado = [
      ...props.dadosPrevia,
      dataShedule,
    ].sort(function (a, b) {
      if (isBefore(new Date(a.date), new Date(b.date))) {
        return -1;
      } else {
        return 1;
      }
    });

    props.setDadosPrevia(dadosPreviaOrdenado);
    // setOpen(false);
    props.negar();
  }
}}
style={{
  backgroundColor: theme.colors.primaryDark,
  color: '#fff',
}}
variant="contained"
>
{props.editar ? 'Editar' : 'Adicionar'}
</Button> */
}
