import React, { useState, useCallback } from 'react';
import { Container } from './styles';
import api from '../../services/api';

// icons
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';

// libs
import { useParams } from 'react-router-dom';

import NovoVinculoExpositor from '../NovoVinculoExpositor';

import Dialogodeconfirmacao from '../Dialogodeconfirmacao';
import imageUrl from '../../utils/imageUrl';

function DetalhesEvento({ program, user }) {
  let { id } = useParams();
  const [update, setUpdate] = useState(false);

  const [dados, setDados] = useState([]);
  const [dadosEditar, setDadosEditar] = useState([]);

  const [active, setActive] = useState(false);
  const [confirmacao, setConfirmacao] = useState([false, '']);
  const [novosexpositores, setNovosexpositores] = useState([]);

  const [dialogoProgramacao, setDialogoProgramacao] = React.useState({
    status: false,
    editar: false,
    dados: '',
  });

  const cancelarAtualizazao = () => {
    setUpdate(false);
    setDadosEditar([]);
    consultarExpositor();
  };

  const atualizarDados = (e) => {
    // e.preventDefault();
    // setBackdrop(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'Access-Control-Allow-Credentials': 'true',
      },
    };

    const data = new FormData();

    api
      .put(`/events/${id}`, data, config)
      .then((a) => {
        // console.log(' RETORNO, ', a.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const consultarExpositor = useCallback(() => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    };
    api
      .get(`/related-exhibitors-event/${id}`, config)
      .then((a) => {
        setDados(a.data);
        // console.log(' RETORNO, ', a.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, [user.token, id]);

  React.useEffect(() => {
    if (dados.length === 0) consultarExpositor();
  }, [consultarExpositor, dados.length]);

  const submeterAtualizacao = async (e) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + user.token,
        // 'Content-Type': 'multipart/form-data',
      },
    };

    novosexpositores.map((b, j) =>
      api
        .post(
          `/related-exhibitors-event/`,
          { event_id: id, exhibitor_id: b.id },
          config,
        )
        .then((a) => {
          consultarExpositor();
          // console.log(a.data);
        })
        .catch((error) => {
          console.log(error.response);
        }),
    );
    // await
  };

  const deletarCampo = (e) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    };

    api
      .delete(`/related-exhibitors-event/${e.id}`, config)
      .then((a) => {
        consultarExpositor();
        dadosEditar.splice(e, 1);
        // console.log(' RETORNO, ', a.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  return (
    <Container update={update} active={active}>
      <div className="header">
        <div
          className="left"
          onClick={() => (update ? '' : setActive(!active))}
        >
          <span className="icon">&#10148;</span>
          <h2 className="title">Expositores</h2>
        </div>

        <div className="button">
          {update ? (
            <>
              <button
                className="salvarinfo"
                onClick={() => {
                  submeterAtualizacao();
                  setUpdate(!update);
                  atualizarDados();
                }}
              >
                Salvar informações
              </button>
              <button className="cancel" onClick={() => cancelarAtualizazao()}>
                Cancelar
              </button>
            </>
          ) : (
            <button
              className="cancel"
              onClick={() => {
                setDadosEditar(dados);
                setUpdate(true);
                setActive(true);
              }}
            >
              Editar
            </button>
          )}
        </div>
      </div>
      <div className="content">
        <div className="a">
          <div className="campoDeEdicao">
            <div className="header2">
              {update && (
                <button
                  className="cadastroManual"
                  onClick={() =>
                    setDialogoProgramacao({
                      status: true,
                      editar: false,
                      dados: dadosEditar,
                    })
                  }
                >
                  {' '}
                  + Vincular
                </button>
              )}
            </div>
            <br />
            <div className="ul">
              {update ? (
                dadosEditar.length > 0 ? (
                  dadosEditar.map((a, i) => (
                    <div className="li">
                      <div className="hora" style={{ flex: 1 }}>
                        {console.log('[Dados]==> ', a)}
                        <img
                          src={
                            a.image
                              ? imageUrl(a.image)
                              : imageUrl(a.objExhibitor.image)
                          }
                          alt="image_exhibitor"
                        />
                      </div>
                      <div className="descr" style={{ flex: 9 }}>
                        <div>
                          <label>{a.name ? a.name : a.objExhibitor.name}</label>
                        </div>
                        <div>
                          {(
                            a.link
                              ? a.link
                              : a.objExhibitor
                              ? a.objExhibitor.link
                              : false
                          ) ? (
                            <a
                              href={a.link ? a.link : a.objExhibitor.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <LinkIcon />{' '}
                              {a.link ? a.link : a.objExhibitor.link}
                            </a>
                          ) : (
                            <p>Não possui link</p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <h3>Nenhum expositor vinculado !</h3>
                )
              ) : dados.length > 0 ? (
                dados.map((a, i) => (
                  <div className="li">
                    <div className="hora" style={{ flex: 1 }}>
                      <img
                        src={
                          a.image
                            ? imageUrl(a.image)
                            : imageUrl(a.objExhibitor.image)
                        }
                        alt="image_exhibitor"
                      />
                    </div>
                    <div className="descr" style={{ flex: 9 }}>
                      <div>
                        <label>{a.name ? a.name : a.objExhibitor.name}</label>
                      </div>
                      <div>
                        {(
                          a.link
                            ? a.link
                            : a.objExhibitor
                            ? a.objExhibitor.link
                            : false
                        ) ? (
                          <a
                            href={a.link ? a.link : a.objExhibitor.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <LinkIcon /> {a.link ? a.link : a.objExhibitor.link}
                          </a>
                        ) : (
                          <p>Não possui link</p>
                        )}
                      </div>
                    </div>
                    <div className="campoMenu">
                      <IconButton
                        className="botoes btndel"
                        onClick={() => setConfirmacao([true, a])}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                ))
              ) : (
                <h3>Nenhum expositor vinculado !</h3>
              )}
            </div>
          </div>

          <form onSubmit=""></form>
        </div>
      </div>

      {dialogoProgramacao.status ? (
        <NovoVinculoExpositor
          user={user}
          editar={(e) => {
            // console.log('dado que vem ', e);
            setDadosEditar(e);
          }}
          novos={(e) => {
            var aux = novosexpositores;
            aux.push(e);
            setNovosexpositores(aux);
          }}
          dados={dadosEditar}
          tamanho="xs"
          negar={() =>
            setDialogoProgramacao({ status: false, editar: false, dados: '' })
          }
        />
      ) : (
        ''
      )}
      {confirmacao[0] ? (
        <Dialogodeconfirmacao
          tamanho="xs"
          message="Tem certeza de que deseja retirar o expositor deste evento?"
          confirmar={(e) => {
            deletarCampo(confirmacao[1]);
            setConfirmacao([false, '']);
          }}
          negar={() => setConfirmacao([false, ''])}
        />
      ) : (
        ''
      )}
    </Container>
  );
}

export default DetalhesEvento;
