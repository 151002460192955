import React, { useContext, useEffect, useState } from 'react';
import { Container } from './styles';
import api from '../../services/api';

// components
import Dialogodeconfirmacao from '../Dialogodeconfirmacao';

// endpoints
import {
  GET_LIST_AUDITORIUMS,
  GET_LIST_GENERAL_SCHEDULES,
} from '../../services/endpoints';

// icons
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

// libs
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

// components
import Backdrop from '../Backdrop';
import Novaprogramacao from '../Novaprogramacao';
import { EdicaoEvento } from '../../context/edicaoEvento';

function DetalhesEvento({ program, user, event, atualizar }) {
  const {
    getDataAuditoriums,
    listAuditoriums,
    getDataGeneralSchedules,
    listSchedules,
    setListSchedules,
  } = useContext(EdicaoEvento);
  const queryNumber = Number(window.location.pathname.split('/')[2]);
  const [abaselected, setAbaselected] = useState(
    listSchedules?.length ? 0 : 'geral'
  );
  const [backdrop, setBackdrop] = React.useState(false);

  const [dados] = useState(program ? program : {});
  const [dadosPrevia, setDadosPrevia] = useState(program ? program : []);
  const [dadosNovos, setDadosNovos] = useState([]);

  const [update, setUpdate] = useState(true);
  const [active, setActive] = useState(false);
  const [confirmacao, setConfirmacao] = useState([false, '']);

  const [dialogoProgramacao, setDialogoProgramacao] = React.useState({
    status: false,
    editar: false,
    dados: '',
  });

  const cancelarAtualizazao = () => {
    setUpdate(update);
    setDadosNovos(dados);
    setDadosPrevia(dados);
  };

  const atualizarDados = () => {
    getDataAuditoriums(queryNumber, user.token);
    GET_LIST_GENERAL_SCHEDULES(
      queryNumber,
      setListSchedules,
      setBackdrop,
      user.token
    );
  };

  //
  const deleteShedule = async (id) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      },
    };

    await api
      .delete(`/shedules/${id}`, config)
      .then((response) => {
        // fechar modal
        // setOpen(false);
        // atualizar shedules
        atualizarDados();
        // tirar loading
        // setBackdrop(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  useEffect(() => {
    if (listAuditoriums?.length === 0)
      getDataAuditoriums(queryNumber, user.token);
    if (listSchedules.length === 0)
      getDataGeneralSchedules(queryNumber, setBackdrop, user.token);
  }, [listAuditoriums?.length, queryNumber, listSchedules.length]);

  return (
    <Container update={update} active={active}>
      <div className="header">
        <div
          className="left"
          onClick={() => (!update ? '' : setActive(!active))}
        >
          <span className="icon">&#10148;</span>
          <h2 className="title">Programação</h2>
        </div>
      </div>
      <div className="content">
        <div className="a">
          <div className="campoDeEdicao">
            <div className="header2">
              {update ? (
                <button
                  className="cadastroManual"
                  onClick={() =>
                    setDialogoProgramacao({
                      status: true,
                      editar: false,
                      dados: event,
                    })
                  }
                >
                  + Adicionar
                </button>
              ) : (
                <br />
              )}
            </div>
            <div className="div__group_auditoriums">
              <div className="div__group_head">
                {listAuditoriums?.length !== 0 &&
                  listAuditoriums?.map((unitAuditorium, idx) => (
                    <button
                      className={idx === abaselected && 'selected'}
                      onClick={() => setAbaselected(idx)}
                    >
                      {unitAuditorium.name}
                    </button>
                  ))}
                <button
                  className={'geral' === abaselected && 'selected'}
                  onClick={() => setAbaselected('geral')}
                >
                  Programação Geral
                </button>
              </div>

              <div className="div__body_auditoriums">
                {abaselected === 'geral' ? (
                  <div className="ul">
                    {listSchedules.length > 0 ? (
                      listSchedules.map((shedule) => (
                        <div className="li">
                          <div className="div__day">
                            <div className="dia">
                              {format(new Date(shedule.date), 'dd', {
                                locale: ptBR,
                              })}
                            </div>
                            <div className="mes">
                              {format(new Date(shedule.date), 'MMM', {
                                locale: ptBR,
                              })}
                            </div>
                          </div>
                          <div className="hora" style={{ flex: 1 }}>
                            {format(new Date(shedule.date), 'HH:mm', {
                              locale: ptBR,
                            })}
                          </div>
                          <div
                            className="descr"
                            style={{ flex: 9, display: 'flex' }}
                          >
                            {shedule.talk_id
                              ? shedule.talk.title
                              : shedule.title}
                            {shedule.talk_id ? (
                              <span className={shedule.talk.type}>
                                {shedule.talk.type}
                              </span>
                            ) : (
                              // <span>Palestra</span>
                              ''
                            )}
                          </div>
                          <div className="campoMenu">
                            <IconButton
                              className="botoes btnedit"
                              onClick={() =>
                                setDialogoProgramacao({
                                  status: true,
                                  editar: true,
                                  dados: shedule,
                                })
                              }
                            >
                              <EditIcon />
                            </IconButton>
                            {update && (
                              <IconButton
                                className="botoes btndel"
                                onClick={() =>
                                  setConfirmacao([true, shedule.id])
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <h2>Nenhuma programação adicionada.</h2>
                    )}
                  </div>
                ) : (
                  <div className="ul">
                    {listAuditoriums?.find(
                      (auditorium, idx) => idx === abaselected
                    )?.schedules.length > 0 ? (
                      listAuditoriums
                        ?.find((auditorium, idx) => idx === abaselected)
                        ?.schedules.map((shedule) => (
                          <div className="li">
                            <div className="div__day">
                              <div className="dia">
                                {format(new Date(shedule.date), 'dd', {
                                  locale: ptBR,
                                })}
                              </div>
                              <div className="mes">
                                {format(new Date(shedule.date), 'MMM', {
                                  locale: ptBR,
                                })}
                              </div>
                            </div>
                            <div className="hora" style={{ flex: 1 }}>
                              {format(new Date(shedule.date), 'HH:mm', {
                                locale: ptBR,
                              })}
                            </div>
                            <div
                              className="descr"
                              style={{ flex: 9, display: 'flex' }}
                            >
                              {shedule.talk_id
                                ? shedule.talk.title
                                : shedule.title}
                              {shedule.talk_id ? (
                                <span className={shedule.talk.type}>
                                  {shedule.talk.type}
                                </span>
                              ) : (
                                // <span>Palestra</span>
                                ''
                              )}
                            </div>
                            <div className="campoMenu">
                              <IconButton
                                className="botoes btnedit"
                                onClick={() =>
                                  setDialogoProgramacao({
                                    status: true,
                                    editar: true,
                                    dados: shedule,
                                  })
                                }
                              >
                                <EditIcon />
                              </IconButton>
                              {update && (
                                <IconButton
                                  className="botoes btndel"
                                  onClick={() =>
                                    setConfirmacao([true, shedule.id])
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </div>
                          </div>
                        ))
                    ) : (
                      <h3>Nenhum auditório cadastrado</h3>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <form onSubmit=""></form>
        </div>
      </div>

      {dialogoProgramacao.status ? (
        <Novaprogramacao
          user={user}
          event={event}
          editar={dialogoProgramacao.editar}
          dados={dialogoProgramacao.dados}
          atualizar={atualizar}
          tamanho="xs"
          setDadosNovos={setDadosNovos}
          dadosNovos={dadosNovos}
          dadosPrevia={dadosPrevia}
          setDadosPrevia={setDadosPrevia}
          setSuccess={() => {
            getDataAuditoriums(queryNumber, user.token);
            getDataGeneralSchedules(queryNumber, setBackdrop, user.token);
            setDialogoProgramacao({ status: false, editar: false, dados: '' });
          }}
          negar={() =>
            setDialogoProgramacao({ status: false, editar: false, dados: '' })
          }
        />
      ) : (
        ''
      )}
      {confirmacao[0] ? (
        <Dialogodeconfirmacao
          tamanho="xs"
          message="Tem certeza de que deseja deletar a programação deste evento?"
          confirmar={() => {
            deleteShedule(confirmacao[1]);
            setConfirmacao([false, '']);
          }}
          negar={() => setConfirmacao([false, ''])}
        />
      ) : (
        ''
      )}
      {backdrop && <Backdrop />}
    </Container>
  );
}

export default DetalhesEvento;
