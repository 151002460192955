const To = (props) => {
  return (
    <svg
      width={200}
      height={200}
      viewBox="0 0 52.917 52.917"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        style={{
          fill: 'red',
          fillOpacity: 1,
          strokeWidth: 0.370146,
        }}
        d="M25.943.657c-.431.006-.346.361.18 1.073.718.971.752 1.24.375 3.055-.227 1.096-.731 2.477-1.12 3.07-.679 1.035-3.339 3.356-4.16 3.63-.569.19-1.014 3.638-.606 4.71.81 2.132-.516 5.535-3.4 8.72-1.006 1.11-1.9 2.396-1.985 2.857-.652 3.515-1.017 4.838-1.92 6.946-1.148 2.68-1.959 8.764-1.496 11.223.244 1.292.35 1.396 1.262 1.213.88-.176 1.085-.017 1.751 1.363.508 1.053 1.202 1.785 2.127 2.241l1.373.677 1.14-1.14c1.309-1.31 3.346-1.553 4.472-.533.37.334 1.63 1.076 2.805 1.648 1.173.573 2.384.945 2.69.829.305-.116 1.72-.44 3.145-.72 1.425-.281 3.417-.825 4.425-1.209l1.834-.699.082-3.18c.045-1.75.196-3.364.335-3.59.14-.224-.099-.654-.528-.955-1.666-1.167-.62-5.016 1.935-7.12.847-.697.774-1.038-.28-1.302-1.513-.38-3.133-2.032-3.607-3.677-.245-.85-.936-2.247-1.538-3.103-1.508-2.148-1.273-4.187.673-5.824 1.525-1.282 1.496-2.428-.063-2.428-.5 0-1.274-.193-1.718-.43-1.079-.574-4.325-4.048-4.325-4.628 0-.252.25-1.417.555-2.59.642-2.466.712-5.283.178-7.207-.314-1.127-.668-1.471-2.196-2.128C27.104.918 26.322.65 25.942.657Zm2.207 29.539c.884 0 1.579.259 2.085.774.506.514.758 1.214.758 2.1 0 .879-.252 1.577-.755 2.095-.504.516-1.2.773-2.088.773-.886 0-1.581-.257-2.085-.773-.503-.518-.756-1.216-.756-2.095 0-.886.253-1.586.756-2.1.504-.515 1.199-.774 2.085-.774zm-7.4.115h4.866v1.066h-1.722v4.447h-1.422v-4.447H20.75Zm7.403.925c-.18 0-.35.032-.51.096a1.199 1.199 0 0 0-.437.32 1.584 1.584 0 0 0-.305.573 2.811 2.811 0 0 0-.114.845c0 .328.037.608.111.84.077.23.176.418.3.563.123.146.267.252.433.321.165.07.34.104.522.104s.357-.035.522-.104c.165-.071.31-.181.433-.33.139-.167.242-.365.308-.592.067-.23.1-.498.1-.807 0-.33-.04-.612-.116-.844a1.631 1.631 0 0 0-.299-.562 1.123 1.123 0 0 0-.438-.322 1.336 1.336 0 0 0-.51-.101z"
      />
    </svg>
  );
};

export default To;
