import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Tooltip from '@material-ui/core/Tooltip';
// import PersonIcon from '@material-ui/icons/Person';
import AccountCircle from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';

import { Container } from './styles';

// editor de texto
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import api from '../../services/api';

// tema
import { useTheme } from 'styled-components';

export default function MaxWidthDialog(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState(props.tamanho);
  const [messageError, setMessageError] = React.useState('');

  const [debatedores, setDebatedores] = React.useState(
    props.editar
      ? props.dados.debaters.map((obj, idx) => obj.debater.id)
      : [null]
  );

  const [dados, setDados] = React.useState({
    type: props.editar ? props.dados.type : 'Palestra',
    id: props.editar ? props.dados.id : '',
    speaker_id: props.editar ? props.dados.speaker_id : '',
    title: props.editar ? props.dados.title : '',
    description: props.editar ? props.dados.description : '',
    debaters: props.editar ? props.dados.debaters : [null],
  });
  const [textdescription, setTextdescription] = React.useState({
    description: EditorState.createEmpty(),
  });

  // console.log("TUDO: ", props.dados);
  const onEditorStateChange = (editorState) => {
    setTextdescription({
      ...textdescription,
      description: editorState,
    });
    setDados({
      ...dados,
      description: draftToHtml(
        convertToRaw(textdescription.description.getCurrentContent())
      ),
    });
  };

  const [speakers, setSpeakers] = React.useState([]);

  const handleClose = () => {
    setOpen(false);
    props.negar();
  };

  const enviarForm = async (type) => {
    type.preventDefault();

    let talkRequest = {
      type: dados.type,
      id: dados.id,
      speaker_id: dados.speaker_id,
      title: dados.title,
      description: dados.description,
      debaters: debatedores,
    };

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    const data = new FormData();

    data.append('type', talkRequest.type);
    data.append('speaker_id', 1);
    data.append('title', talkRequest.title);
    data.append('description', talkRequest.description);
    data.append('debaters', talkRequest.debaters);

    // console.log(talkRequest);

    // aqui tem que retornar

    props.editar
      ? await api
          .put(`/talks/${props.dados.id}`, talkRequest, config)
          .then((a) => {
            // console.log(a.data);
            setMessageError('');
            props.atualizar();
            //setLoad(false);
          })
          .catch((error) => {
            console.log(error.response);

            const message = error.response.data.messages
              ? error.response.data.messages[0].message
              : error.response.data.message;
            setMessageError(message);
          })
      : await api
          .post('/talks', talkRequest, config)
          .then((a) => {
            // console.log(a.data);
            setMessageError('');
            props.atualizar();
          })
          .catch((error) => {
            console.log(error.response);
            const message = error.response.data.messages
              ? error.response.data.messages[0].message
              : error.response.data.message;
            setMessageError(message);
          });
  };

  const getSpeaker = useCallback(async () => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    await api
      .get(`/speakers`, config)
      .then((response) => {
        setSpeakers(response.data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, [props.user.token]);

  React.useEffect(() => {
    getSpeaker();
  }, [getSpeaker]);

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        scroll={'body'}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <Container>
          <form onSubmit={enviarForm}>
            <DialogTitle id="max-width-dialog-title">
              {props.editar ? 'Editar Programação' : '+ Nova Programação'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div className="subtitulo">
                  Preencha corretamente os campos abaixo para{' '}
                  {props.editar ? 'editar' : 'adicionar'} uma programação.
                </div>
                <div className="formulario">
                  <p style={{ fontSize: '16px' }}>{props.message}</p>

                  <FormControl variant="" margin="dense" className="campo">
                    <InputLabel id="demo-simple-select-label">
                      Tipo de programação
                    </InputLabel>
                    <Select
                      labelId="id-selctor-zone"
                      id="id-selctor-zone-select"
                      value={dados.type}
                      onChange={(e) => {
                        setDados({ ...dados, type: e.target.value });
                      }}
                      label="Tipo de programação"
                      style={{ height: '40px' }}
                      margin="dense"
                    >
                      <MenuItem value="Palestra">Palestra</MenuItem>
                      <MenuItem value="Painel">Painel</MenuItem>
                    </Select>
                  </FormControl>

                  {props.editar && !speakers.length > 0 ? (
                    <span
                      style={{
                        padding: '1rem',
                        border: '1px dashed rgba(0,0,0,0.4)',
                        margin: '1rem 1rem',
                      }}
                    >
                      Carregando
                    </span>
                  ) : (
                    <Autocomplete
                      id="combo-box-demo"
                      options={speakers}
                      value={
                        speakers?.filter((a) => a.id === dados.speaker_id)[0]
                      }
                      defaultValue={
                        speakers.filter((a) => a.id === dados.speaker_id)[0]
                      }
                      getOptionLabel={(option) => option.name}
                      noOptionsText="Nenuma pessoa encontrada"
                      style={{ width: '100%', padding: '0.5rem 1rem' }}
                      onChange={(event, newValue) => {
                        setDados({
                          ...dados,
                          speaker_id: newValue ? newValue.id : null,
                        });
                      }}
                      required
                      renderInput={(params) => (
                        <Grid
                          alignItems="flex-end"
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flex: 1,
                          }}
                        >
                          <Grid item style={{ width: '100%' }}>
                            <TextField
                              {...params}
                              label={`${
                                dados.type === 'Palestra'
                                  ? 'Palestrante'
                                  : dados.type === 'Painel'
                                  ? 'Moderador'
                                  : 'Erro'
                              }`}
                              id="input-with-icon-grid"
                            />
                          </Grid>
                          <Grid item style={{ paddingLeft: '1rem' }}>
                            <AccountCircle
                              style={{
                                fontSize: '2rem',
                                marginBottom: '-0.4rem',
                                marginRight: '-0.5rem',
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    />
                  )}

                  {props.editar && !speakers.length > 0 ? (
                    <span
                      style={{
                        padding: '1rem',
                        border: '1px dashed rgba(0,0,0,0.4)',
                        margin: '1rem 1rem',
                      }}
                    >
                      Carregando
                    </span>
                  ) : (
                    dados.type === 'Painel' &&
                    debatedores.map((debatedor, idx) => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <Autocomplete
                          key={idx}
                          id="combo-box-demo"
                          options={speakers}
                          value={
                            debatedor
                              ? speakers?.filter((a) => a.id === debatedor)[0]
                              : null
                          }
                          defaultValue={
                            props.editar &&
                            debatedor &&
                            speakers?.filter((a) => a.id === debatedor)[0]
                          }
                          getOptionLabel={(option) => option.name}
                          noOptionsText="Nenhuma pessoa encontrada"
                          style={{ width: '100%', padding: '0.5rem 1rem' }}
                          onChange={(event, newValue) => {
                            let aux = debatedores;
                            aux[idx] = newValue ? newValue.id : null;
                            setDebatedores([...aux]);
                          }}
                          required
                          renderInput={(params) => (
                            <TextField
                              value={
                                speakers?.filter((a) => a.id === debatedor)[0]
                                  ?.name
                              }
                              {...params}
                              label={`${idx + 1}º Debatedor`}
                            />
                          )}
                        />
                        <Tooltip
                          title="Excluir este debatedor"
                          placement="bottom-start"
                        >
                          <IconButton
                            type="button"
                            style={{
                              margin: 'auto',
                              padding: '.5rem',
                              filter: idx === 0 ? 'grayscale(1)' : '',
                              opacity: idx === 0 ? '0.5' : '1',
                            }}
                            disabled={idx === 0 ? true : false}
                            onClick={() => {
                              // setDebatedores([...debatedores, ""])
                              var aux = debatedores;
                              aux.splice(idx, 1);
                              setDebatedores([...aux]);
                            }}
                          >
                            <HighlightOffIcon
                              style={{
                                color: '#e74c3c',
                                fontSize: '2rem',
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ))
                  )}

                  {}
                  {dados.type === 'Painel' && (
                    <div
                      style={{
                        textAlign: 'right',
                      }}
                    >
                      <Tooltip
                        title="Adicionar novo debatedor"
                        placement="bottom-start"
                      >
                        <IconButton
                          type="button"
                          style={{
                            margin: 'auto',
                            padding: '.5rem',
                            filter:
                              debatedores.length === 10 ? 'grayscale(1)' : '',
                            opacity: debatedores.length === 10 ? '0.5' : '1',
                          }}
                          disabled={debatedores.length === 10 ? true : false}
                          onClick={() => {
                            setDebatedores([...debatedores, null]);
                          }}
                        >
                          <AddCircleOutlineIcon
                            style={{
                              color: 'rgb(37,211,102)',
                              fontSize: '2rem',
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}

                  <Divider
                    style={{
                      marginTop: '2rem',
                    }}
                  />

                  <TextField
                    required
                    id="standard-basic"
                    className="campo"
                    label="Título"
                    onChange={(e) =>
                      setDados({ ...dados, title: e.target.value })
                    }
                    value={dados.title}
                  />
                  <div style={{ marginTop: '1rem', marginLeft: '1rem' }}>
                    Descrição
                  </div>
                  <div
                    style={{
                      border: '1px solid rgba(0,0,0,0.2)',
                      margin: '2rem 0rem',
                      padding: '5px',
                      borderRadius: '5px',
                    }}
                  >
                    <Editor
                      defaultEditorState={
                        props.editar
                          ? EditorState.createWithContent(
                              ContentState.createFromBlockArray(
                                convertFromHTML(dados.description)
                              )
                            )
                          : textdescription.description
                      }
                      // editorState={textdescription.description}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      onEditorStateChange={onEditorStateChange}
                      style={{ minHeight: '500px' }}
                    />
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>

            {messageError && (
              <Alert
                style={{ margin: '1rem 2rem' }}
                variant="outlined"
                severity="error"
              >
                {messageError.replace('!', '')}. Corrija e tente novamente.
              </Alert>
            )}

            <DialogActions>
              <Button
                onClick={() => props.negar()}
                color=""
                variant="contained"
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                style={{
                  backgroundColor: theme.colors.primaryDark,
                  color: '#fff',
                }}
                variant="contained"
              >
                {props.editar ? 'Editar' : 'Adicionar'}
              </Button>
            </DialogActions>
          </form>
        </Container>
      </Dialog>
    </React.Fragment>
  );
}
