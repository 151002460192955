import styled from 'styled-components';

export const Container = styled.div`
  .subtitulo {
    font-size: 16px;
  }

  .formulario {
    display: flex !important;
    flex-direction: column !important;

    .campo,
    .apresentarHome {
      margin: 0.5rem 1rem;
    }

    .imagematual {
      text-align: center;
      h3 {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 500;
        margin: 1rem 0rem;
        text-align: left;
      }
      img {
        width: 75%;
        /* height: 100px; */
        margin: auto;
      }
    }
  }
`;
