import styled from 'styled-components';

export const Container = styled.div`
  .subtitulo {
    font-size: 16px;
  }

  .formulario {
    display: flex !important;
    flex-direction: column !important;

    details {
      summary {
        background: ${(props) => props.theme.colors.primaryDark};
        color: white;
        padding: 0.25rem 1rem;
        cursor: pointer;
        margin-top: 1rem;
        font-size: 1rem;
      }
      .div__autocomplete {
        padding: 1rem 0rem;
        border-bottom: 1px dotted rgba(0, 0, 0, 0.4);
        border-right: 1px dotted rgba(0, 0, 0, 0.4);
        border-left: 1px dotted rgba(0, 0, 0, 0.4);
        padding: 1rem;
        border-radius: 0rem 0rem 0.5rem 0.5rem;
      }
    }

    .campo,
    .apresentarHome {
      margin: 0.5rem 1rem;
    }

    .imagematual {
      text-align: center;
      h3 {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 500;
        margin: 1rem 0rem;
        text-align: left;
      }
      img {
        width: 75%;
        /* height: 100px; */
        margin: auto;
      }
    }
  }
`;
