import React, { useContext, useState, useCallback } from 'react';

import { EdicaoEvento } from '../../context/edicaoEvento';

import { Container } from './styles';
import api from '../../services/api';

// icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

// libs
import { useParams } from 'react-router-dom';

import ModalEnviarPesquisa from '../../components/ModalEnviarPesquisa';

function DetalhesEnviarPesquisa(props) {
  let { id } = useParams();

  const { dados: evento } = useContext(EdicaoEvento);

  const [update] = useState(false);

  const [dados, setDados] = useState([]);

  const [active, setActive] = useState(false);

  // modal
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    //console.log('Liberado ', released);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    listSurveys();
  };
  // fim

  const listSurveys = useCallback(() => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
      },
    };

    api
      .get(`/surveys/${id}`, config)
      .then((response) => {
        //console.log(response);
        let surveys = response.data;
        setDados(surveys);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.user.token, id]);

  const isReleased = () => {
    if (evento.event.start_date) {
      let today = new Date().getTime();
      let eventDate = new Date(evento.event.start_date).getTime();
      return today >= eventDate ? true : false;
    } else {
      return false;
    }
  };
  const [released] = useState(isReleased());

  React.useEffect(() => {
    if (dados.length === 0) listSurveys();
  }, [dados.length, listSurveys]);

  const TextToHTML = (html) => {
    let a = html;
    a = a.replace(/\n/g, '<br/>');

    a = a.replace(/ \*/g, ' <strong>');
    a = a.replace(/\* /g, '</strong> ');

    a = a.replace(/ _/g, ' <em>');
    a = a.replace(/_ /g, '</em> ');

    a = a.replace(/ ~/g, ' <del>');
    a = a.replace(/~ /g, '</del> ');

    //console.log('<p>' + a + '</p>');
    return '<p>' + a + '</p>';
  };

  return (
    <Container update={update} active={active}>
      <div className="header">
        <div
          className="left"
          onClick={() => (update ? '' : setActive(!active))}
        >
          <span className="icon">&#10148;</span>
          <h2 className="title">Pesquisa de Satisfação</h2>
        </div>
      </div>
      <div className="content">
        {dados.message ? (
          <div className="message">
            <br />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <h2>A pesquisa de satisfação já foi enviada. </h2>
              <CheckCircleIcon style={{ fontSize: 30, color: 'green' }} />
            </div>
            <br />
            <h3>Mensagem enviada:</h3>
            <br />
            <div
              className="content__description"
              dangerouslySetInnerHTML={{
                __html:
                  '<p><p>Olá *Nome do Participante*, gostaríamos de agradecer pela sua presença no evento de *Local do Evento* da ' +
                  process.env.REACT_APP_NAME_SITE +
                  ' no dia *Data do Evento*.</p><br/>' +
                  '<p>' +
                  //dados.message.replace(/\n/g, '<br />') +
                  TextToHTML(dados.message) +
                  '</p>',
              }}
            ></div>
            <br />
          </div>
        ) : (
          <div className="message">
            <br />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <h2>A Pesquisa de Satisfação ainda não foi enviada. </h2>
              <ErrorIcon style={{ fontSize: 30, color: '#777' }} />
            </div>
            <br />
            {released ? (
              ''
            ) : (
              <h3>A pesquisa será liberada no dia do evento. </h3>
            )}
            <br />
          </div>
        )}

        {!dados.message && released ? (
          <div className="button">
            <button
              className="enviar-pesquisa"
              onClick={() => {
                handleOpen();
              }}
            >
              Criar pesquisa
            </button>
          </div>
        ) : (
          <div className="button">
            <button
              className="enviar-pesquisa"
              style={{ background: '#d1d1d1' }}
              disabled
            >
              Criar pesquisa
            </button>
          </div>
        )}
      </div>

      {open && (
        <ModalEnviarPesquisa
          open={open}
          handleClose={handleClose}
          eventId={id}
          user={props.user}
        />
      )}
    </Container>
  );
}

export default DetalhesEnviarPesquisa;
