import React, { useState } from 'react';
import { Container } from './styles';

import api from '../../../services/api';

import { isAfter, isBefore } from 'date-fns';

// dropdown
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import Modalcriarevento from '../../../components/Modalcriarevento';
import Dialogodeconfirmacao from '../../../components/Dialogodeconfirmacao';

import { useEvents } from '../../../context/eventsContext';
import { uf } from '../../../utils/defaultData';
import CardEventoInter from '../../../components/CardEventoInter';

function Eventos(props) {
  const [filtro, setFiltro] = React.useState({
    tipo: '',
    descricao: '',
  });

  const { events, getData } = useEvents();

  const [confirmDelete, setConfirmDelete] = useState([false, '']);
  const deleteEvent = (id) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
      },
    };

    api
      .delete(`events/${id}`, config)
      .then((a) => {
        setConfirmDelete([false, '']);
        getData();
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  // função do filtro
  const filtrodig = (e) => {
    setFiltro({ ...filtro, descricao: e.target.value });
  };

  const filtroAplicado = (e) => {
    if (
      filtro.tipo === 'eventos_estado' || filtro.tipo === 'eventos_cidade'
        ? filtro.tipo && filtro.descricao
        : filtro.tipo
    ) {
      if (filtro.tipo === 'eventos_passados') {
        if (isBefore(new Date(e.start_date), new Date())) return e;
      }
      if (filtro.tipo === 'eventos_futuros') {
        if (isAfter(new Date(e.start_date), new Date())) return e;
      }
      if (filtro.tipo === 'eventos_sem_data') {
        if (!e.start_date) return e;
      }
      if (filtro.tipo === 'eventos_publicados') {
        if (e.is_published) return e;
      }
      if (filtro.tipo === 'eventos_em_rascunho') {
        if (!e.is_published) return e;
      }
      if (filtro.tipo === 'eventos_estado') {
        if (e.state === filtro.descricao) return e;
      }
      if (filtro.tipo === 'eventos_cidade') {
        if (e.city.toLowerCase().indexOf(filtro.descricao.toLowerCase()) > -1)
          return e;
      }
    } else {
      return true;
    }
  };

  var events2 = events.filter(filtroAplicado).sort((a, b) => {
    return b.id - a.id;
  });

  return (
    <Container>
      <header>
        <h1>Eventos {process.env.REACT_APP_NAME_SITE}</h1>
        <p>Lista de eventos publicados e em rascunho.</p>
      </header>

      <div className="containerFilter">
        <div className="filter">
          <div className="campodefiltro">
            <FormControl
              variant=""
              margin="dense"
              style={{
                margin: '0rem 1rem',
                marginTop: '-0.0rem',
                width: '200px',
                textAlign: 'left',
              }}
            >
              <InputLabel
                id="id-selctor-zone"
                style={{ fontWeight: 'normal', fontSize: '14px' }}
              >
                {'Filtrar'}
              </InputLabel>
              <Select
                labelId="id-selctor-zone"
                id="id-selctor-zone-select"
                value={filtro.tipo}
                onChange={(e) =>
                  setFiltro({ ...filtro, tipo: e.target.value, descricao: '' })
                }
                label="Tipo"
              >
                <MenuItem value="" default>
                  <em>Nenhum</em>
                </MenuItem>
                <MenuItem value={'eventos_passados'}>Eventos Passados</MenuItem>
                <MenuItem value={'eventos_futuros'}>Eventos Futuros</MenuItem>
                <MenuItem value={'eventos_sem_data'}>Eventos sem data</MenuItem>
                <MenuItem value={'eventos_publicados'}>Publicados</MenuItem>
                <MenuItem value={'eventos_em_rascunho'}>Em rascunho</MenuItem>
                <MenuItem value={'eventos_cidade'}>Cidade</MenuItem>
                <MenuItem value={'eventos_estado'}>Estado</MenuItem>
              </Select>
            </FormControl>

            {filtro.tipo === 'eventos_estado' ? (
              <FormControl
                variant=""
                margin="dense"
                style={{
                  margin: '0rem 1rem',
                  marginTop: '-0.0rem',
                  width: '200px',
                  textAlign: 'left',
                  fontWeight: 'normal',
                }}
              >
                <InputLabel id="id-selctor-zone"> </InputLabel>
                <Select
                  labelId="id-selctor-zone"
                  id="id-selctor-zone-select"
                  value={filtro.descricao}
                  onChange={filtrodig}
                  label="Estado"
                  style={{ fontWeight: 'normal' }}
                >
                  <MenuItem value="" default>
                    <em>Nenhum</em>
                  </MenuItem>
                  {uf.map((a, i) => (
                    <MenuItem key={i} value={a}>
                      {a}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <TextField
                onChange={filtrodig}
                value={filtro.descricao}
                id="outlined-basic"
                label={
                  filtro.tipo === '' ||
                  filtro.tipo === 'eventos_passados' ||
                  filtro.tipo === 'eventos_futuros' ||
                  filtro.tipo === 'eventos_sem_data' ||
                  filtro.tipo === 'eventos_publicados' ||
                  filtro.tipo === 'eventos_em_rascunho' ? (
                    <div style={{ fontWeight: 'normal' }}> - </div>
                  ) : (
                    <div style={{ fontWeight: 'normal', fontSize: '14px' }}>
                      {' '}
                      Nome da Cidade
                    </div>
                  )
                }
                disabled={
                  filtro.tipo === '' ||
                  filtro.tipo === 'eventos_passados' ||
                  filtro.tipo === 'eventos_futuros' ||
                  filtro.tipo === 'eventos_sem_data' ||
                  filtro.tipo === 'eventos_publicados' ||
                  filtro.tipo === 'eventos_em_rascunho'
                    ? true
                    : false
                }
                margin="dense"
                style={{
                  margin: '0rem 0rem',
                  marginLeft: '0rem',
                  width: '250px',
                  fontWeight: 'normal',
                }}
              />
            )}
          </div>

          <Modalcriarevento user={props.user} />
        </div>
      </div>

      <section className="card-area">
        {events2 &&
          events2.map((event, i) => (
            // <CardEvento event={event} setConfirmDelete={setConfirmDelete} />
            <CardEventoInter
              event={event}
              setConfirmDelete={setConfirmDelete}
              idx={i}
            />
          ))}
      </section>

      {confirmDelete[0] ? (
        <Dialogodeconfirmacao
          tamanho="xs"
          message="Tem certeza de que deseja apagar este evento?"
          confirmar={() => deleteEvent(confirmDelete[1])}
          negar={() => setConfirmDelete([false, ''])}
        />
      ) : (
        ''
      )}
    </Container>
  );
}

export default Eventos;
