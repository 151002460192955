import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import api from '../../services/api';

import { Container } from './styles';
import { DropzoneArea } from 'material-ui-dropzone';
import Alert from '@material-ui/lab/Alert';

// tema
import { useTheme } from 'styled-components';

import 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

// editor de texto
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { acceptedFilesTypes } from '../../utils/acceptedFilesTypes';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function ModalAtualizarBlog({
  open,
  handleClose,
  isEditing,
  post,
  user,
  updatePosts,
}) {
  const classes = useStyles();
  const [messageError, setMessageError] = React.useState('');
  const theme = useTheme();

  const [dados, setDados] = React.useState({
    title: post ? post.title : '',
    author: post ? post.author : '',
    description: post ? post.description : '',
    theme: post ? post.theme : '',
    published_data: post ? post.published_data : new Date().toISOString(),
    is_published: post ? post.is_published : false,
  });
  const [files, setFiles] = React.useState([]);
  const [textdescription, setTextdescription] = React.useState({
    description: EditorState.createEmpty(),
  });

  const onEditorStateChange = (editorState) => {
    setTextdescription({
      ...textdescription,
      description: editorState,
    });
    setDados({
      ...dados,
      description: draftToHtml(
        convertToRaw(textdescription.description.getCurrentContent())
      ),
    });
  };

  useEffect(() => {
    // if (post && post !== null) setDados(post);
    // console.log("Info importante: ", post);
  }, []);

  // chamadas api
  const enviarForm = async (type) => {
    let postRequest = {
      title: dados.title,
      author: dados.author,
      description: dados.description,
      theme: dados.theme,
      is_published: type === 'salvar' ? true : false,
      published_data: dados.published_data,
    };
    // console.log('dados enviados: ', postRequest);

    const config = {
      headers: {
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'multipart/form-data',
      },
    };

    const data = new FormData();

    data.append('title', postRequest.title);
    data.append('author', postRequest.author);
    data.append('description', postRequest.description);
    data.append('theme', postRequest.theme);
    data.append('is_published', postRequest.is_published);
    data.append('published_data', postRequest.published_data);
    data.append('image', files[0] ? files[0] : null);

    // if (files.length > 0) {
    isEditing
      ? await api
          .put(`/posts/${post.id}`, data, config)
          .then((a) => {
            // console.log(a.data);

            handleClose();
            setMessageError('');

            // props.fecharDialogo();
            //setLoad(false);
          })
          .catch((error) => {
            const message = error.response.data.messages
              ? error.response.data.messages[0].message
              : error.response.data.message;
            setMessageError(message);
          })
      : await api
          .post('/posts', data, config)
          .then((a) => {
            handleClose();
            setMessageError('');
          })
          .catch((error) => {
            console.log(error);
            const message = error.response.data.messages
              ? error.response.data.messages[0].message
              : error.response.data.message;
            setMessageError(message);
          });
  };

  //fim

  const handleChange = (e) => {
    setFiles(e);
  };

  return (
    <Dialog
      open={open}
      scroll={'body'}
      aria-labelledby="max-width-dialog-title"
    >
      <Container>
        <form>
          <DialogTitle id="max-width-dialog-title">
            {isEditing ? 'Editar Evento' : 'Nova Postagem'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div className="subtitulo">
                Preencha corretamente os campos abaixo para{' '}
                {isEditing ? 'editar' : 'adicionar'} uma postagem.
              </div>
              <div className="formulario">
                <TextField
                  required
                  id="standard-full-width"
                  label="Titulo"
                  value={dados.title}
                  onChange={(e) =>
                    setDados({ ...dados, title: e.target.value })
                  }
                  fullWidth
                  margin="normal"
                  variant="standard"
                />

                <div className="local-data">
                  <TextField
                    required
                    label="Autor"
                    id="standard-full-width"
                    defaultValue={dados.author}
                    className={classes.textField}
                    style={{ width: '50%', marginRight: '1rem' }}
                    variant="standard"
                    onChange={(e) =>
                      setDados({ ...dados, author: e.target.value })
                    }
                  />

                  <TextField
                    required
                    label="Tema"
                    id="standard-full-width"
                    defaultValue={dados.theme}
                    className={classes.textField}
                    style={{ width: '50%', marginRight: '1rem' }}
                    variant="standard"
                    onChange={(e) =>
                      setDados({ ...dados, theme: e.target.value })
                    }
                  />
                </div>

                <div>
                  <p>Data da Postagem:</p>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                    <DateTimePicker
                      required
                      value={dados.published_data}
                      format="dd/MM/yyyy HH:mm"
                      ampm={false}
                      onChange={(e) =>
                        setDados({
                          ...dados,
                          published_data: e.toISOString(),
                        })
                      }
                      style={{ width: '100%' }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <br />

                {/* <p>Descrição</p>
                <Editor
                  apiKey="0plnsczuxljhgcrr0scu99u4eepex4j6sqi02uav93i8514p"
                  initialValue={dados.description}
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image',
                      'charmap print preview anchor help',
                      'searchreplace visualblocks code',
                      'insertdatetime media table paste wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic |  alignleft aligncenter alignright |  bullist numlist outdent indent | help',
                  }}
                  name="description"
                  onChange={editorState}
                /> */}

                <div style={{ marginTop: '1rem', marginLeft: '0rem' }}>
                  Descrição
                </div>
                <div
                  style={{
                    border: '1px solid rgba(0,0,0,0.2)',
                    margin: '2rem 0rem',
                    padding: '5px',
                    borderRadius: '5px',
                  }}
                >
                  <Editor
                    defaultEditorState={
                      post && post.description
                        ? EditorState.createWithContent(
                            ContentState.createFromBlockArray(
                              convertFromHTML(dados.description)
                            )
                          )
                        : textdescription.description
                    }
                    // editorState={textdescription.description}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={onEditorStateChange}
                    style={{ minHeight: '500px' }}
                    placeholder="Escreva aqui seu texto..."
                  />
                </div>

                <br />
                <DropzoneArea
                  onChange={handleChange}
                  acceptedFiles={acceptedFilesTypes}
                  filesLimit={'1'}
                  dropzoneText="Arraste a imagem para este campo ou clique aqui para buscar"
                  maxFileSize="6000000"
                  getFileAddedMessage={(fileName) =>
                    `Arquivo ${fileName} adicionado com sucesso.`
                  }
                  getFileRemovedMessage={(fileName) =>
                    `Arquivo ${fileName} removido.`
                  }
                  getFileLimitExceedMessage={(filesLimit) =>
                    `Número máximo de ${filesLimit} arquivos excedido.`
                  }
                  getDropRejectMessage={() => `Tipo de arquivo não permitido.`}
                />

                {isEditing ? (
                  <div className="imagematual">
                    <h3>Imagem atual</h3>
                    <img
                      src={post.image_url}
                      style={{ width: '100%' }}
                      alt="image_atual  "
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </DialogContentText>
          </DialogContent>
          {messageError && (
            <Alert
              style={{ margin: '1rem 2rem' }}
              variant="outlined"
              severity="error"
            >
              {messageError.replace('!', '')}. Corrija e tente novamente.
            </Alert>
          )}
          <DialogActions>
            <Button onClick={() => handleClose()} color="" variant="contained">
              Cancelar
            </Button>

            <Button
              color=""
              variant="contained"
              onClick={async () => {
                handleClose();
                await enviarForm('rascunho');
                await updatePosts();
              }}
            >
              Em rascunho
            </Button>

            <Button
              onClick={async () => {
                await enviarForm('salvar');
                await updatePosts();
              }}
              style={{
                backgroundColor: theme.colors.primaryDark,
                color: '#fff',
              }}
              variant="contained"
            >
              {isEditing ? 'Publicar' : 'Adicionar'}
            </Button>
          </DialogActions>
        </form>
      </Container>
    </Dialog>
  );
}
