import styled from "styled-components";

import credencialfundo from "../../../assets/credenciais/credencial-inter.jpg";
import credencialfundo2 from "../../../assets/credenciais/credencial-inter.jpg";
import credencialfundoSolar from "../../../assets/credenciais/credencial-inter.jpg";

export const Container = styled.div`
  .paginaBranco {
    background-color: #fff;
    // backgroundColor:'transparent',
    border: 0px;
    border-radius: 6px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    //  max-width:1000px;
    margin: auto;
    padding: 2rem;

    header {
      padding-bottom: 1rem;
      border-bottom: 1px solid #d3d3d3;

      h1 {
        font-weight: 800;
        color: rgba(0, 0, 0, 0.8);
      }

      p {
        color: rgba(0, 0, 0, 0.7);
        font-weight: 600;
      }
    }

    form {
      display: flex;
      flex-direction: row;
      justify-content: center;
      justify-items: center;
      align-items: center;

      input {
        padding: 0.5rem 1rem;
      }
      button {
        border-radius: 5px;
        padding: 0.75rem 1rem;
        /* width: 25px;
                height: 25px; */
        background-color: ${(props) => props.theme.colors.primaryDark};
        color: #fff;
        border: 0px solid rgba(0, 0, 0, 0);
        margin: 0.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }

    .listagemDeInscricoes {
      padding: 0.25rem 0.5rem;
      text-decoration: underline;
      cursor: pointer;
    }
    .listagemDeInscricoes:hover {
      opacity: 0.8;
    }
  }

  .cardParaImpressao {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    text-align: center;

    .div__campo_botoes {
      display: flex;
      flex-direction: row;
    }

    .buttonImprimir_presenca {
      border: 1px solid #dcf8c6;
      color: #dcf8c6;
      background-color: #25d366;
      padding: 1rem 1.75rem;
      border-radius: 5px;
      display: flex;
      margin: auto 1rem;
      flex-direction: row;
      justify-content: center;
      justify-items: center;
      align-items: center;
      text-align: center;
      margin-top: 3rem;

      button {
        margin: auto;
      }

      svg {
        font-size: 3rem;
        margin-top: -0.1rem;
        margin-right: 0.5rem;
      }

      :disabled {
        opacity: 0.5;
        :hover {
          opacity: 0.5;
        }
      }
      :hover {
        opacity: 0.8;
      }
    }

    .buttonImprimir {
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 1rem 1.75rem;
      border-radius: 5px;
      display: flex;
      margin: auto 1rem;
      flex-direction: row;
      justify-content: center;
      justify-items: center;
      align-items: center;
      text-align: center;
      margin-top: 3rem;

      button {
        margin: auto;
      }

      svg {
        font-size: 3rem;
        margin-top: -0.1rem;
        margin-right: 0.5rem;
      }
    }

    .comDados {
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      align-items: center;
      align-self: center;
      text-align: center;
    }

    .etiqueta {
      height: 565px !important;
      width: 375px !important;
      text-align: center;
      font-size: 0.5cm;
      border: 1px solid rgba(0, 0, 0, 0.2);
      /* border-radius: 10px; */
      padding: 1.5rem 1rem;
      margin: 4rem auto;
      background-image: url(${(props) =>
        props.tipoCredential === false
          ? process.env.REACT_APP_TYPE_SITE === "solar"
            ? credencialfundoSolar
            : credencialfundo
          : process.env.REACT_APP_TYPE_SITE === "solar"
          ? credencialfundoSolar
          : credencialfundo2});
      background-position: center;
      background-size: cover;

      .informacoes {
        text-transform: uppercase;
        margin: auto;
        position: relative;
        top: 225px;
        height: 4cm;
        width: 8cm;
        /* background-color: rgba(0,0,0,0.2); */
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }

      .code {
        margin: 0;
        font: 14px "Fira Sans", sans-serif;
        letter-spacing: 0.01071em;
        font-weight: 400;
        text-transform: uppercase;
      }

      .typeInscription {
        font-size: 0.6cm;
        font-weight: 600;
        text-transform: uppercase;
      }

      .nomeCredencial {
        font-size: 0.72cm;
        font-weight: 800;
        text-transform: uppercase;
      }
      .demaisCredencial {
        font-size: 0.4cm;
        text-transform: uppercase;
      }
      .demaisCredencial2 {
        margin-top: 0.1cm;
        font-size: 0.4cm;
        text-transform: uppercase;
      }

      p {
        svg {
          font-size: 14px;
          margin-top: -0.1rem;
          margin-right: 0.3rem;
        }
      }

      h4 {
        padding: 0.5rem;
        margin-bottom: -1rem;
      }
    }
  }

  @media only screen and (max-width: 675px) {
    form {
      display: flex;
      flex-direction: column !important;
    }
  }
`;
