import { createContext, useState } from 'react';

export const EtiquetaContexto = createContext({});

export function EtiquetaProvider({ children, user }) {
  const [dadosEtiqueta, setDadosEtiqueta] = useState({
    credential_name: '',
    credential_role: '',
    credential_company: '',
  });

  const [dadosEtiquetaSorteio, setDadosEtiquetaSorteio] = useState({
    credential_id: '',
    credential_name: '',
    credential_role: '',
    credential_company: '',
    event_city: '',
    event_state: '',
    event_date: '',
  });

  return (
    <EtiquetaContexto.Provider
      value={{
        dadosEtiqueta,
        setDadosEtiqueta,
        dadosEtiquetaSorteio,
        setDadosEtiquetaSorteio
      }}
    >
      {children}
    </EtiquetaContexto.Provider>
  );
}
