import React from 'react';
import { getStateMap } from '../../utils/stateEvent';
import { Card } from './styles.js';

// icons
import RoomIcon from '@material-ui/icons/Room';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import TodayIcon from '@material-ui/icons/Today';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';

const CardEventoInter = ({ event, setConfirmDelete, idx }) => {
  return (
    <Card
      key={idx}
      selected={false}
      // selected={event.is_national_event ? true : false}
      nacional={event?.is_national_event}
    >
      <div className="div__event_nacional">
        <EmojiEventsIcon /> Nacional
      </div>
      <div className="div__content">
        <div className="div__svg_map">
          {event?.state && getStateMap(event?.state)}
        </div>
        <div className="div__local_img">
          <RoomIcon />
        </div>
        <div className="div__about_event">
          <h4>
            <RoomIcon /> {event.address}
          </h4>

          <p>
            <LocationSearchingIcon />
            {event.city} - {event.state}
          </p>

          <span>
            <TodayIcon />{' '}
            {new Date(event?.start_date).toLocaleString('pt-BR', {
              day: '2-digit',
              month: 'long',
              year: 'numeric',
            })}
          </span>
        </div>
        <div className="div__event_today">
          <EventAvailableIcon /> É hoje!
        </div>
      </div>

      <div className="buttons">
        {event.is_published ? (
          <a
            href={`${process.env.REACT_APP_LINK_EVENT}/eventos/${
              event?.id
            }/${event?.city
              .normalize('NFD')
              .toLowerCase()
              .replace(/[^a-zA-Zs]/g, '')}-${event?.state?.toLowerCase()}`}
            target="_blank"
            rel="noreferrer"
          >
            <button size="small" disabled={!event?.is_published}>
              <VisibilityIcon style={{ color: '#555' }} />
            </button>
          </a>
        ) : (
          ''
        )}

        <Link to={`/editar-evento/${event.id}`}>
          <button size="small" style={{ color: '#555' }} onClick={() => {}}>
            <CreateIcon onClick={() => {}} />
          </button>
        </Link>
        <a href="#delete">
          <button
            size="small"
            color="secondary"
            className="delete"
            onClick={() => setConfirmDelete([true, event.id])}
          >
            <DeleteIcon style={{ color: '#f44336' }} />
          </button>
        </a>
      </div>
    </Card>
  );
};

export default CardEventoInter;
