import styled from 'styled-components';

import credencialfundo from '../../../assets/credenciais/credencial-inter.jpg';

export const Container = styled.div`
  .paginaBranco {
    background-color: #fff;
    border: 0px;
    border-radius: 6px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    margin: auto;
    padding: 2rem;

    header {
      padding-bottom: 1rem;
      border-bottom: 1px solid #d3d3d3;

      h1 {
        font-weight: 800;
        color: rgba(0, 0, 0, 0.8);
      }

      p {
        color: rgba(0, 0, 0, 0.7);
        font-weight: 600;
      }
    }
    form {
      display: flex;
      flex-direction: row;
      justify-content: center;
      justify-items: center;
      align-items: center;

      input {
        padding: 0.5rem 1rem;
      }
      button {
        border-radius: 5px;
        padding: 0.75rem 1rem;
        /* width: 25px;
                height: 25px; */
        background-color: ${(props) => props.theme.colors.primaryDark};
        color: #fff;
        border: 0px solid rgba(0, 0, 0, 0);
        margin: 0.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }

    .listagemDeInscricoes {
      padding: 0.25rem 0.5rem;
      text-decoration: underline;
      cursor: pointer;
    }
    .listagemDeInscricoes:hover {
      opacity: 0.8;
    }
  }

  .cardParaImpressao {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    text-align: center;

    .div__campo_botoes {
      display: flex;
      flex-direction: row;
    }

    .buttonImprimir {
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 1rem 1.75rem;
      border-radius: 5px;
      display: flex;
      margin: auto auto;
      flex-direction: row;
      justify-content: center;
      justify-items: center;
      align-items: center;
      text-align: center;
      margin-top: 3rem;

      button {
        margin: auto;
      }

      svg {
        font-size: 3rem;
        margin-top: -0.1rem;
        margin-right: 0.5rem;
      }
    }

    .comDados {
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      align-items: center;
      align-self: center;
      text-align: center;
    }

    .etiqueta {
      height: 565px !important;
      width: 375px !important;
      text-align: center;
      font-size: 0.5cm;
      border: 1px solid rgba(0, 0, 0, 0.2);
      /* border-radius: 10px; */
      padding: 1.5rem 1rem;
      margin: 4rem auto;
      background-image: url(${credencialfundo});
      background-position: center;
      background-size: cover;

      .informacoes {
        text-transform: uppercase;
        margin: auto;
        position: relative;
        top: 225px;
        height: 4cm;
        width: 8cm;
        /* background-color: rgba(0,0,0,0.2); */
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }

      .nomeCredencial {
        font-size: 0.72cm;
        font-weight: 800;
        text-transform: uppercase;
      }
      .demaisCredencial {
        font-size: 0.4cm;
        text-transform: uppercase;
      }
      .demaisCredencial2 {
        margin-top: 0.1cm;
        font-size: 0.4cm;
        text-transform: uppercase;
      }

      p {
        /* height: 25px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-items: center;
        align-items: center;
        font-weight: 800; */

        /* color: #000;
        font-size: 1.25rem;
        font-weight: 200;

        margin: 0 0 7px 0;
        padding: 0; */

        svg {
          font-size: 14px;
          margin-top: -0.1rem;
          margin-right: 0.3rem;
        }
      }

      h4 {
        padding: 0.5rem;
        margin-bottom: -1rem;
      }
    }
  }

  @media only screen and (max-width: 675px) {
    form {
      display: flex;
      flex-direction: column !important;
    }
  }
`;

export const DivTexto = styled.div`
  width: 100%;
  text-align: center;

  @media print {
    display: none;
  }
`;

export const TicketPrint = styled.div`
  @media print {
    @page {
      margin: 0px;
      padding: 0px;
    }
    margin: 0px;
    padding: 0px;
    position: fixed;
    top: 0;
    left: 0;

    border: 0px solid rgba(0, 0, 0, 0);
    color: #000 !important;
    font-family: 'Times New Roman', Times, serif !important;
  }
  margin: 1rem auto;
  width: 8cm;
  height: 5cm;
  /* width: 100%; */
  border: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;

  .div__prim_part {
    /* flex:1; */
    /* writing-mode: vertical-lr; */
    /* padding: 1rem; */
    border-bottom: 1px dashed rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.5cm;
    font-family: 'Hypermarket' !important;
  }
  .div__second_part {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 1rem; */
    h2 {
      font-weight: 800;
      font-size: 0.8cm;
      text-transform: uppercase;
      font-family: 'Hypermarket' !important;
    }
    h3 {
      margin-top: -0.1cm;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 0.4cm;
      font-family: 'Hypermarket' !important;
    }
    h4 {
      font-weight: 800;
      font-size: 0.4cm;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Hypermarket' !important;
      svg {
        font-size: 1rem;
        margin-top: -0.1cm;
      }
    }
  }

  .div__terc_part {
    /* flex:1; */
    /* writing-mode: vertical-lr; */
    /* padding: 1rem; */
    border-top: 1px dashed rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Hypermarket' !important;

    svg {
      font-size: 1.3rem;
      transform: rotate(00deg);
    }
  }

  /* @media print{
    width: 100%;

   
    .div__prim_part{
      padding: 0rem;
    }
    .div__second_part{
      h2{
        font-weight: 800;
        font-size: 1.5rem;
        text-transform: uppercase;
      }
    }
  } */
`;

export const CampoRotacao = styled.div`
  display: flex;
  svg {
    /* position: absolute; */
    /**top: 50%;**/
    /**left: 50%;**/
    margin: auto;
    width: 5rem;
    height: 5rem;
    /**margin:-60px 0 0 -60px;**/
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @media print {
    display: none;
  }
`;
