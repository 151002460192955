import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';

import { DropzoneArea } from 'material-ui-dropzone';
import { Container } from './styles';

import 'date-fns';

// temas
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';
import Backdrop from '../Backdrop';
import imageUrl from '../../utils/imageUrl';
import api from '../../services/api';
import { acceptedFilesTypes } from '../../utils/acceptedFilesTypes';

export default function Novobannerhome(props) {
  const [backdrop, setBackdrop] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState([false, '']);

  // tema
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState(props.tamanho);

  const [messageError, setMessageError] = React.useState('');

  const [dados, setDados] = React.useState({
    id: props.editar ? props.data.id : '',
    event_id: props.editar ? props.data.event_id : props.id,
    link: props.editar ? props.data.link : '',
    image_web: '',
    image_mobile: '',
    show_mobile_img: props.editar && props.data.image_mobile ? true : false,
  });

  function handleChangeImageWeb(file) {
    setDados({
      ...dados,
      image_web: file[0],
    });
  }
  function handleChangeImageMobile(file) {
    setDados({
      ...dados,
      image_mobile: file[0],
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!dados.image_web) return toast.warn('Anexar banner é obrigatório');
    if (dados.show_mobile_img && !dados.image_mobile)
      return toast.warn(
        'Anexar banner para mobile é obrigatório. Caso não queira anexar, desmarque o campo indicado',
      );

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'multipart/form-data',
      },
    };

    const data = new FormData();
    data.append('image_web', dados.image_web);
    data.append('show_in_home', 'true');
    dados.link && data.append('link', dados.link);
    dados.show_mobile_img &&
      dados.image_mobile &&
      data.append('image_mobile', dados.image_mobile);

    await api
      .post(`banners`, data, config)
      .then((res) => {
        toast.success('Banner adicionado com sucesso.');

        setMessageError('');
        handleClose();
        props.getBanner();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error.response.data);
      })
      .finally(() => setBackdrop(false));

    setMessageError('');
    handleClose();
    props.getBanner();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setDados({
      id: props.editar ? props.data.id : '',
      event_id: props.editar ? props.data.event_id : props.id,
      link: props.editar ? props.data.link : '',
      image_web: '',
      image_mobile: '',
      show_mobile_img: props.editar && props.data.image_mobile ? true : false,
    });
    setMessageError('');
    setOpen(false);
  };

  return (
    <React.Fragment>
      <div>
        <Button
          variant="contained"
          onClick={handleOpen}
          className="Button"
          style={{ backgroundColor: theme.colors.primaryDark, color: '#fff' }}
        >
          Adicionar Banner
        </Button>
      </div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        scroll={'body'}
        aria-labelledby="max-width-dialog-title"
      >
        <Container>
          <form onSubmit={handleSubmit}>
            <DialogTitle id="max-width-dialog-title">
              {props.editar ? 'Editar Banner' : '+ Novo Banner'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div className="subtitulo">
                  Preencha corretamente os campos abaixo para{' '}
                  {props.editar ? 'editar o' : 'adicionar novo'} banner.
                </div>
                <div className="formulario">
                  <p style={{ fontSize: '16px' }}>{props.message}</p>
                  <br />
                  <h3>
                    {props.editar ? 'Editar' : 'Adicionar'} o banner principal *
                  </h3>
                  <DropzoneArea
                    required
                    onChange={handleChangeImageWeb}
                    acceptedFiles={acceptedFilesTypes}
                    filesLimit={'1'}
                    dropzoneText="Arraste a imagem para este campo ou clique aqui para buscar"
                    maxFileSize="6000000"
                    getFileAddedMessage={(fileName) =>
                      `Arquivo ${fileName} adicionado com sucesso.`
                    }
                    getFileRemovedMessage={(fileName) =>
                      `Arquivo ${fileName} removido.`
                    }
                    getFileLimitExceedMessage={(filesLimit) =>
                      `Número máximo de ${filesLimit} arquivos excedido.`
                    }
                    getDropRejectMessage={() =>
                      `Tipo de arquivo não permitido.`
                    }
                  />
                  {props.editar && props.data.image_web && (
                    <div className="imagematual">
                      <h3>Imagem atual</h3>
                      <img
                        src={imageUrl(props.data.image_web)}
                        alt="image_web_selected"
                        loading="lazy"
                      />
                    </div>
                  )}
                  <TextField
                    id="standard-basic"
                    className="campo"
                    label="Link para a imagem (Opcional)"
                    type="url"
                    onChange={(e) =>
                      setDados({ ...dados, link: e.target.value })
                    }
                    value={dados.link}
                  />
                  <br />
                  Agora você também tem opção de adicionar uma imagem
                  alternativa para a visualização do banner na versão mobile de
                  forma com que o usuário tenha melhor experiência ao visualizar
                  as informações.
                  <br />
                  <br />
                  <label
                    className={
                      dados.show_mobile_img
                        ? 'chkbox__checked chkbox__img_mobile'
                        : 'chkbox__img_mobile'
                    }
                  >
                    <input
                      type="checkbox"
                      checked={dados.show_mobile_img}
                      onChange={() =>
                        setDados({
                          ...dados,
                          show_mobile_img: !dados.show_mobile_img,
                        })
                      }
                    />{' '}
                    Incluir imagem mobile
                  </label>
                  <br />
                  {dados.show_mobile_img && (
                    <h3>
                      {' '}
                      {props.editar ? 'Editar' : 'Adicionar'} o banner para
                      mobile *
                    </h3>
                  )}
                  {dados.show_mobile_img && (
                    <DropzoneArea
                      required
                      onChange={handleChangeImageMobile}
                      acceptedFiles={acceptedFilesTypes}
                      filesLimit={'1'}
                      dropzoneText="Arraste a imagem para este campo ou clique aqui para buscar"
                      maxFileSize="6000000"
                      getFileAddedMessage={(fileName) =>
                        `Arquivo ${fileName} adicionado com sucesso.`
                      }
                      getFileRemovedMessage={(fileName) =>
                        `Arquivo ${fileName} removido.`
                      }
                      getFileLimitExceedMessage={(filesLimit) =>
                        `Número máximo de ${filesLimit} arquivos excedido.`
                      }
                      getDropRejectMessage={() =>
                        `Tipo de arquivo não permitido.`
                      }
                    />
                  )}
                  {props.editar &&
                    dados.show_mobile_img &&
                    props.data.image_mobile && (
                      <div className="imagematual">
                        <h3>Imagem Mobile atual</h3>
                        <img
                          src={imageUrl(props.data.image_mobile)}
                          alt="image_mobile_selected"
                          loading="lazy"
                          style={{
                            width: '75% !important',
                            margin: 'auto',
                            maxWidth: '200px',
                          }}
                        />
                      </div>
                    )}
                  {props.editar && (
                    <div className="div__delede_field">
                      <span>Deseja deletar este banner? </span>
                      <button
                        type="button"
                        onClick={() => setConfirmDelete([true, props.data])}
                      >
                        Deletar
                      </button>
                    </div>
                  )}
                </div>
              </DialogContentText>
            </DialogContent>

            {messageError && (
              <Alert
                style={{ margin: '1rem 2rem' }}
                variant="outlined"
                severity="error"
              >
                {messageError.replace('!', '')}. Corrija e tente novamente.
              </Alert>
            )}

            <DialogActions>
              <Button onClick={handleClose} color="" variant="contained">
                Cancelar
              </Button>
              <Button
                onClick={() => true}
                style={{
                  backgroundColor: theme.colors.primaryDark,
                  color: '#fff',
                }}
                variant="contained"
                type="submit"
              >
                {props.editar ? 'Editar' : 'Adicionar'}
              </Button>
            </DialogActions>
          </form>
        </Container>

        {backdrop && <Backdrop />}
      </Dialog>
    </React.Fragment>
  );
}
