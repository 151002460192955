import styled from 'styled-components';

export const Container = styled.div`
  .header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    padding: 0.1rem 0.5rem;

    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    .left {
      display: flex;

      .icon {
        transform: ${(props) =>
          props.active
            ? 'rotate(90deg) translateX(-00%) translateY(+10%)'
            : ''};
        transition: 0.3s;
        transition-timing-function: ease-in;
      }

      span {
        font-size: 1.5rem;
        margin-right: 1rem;
      }
    }
  }

  .content {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0rem;

    .div__oneRow {
      display: flex;
    }
    .div__oneColumn {
      display: flex;
      flex-direction: column;
    }

    /* detalhes das opções  */
    .div__options_buttons {
      display: flex;
      justify-content: flex-end;
      align-items: right;
      padding: 1rem;
      /* gap: 0.5rem; */

      button {
        padding: 0.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px !important;
        padding: 0.8rem 1rem;
        margin-bottom: -0.2rem;
        border: 0;
        outline: none;
        text-decoration: none;
        margin-left: 1rem;
        background: rgba(0, 0, 0, 0.1);
        svg {
          margin-right: 0.25rem;
        }
      }
      button:hover {
        color: var(--white);
      }
      .btn__edit_auditorium {
        color: #3f51b5;
        :hover {
          background-color: #3f51b5;
        }
      }
      .btn__delete_auditorium {
        color: var(--error);
        :hover {
          background-color: var(--error);
        }
      }
    }

    /* detalhes auditorio style  */
    .div__body_auditoriums {
      background-color: white;
      margin-top: -19px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
      padding: 2rem;

      .div__body_name {
        font-size: 1.25rem;
        b {
          font-size: 1.1rem;
        }
      }
      .div__body_description {
        margin-right: 2rem;
        margin-top: 1rem;
        font-size: 1.1rem;
        b {
          font-size: 1.1rem;
        }
      }
      .div__body_image {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          margin: 0rem;
          width: 250px;
          height: auto;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        }

        span {
          max-width: 200px;
          text-align: center;
          margin: 0rem;
        }
        /* background: red; */
        svg {
          margin: 0rem;
          color: rgba(0, 0, 0, 0.5);
          width: 200px;
          height: 200px;
        }
      }
    }
    .div__group_auditoriums {
      .div__group_head {
        display: flex;
        width: 100%;
        overflow-x: scroll;
        padding: 0.5rem;
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;

        button {
          margin: 0rem 0.25rem;

          border: 0rem;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
          padding: 0.5rem 1rem;
          border-radius: 1rem 1rem 0rem 0rem;

          background-color: ${(props) => props.theme.colors.primaryDark};
          color: ${(props) => props.theme.colors.white};
        }

        .selected {
          background: white;
          color: ${(props) => props.theme.colors.primaryDark};
        }
      }
    }

    .a {
      display: flex;
    }

    display: ${(props) => (props.active ? 'flex' : 'none')};
  }

  .button {
    display: flex;
    justify-content: flex-end;

    .salvarinfo {
      padding: 0.8rem 1rem;
      background: ${(props) => props.theme.colors.primaryDark};
      border: 0;
      outline: none;
      margin-bottom: -0.2rem;

      color: #fff;
      font-size: 1rem;
      border-radius: 5px 5px 0rem 0rem !important;
    }
    .cancel {
      padding: 0.8rem 1rem;
      margin-bottom: -0.2rem;
      border-radius: 5px 5px 0rem 0rem !important;
      border: 0;
      outline: none;
      background: rgba(0, 0, 0, 0.1);
      color: #3f51b5;
      text-decoration: none;
      margin-left: 1rem;
    }
  }

  .campoDeEdicao {
    width: 100%;
    .header2 {
      width: 100%;
      margin: 0rem 1rem;

      .cadastroManual {
        float: right;
        background-color: ${(props) => props.theme.colors.primaryDark};
        /* margin: 0.8rem auto; */
        align-self: center;
        border: 0px;
        color: #fff;
        padding: 1.15rem 1.25rem;
        display: flex;
        flex-direction: row;
        justify-items: center;
        align-items: center;
        margin-bottom: 1rem;
        margin-right: 2rem;
        margin-top: 1rem;
        img {
          width: 20px;
          margin-right: 0.5rem;
        }
      }
      .cadastroManual:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
      }
    }
    .ul {
      display: flex;
      flex-direction: column;
      width: 100%;

      .li {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 95%;
        margin: 0.05rem auto;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        /* padding: 1rem; */
        div {
          padding: 1rem 0.5rem;
          display: flex;
          align-items: center;
        }

        span.Palestra {
          background-color: #0d999a;
          color: #fff;

          margin: 0 1rem;
          padding: 0.25rem 0.5rem;
          border-radius: 0.1rem;

          font-size: 1rem;
          font-weight: 500;
        }

        span.Painel {
          background-color: #197b9c;
          color: #fff;

          margin: 0 1rem;
          padding: 0.25rem 0.5rem;
          border-radius: 0.1rem;

          font-size: 1rem;
          font-weight: 500;
        }
        .div__day {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0rem 2rem;
          text-transform: uppercase;
          div {
            margin: 0;
            padding: 0;
          }
        }

        .hora {
          text-align: left;
          font-size: 14px;
          font-weight: 600;
        }
        .campoMenu {
          .botoes {
            padding: 0.5rem;
          }
          .btnedit {
            bottom: 0.5rem;
            right: 1rem;
            padding: 0.5rem;
          }
          .btnedit:hover {
            background-color: #6c6c6c;
            svg {
              color: #fff;
            }
          }
          .btndel {
            margin-right: 0.5rem;
          }
          .btndel:hover {
            background-color: #f44336;
            svg {
              color: #fff;
            }
          }
        }
      }
    }
  }
`;
