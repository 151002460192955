import React from 'react';
import { Card } from './styles';

// icones
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';

const CardPalestrante = ({ speaker, setConfirmacao, setDialogoApoiador }) => {
  return (
    <Card key={speaker.id}>
      <div className="div__content">
        <div className="div__svg_map">
          <img src={speaker.image_url} alt="" />
        </div>
        <div className="div__dados">
          <h3>{speaker.name}</h3>
          <p>{speaker.formation}</p>
        </div>
      </div>

      <div className="buttons">
        <button
          className="edit"
          onClick={async (e) => {
            setDialogoApoiador({
              status: true,
              editar: true,
              dados: speaker,
            });
          }}
        >
          <CreateIcon style={{ color: '#555' }} />
        </button>
        <button
          className="delete"
          onClick={(e) => {
            setConfirmacao([true, speaker.id]);
          }}
        >
          <DeleteIcon style={{ color: '#f44336' }} />
        </button>
      </div>
    </Card>
  );
};

export default CardPalestrante;
