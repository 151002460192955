import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  // backgroundColor:'transparent',
  border: 0px;
  border-radius: 6px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  //  max-width:1000px;
  margin: auto;
  padding: 2rem;
  margin-bottom: 2rem;

  header {
    padding-bottom: 1rem;
    border-bottom: 1px solid #d3d3d3;

    h1 {
      font-weight: 800;
      color: rgba(0, 0, 0, 0.8);
    }

    p {
      color: rgba(0, 0, 0, 0.7);
      font-weight: 600;
    }
  }

  body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .div__duasCol {
      width: 100%;
      display: flex;
      justify-content: center;

      .button__excluir {
        flex: 1;
        margin: 3rem 0rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e74c3c;
        color: #fff;
        border: 0;
        margin-left: -2px;
        svg {
          margin: 0.2rem;
        }
      }
    }

    .label__file {
      flex: 5;
      padding: 1rem;
      border: 2px dashed rgba(0, 0, 0, 0.5);
      color: #000;
      width: 95%;
      margin: 3rem 0rem;
      display: flex;
      align-items: center;
      font-size: 1rem;

      span {
        visibility: hidden;
        margin: 1rem;
        background-color: rgb(246, 197, 10);
        color: #fff;
        font-size: 0.85rem;
        padding: 0.25rem 0.5rem;
        border-radius: 0.5rem;
      }

      svg {
        margin: 1rem;
      }

      .input__file {
        display: none;
      }

      :hover {
        cursor: pointer;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      }
    }

    .onArchive {
      border: 2px dashed #25d366;
      color: #25d366;
      pointer-events: none;

      :hover {
        border: 2px dashed rgb(246, 197, 10);
        color: rgb(246, 197, 10);

        span {
          visibility: visible;
        }
      }
    }

    .button_coletar {
      padding: 0.8rem 1.5rem;
      border-radius: 0.75rem;
      background-color: #1c4185;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #1c4185;
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
    }

    .onArchiveButton {
      cursor: pointer;
      opacity: 1;
      pointer-events: initial;
      :hover {
        color: #1c4185;
        background-color: #fff;
      }
    }
  }

  .div__encontrado {
    margin: 3rem 0rem;
    width: 100%;

    .botaoBaixarExcel {
      background-color: #217346;
      margin: 3rem auto;
      margin-top: 4rem;
      align-self: center;
      border: 0px;
      color: #fff;
      padding: 1rem 1.25rem;
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      img {
        width: 20px;
        margin-right: 0.5rem;
      }
    }
  }

  .div__infor {
    width: 100%;
    background-color: #efefef;
    padding: 3rem;
    font-size: 1rem;

    ul {
      margin-top: 1rem;
    }
  }
`;

export const Body = styled.body`
  margin: 1rem 0rem;

  details {
    width: 100%;
    background-color: #e7e7e7;
    margin: 0.1rem 0rem;

    summary {
      padding: 1.5rem 1.25rem;
      font-size: 1rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      cursor: pointer;
      background-color: ${(props) => props.theme.colors.primaryDark};
      color: #fff;
    }

    .content {
      padding: 2rem;
      font-size: 1rem;

      .action__buttons {
        text-align: right;

        .button__cancel {
          padding: 0.7rem 1.9rem;
          border: 1px solid ${(props) => props.theme.colors.primaryDark};
          color: ${(props) => props.theme.colors.primaryDark};
          background-color: #fff;
          margin: 0rem 1rem;

          :hover {
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
            filter: brightness(1.1);
          }
        }

        .button__save {
          padding: 0.75rem 2rem;
          background-color: ${(props) => props.theme.colors.primaryDark};
          color: #fff;
          border: 0;

          :hover {
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
            filter: brightness(1.1);
          }
        }
      }
    }
  }
`;
