const Ac = (props) => {
  return (
    <svg
      width={200}
      height={200}
      viewBox="0 0 52.917 52.917"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        style={{
          fill: 'red',
          fillOpacity: 1,
          strokeWidth: 0.429252,
        }}
        d="M1.924 12.533c-.482.016-.725.223-.725.619 0 1.287 3.12 6.89 4.813 8.64 1.543 1.597 1.707 1.968 1.557 3.541-.155 1.618-.086 1.76.866 1.76 1.568 0 3.952 1.677 4.605 3.242.536 1.282.767 1.409 2.85 1.573 2.575.204 3.615-.201 6.897-2.687l2.235-1.691.359 1.378c.216.83.168 2.359-.122 3.83-.265 1.348-.379 2.947-.252 3.554l.453 2.176c.223 1.072.228 1.073 5.472 1.103 2.888.017 5.637.196 6.11.4 1.758.755 3.159.476 4.118-.821.622-.843 1.572-1.414 2.91-1.75 1.092-.274 2.341-.82 2.776-1.213.435-.393 1.688-1.199 2.785-1.792 1.097-.593 2.035-1.368 2.086-1.722.064-.445-3.18-2.263-10.512-5.888-5.832-2.884-11.734-5.924-13.119-6.757-2.01-1.211-3.35-1.642-6.654-2.139-4.427-.665-13.11-3.03-16.513-4.497-1.305-.563-2.274-.85-2.895-.86h-.1zM36.6 28.002c.269 0 .51.014.724.045a3.9 3.9 0 0 1 1.122.301c.157.066.294.128.411.185v1.55h-.188a7.138 7.138 0 0 0-.304-.244 3.828 3.828 0 0 0-.413-.28 2.865 2.865 0 0 0-.511-.231 1.96 1.96 0 0 0-1.25.017 1.577 1.577 0 0 0-.58.36 1.804 1.804 0 0 0-.412.648c-.103.27-.154.596-.154.98 0 .4.056.736.168 1.005.114.269.258.48.43.634.174.158.368.272.583.34.215.066.426.099.634.099.2 0 .4-.03.594-.09a2.3 2.3 0 0 0 .545-.245c.14-.083.27-.171.391-.266.12-.094.219-.177.296-.245h.171v1.53c-.16.07-.313.138-.459.2-.146.063-.3.119-.46.165-.209.06-.404.105-.587.137a4.712 4.712 0 0 1-.756.047c-.475 0-.914-.071-1.318-.211-.4-.14-.746-.349-1.036-.626a2.824 2.824 0 0 1-.673-1.04c-.157-.414-.237-.895-.237-1.44 0-.506.076-.964.229-1.376a2.95 2.95 0 0 1 .661-1.061 2.805 2.805 0 0 1 1.03-.657 3.812 3.812 0 0 1 1.349-.231zm-6.847.123h1.896l2.362 6.394h-1.703l-.444-1.293h-2.37l-.442 1.293h-1.661zm.927 1.636-.787 2.293h1.572z"
      />
    </svg>
  );
};

export default Ac;
