import React from 'react';
import ReactDOM from 'react-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import App from './App';

import { EtiquetaProvider } from './context/EtiquetaContexto';
import { ThemeProvider } from 'styled-components';

// temas
import { interispTheme } from './styles/themes/interisp';

ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
      <EtiquetaProvider>
        <ThemeProvider
          theme={process.env.REACT_APP_TYPE_SITE === 'isp' ? interispTheme : ''}
        >
          <App />
        </ThemeProvider>
      </EtiquetaProvider>
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById('root'),
);
