import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  padding: 1rem 2rem;
  min-height: ${window.innerHeight};

  header {
    padding-bottom: 1rem;
    border-bottom: 1px solid #d3d3d3;

    h1 {
      font-weight: 800;
      color: rgba(0, 0, 0, 0.8);
    }

    p {
      color: rgba(0, 0, 0, 0.7);
      font-weight: 600;
    }
  }

  .newBanner {
    margin-top: 2rem;
    width: 100%;
    position: relative;

    .btnNew {
      position: absolute;
      right: 5px;
    }
  }
`;

export const Deck = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
  padding-top: 4rem;
  justify-content: space-evenly;
`;

export const Card = styled.div`
  width: 370px;
  height: 210px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  margin: 1rem;
  display: flex;
  flex-direction: column;

  .containerBanner {
    background-image: url(${(props) => props.imgUrl});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 185px;
  }

  .div__link {
    height: 25px;
    a {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      height: 100%;
      text-decoration: none;
      margin-top: 0.1rem;
      color: ${(props) => props.theme.colors.primary};
      text-align: center;
      justify-content: center;
      :hover {
        color: ${(props) => props.theme.colors.primaryLight};
      }

      svg {
        margin-top: -0.1rem;
        margin-right: 0.3rem;
      }
    }
  }

  .div__p1 {
    flex: 5;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .div__action_buttons {
      width: 100%;
      height: 185px;
      margin-top: -185px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      z-index: 10;

      button {
        visibility: hidden;
        display: flex;
        padding: 0rem 2rem;
        justify-content: center;
        align-items: center;
        height: 50px;
        border-radius: 0.5rem;
        color: #ffffff;
        background-color: transparent;
        border: 1px solid rgba(0, 0, 0, 0);
        svg {
          margin-right: 0.5rem;
          margin-left: -0.5rem;
        }
      }

      :hover {
        background-color: rgba(0, 0, 0, 0.6);

        button {
          visibility: visible;

          :hover {
            background-color: rgba(0, 0, 0, 0.4);
          }
        }
        .button__del {
          :hover {
            background-color: ${(props) => props.theme.colors.delete};
          }
        }
      }
    }
  }
`;
