import React, { useContext, useEffect, useState } from 'react';
import { Container } from './styles';
import api from '../../services/api';

// components
// import { Editor } from '@tinymce/tinymce-react';
import PublishIcon from '@material-ui/icons/Publish';

// image
import defaultEventImage from '../../assets/background/default-event.jpg';

// libs
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

// editor de texto
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { EdicaoEvento } from '../../context/edicaoEvento';
import { toast } from 'react-toastify';
import { useEvents } from '../../context/eventsContext';
import imageUrl from '../../utils/imageUrl';

const defaultDescription =
  "<div><h5>Visão geral do evento</h5><p>Evendo da Inter' ISP.</p>";

const defaultDescriptionSolar =
  "<div><h5>Visão geral do evento</h5><p>Evendo da Inter' ISP.</p>";

function DetalhesEvento() {
  const {
    dados,
    setIsLoading,
    setControle,
    active,
    setActive,
    id,
    user,
    uf,
    getData: newGetData,
  } = useContext(EdicaoEvento);
  const { getData } = useEvents();

  console.log('DADOS: ', dados?.event);

  const [dados2, setDados2] = useState(dados.event ? dados.event : {});

  const [update, setUpdate] = useState(false);

  const [files, setFiles] = React.useState(dados.event.image_description);
  const [filesSecondary, setFilesSecondary] = React.useState(
    dados.event.image_description2,
  );

  const [textdescription, setTextdescription] = React.useState({
    description: EditorState.createEmpty(),
  });

  const [eventHasDate, setEventHasDate] = useState(
    dados2.start_date === null || !dados2.start_date ? false : true,
  );

  const formatDate = (date) => {
    const dateFormated = format(new Date(date), 'dd/MM/yyyy HH:mm', {
      locale: ptBR,
    });

    return dateFormated;
  };

  const cancelarAtualizazao = () => {
    setDados2(dados.event);
    setFiles(dados.event.image_description);
    setFilesSecondary(dados.event.image_description2);
    setUpdate(!update);
  };

  const handleChangeImage = (e) => {
    setFiles(e.target.files[0]);
    setDados2({
      ...dados2,
      image_description_url: URL.createObjectURL(e.target.files[0]),
    });
  };

  const handleChangeImageSecondary = (e) => {
    console.log('DADOS2: ', dados2);
    setFilesSecondary(e.target.files[0]);
    console.log('AQUI: ', e.target.files[0]);
    setDados2({
      ...dados2,
      image_description2_url: URL.createObjectURL(e.target.files[0]),
    });
  };

  const validateHtml = (htmlCode) => {
    let aux = new DOMParser().parseFromString(htmlCode, 'text/html');
    return aux?.body?.textContent?.trim() || '';
  };

  const atualizarDados = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setControle(false);

    console.log('[EVENTO COM DATA] => ', eventHasDate);

    const config = {
      headers: {
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'multipart/form-data',
      },
    };

    const data = new FormData();

    console.log('[DESCRIÇÃO]=> ', validateHtml(dados2.description));

    data.append('start_date', dados2.start_date);
    data.append('end_date', dados2.end_date);
    data.append('address', dados2.address);
    data.append('place', dados2.place);
    data.append('city', dados2.city);
    data.append('state', dados2.state);
    data.append('orientation_left', false);
    data.append('image_description', files);
    data.append('image_description2', filesSecondary);
    data.append(
      'description',
      validateHtml(dados2.description)
        ? dados2.description
        : process.env.REACT_APP_TYPE_SITE === 'isp'
        ? defaultDescription
        : defaultDescriptionSolar,
    );
    data.append('is_published', dados2.is_published);
    data.append('is_national_event', dados2.is_national_event);

    await api
      .put(`/events/${id}`, data, config)
      .then(async (a) => {
        // console.log(' RETORNO, ', a.data);
        await getData();
        setIsLoading(false);
        setDados2(a.data);
        setControle(true);
        setFiles([dados.event.image_description]);
        setFilesSecondary([dados.event.image_description2]);

        await setUpdate(!update);
        await newGetData();
        // setDados2(dados.event);
      })
      .catch((error) => {
        console.log(error.response);
        toast.warn(error.response.data.message);
      });
  };

  const onEditorStateChange = (editorState) => {
    setTextdescription({
      ...textdescription,
      description: editorState,
    });
    setDados2({
      ...dados2,
      description: draftToHtml(
        convertToRaw(textdescription.description.getCurrentContent()),
      ),
    });
  };

  // console.log('dados eventos 2: ', dados2);

  useEffect(() => {
    if (dados) {
      // console.log('oioioioio');
      setDados2(dados.event);
    }
  }, [dados]);

  // console.log(active.detalhesEvento);

  return (
    <Container update={update} active={active.detalhesEvento}>
      <form onSubmit={atualizarDados}>
        <div className="header">
          <div
            className="left"
            onClick={() =>
              update
                ? window.alert('Para fechar termine ou cancele a edição!')
                : setActive({
                    ...active,
                    detalhesEvento: !active.detalhesEvento,
                  })
            }
          >
            <span className="icon">&#10148;</span>
            <h2 className="title">Detalhes do evento</h2>
          </div>

          <div className="button">
            {update ? (
              <>
                <button type="submit" className="salvarinfo">
                  Salvar informações
                </button>
                <button
                  type="button"
                  className="cancel"
                  onClick={() => cancelarAtualizazao()}
                >
                  Cancelar
                </button>
              </>
            ) : (
              <div
                className="cancel"
                onClick={() => {
                  setUpdate(true);
                  setActive({ ...active, detalhesEvento: true });
                }}
              >
                Editar
              </div>
            )}
          </div>
        </div>
        <div className="content">
          <div className="a">
            <table>
              <tbody>
                <tr>
                  <th>Início do Evento: </th>
                  <td>
                    {update ? (
                      dados2.start_date === null || !dados2.start_date ? (
                        <div className="paiCheckboxContainer">
                          <p>Em breve</p>
                          <div className="checkboxContainer">
                            <input
                              type="checkbox"
                              name="date_check"
                              id="date_check"
                              value={true}
                              checked
                              onChange={(e) => {
                                setDados2({
                                  ...dados2,
                                  start_date: new Date().toISOString(),
                                });
                                setEventHasDate(true);
                              }}
                            />

                            <label for="date_check">Evento sem data</label>
                          </div>
                        </div>
                      ) : (
                        <>
                          <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={ptBR}
                          >
                            <DateTimePicker
                              value={dados2.start_date}
                              format="dd/MM/yyyy HH:mm"
                              ampm={false}
                              onChange={(e) => {
                                setDados2({
                                  ...dados2,
                                  start_date: e.toISOString(),
                                });
                                setEventHasDate(false);
                              }}
                              style={{ width: '100%' }}
                            />
                          </MuiPickersUtilsProvider>

                          <div className="checkboxContainer">
                            <input
                              type="checkbox"
                              name="date_check"
                              id="date_check"
                              value={false}
                              onChange={() => {
                                setDados2({
                                  ...dados2,
                                  start_date: null,
                                  end_date: null,
                                });
                                console.log('false');
                              }}
                            />

                            <label for="date_check">Evento sem data</label>
                          </div>
                        </>
                      )
                    ) : dados2.start_date === null || !dados2.start_date ? (
                      'Em breve'
                    ) : (
                      formatDate(dados2.start_date)
                    )}
                  </td>
                </tr>
                {dados2.start_date && (
                  <tr>
                    <th> {<>Final do Evento: </>}</th>
                    <td>
                      {update ? (
                        // !moreDays ? (
                        //   <div className="paiCheckboxContainer">
                        //     <p>Não definido</p>

                        //   </div>
                        // ) : (
                        <>
                          <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={ptBR}
                          >
                            <DateTimePicker
                              value={dados2.end_date}
                              format="dd/MM/yyyy HH:mm"
                              ampm={false}
                              minDate={dados2.start_date}
                              onChange={(e) => {
                                setDados2({
                                  ...dados2,
                                  end_date: e.toISOString(),
                                });
                                setEventHasDate(false);
                              }}
                              style={{ width: '100%' }}
                            />
                          </MuiPickersUtilsProvider>
                        </>
                      ) : //)
                      dados2.end_date === null || !dados2.end_date ? (
                        'Em breve'
                      ) : (
                        formatDate(dados2.end_date)
                      )}
                    </td>
                  </tr>
                )}

                <tr>
                  <th>Cidade: </th>
                  <td>
                    {update ? (
                      // <input
                      //   type="text"
                      //   name="city"
                      //   value={dados2.city}
                      //   onChange={handleChange}
                      // />
                      <TextField
                        required
                        id="standard-margin-name"
                        // label="Cidade"
                        inputProps={{
                          readOnly: dados2.is_national_event ? true : false,
                        }}
                        value={dados2.city}
                        onChange={(e) =>
                          setDados2({ ...dados2, city: e.target.value })
                        }
                        style={{ width: '100%' }}
                        // className={classes.textField}
                        variant="standard"
                      />
                    ) : (
                      dados2.city
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Estado: </th>
                  <td>
                    {update ? (
                      // <input
                      //   type="text"
                      //   name="state"
                      //   value={dados2.state}
                      //   onChange={handleChange}
                      // />
                      <FormControl required style={{ width: '100%' }}>
                        {/* <InputLabel 
                          id="demo-simple-select-required-label"
                        >
                          UF
                        </InputLabel> */}
                        <Select
                          labelId="demo-simple-select-required-label"
                          id="demo-simple-select-required"
                          defaultValue={dados2.state}
                          onChange={function (e) {
                            if (dados2.is_national_event) {
                              e.target.value === 'SP'
                                ? setDados2({
                                    ...dados2,
                                    state: e.target.value,
                                    city: 'São Paulo',
                                  })
                                : setDados2({
                                    ...dados2,
                                    state: e.target.value,
                                    city: 'Olinda',
                                  });
                            } else {
                              setDados2({ ...dados2, state: e.target.value });
                            }
                          }}
                          style={{ marginTop: '1rem' }}
                        >
                          {uf
                            .filter((value) =>
                              dados2.is_national_event
                                ? value === 'SP' || value === 'PE'
                                : value,
                            )
                            .map((item, index) => {
                              return <MenuItem value={item}>{item}</MenuItem>;
                            })}
                        </Select>
                      </FormControl>
                    ) : (
                      dados2.state
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Endereço: </th>
                  <td>
                    {update ? (
                      // <input
                      //   type="text"
                      //   name="address"
                      //   value={dados2.address}
                      //   onChange={handleChange}
                      // />
                      <TextField
                        id="standard-full-width"
                        // label="Endereço"
                        value={dados2.address}
                        onChange={(e) =>
                          setDados2({ ...dados2, address: e.target.value })
                        }
                        fullWidth
                        margin="dense"
                        variant="standard"
                      />
                    ) : (
                      dados2.address
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Tipo de Evento: </th>
                  <td>
                    {update ? (
                      // <input
                      //   type="text"
                      //   name="place"
                      //   value={dados2.place}
                      //   onChange={handleChange}
                      // />
                      <FormControl required style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-required-label"></InputLabel>
                        <Select
                          labelId="demo-simple-select-required-label"
                          id="demo-simple-select-required"
                          defaultValue={dados2.is_national_event}
                          onChange={function (e) {
                            e.target.value
                              ? setDados2({
                                  ...dados2,
                                  is_national_event: e.target.value,
                                  state: 'SP',
                                  city: 'São Paulo',
                                })
                              : setDados2({
                                  ...dados2,
                                  is_national_event: e.target.value,
                                });
                          }}
                        >
                          <MenuItem value={false}>Regional</MenuItem>
                          <MenuItem value={true}>Nacional</MenuItem>
                        </Select>
                      </FormControl>
                    ) : dados2.is_national_event ? (
                      'Nacional'
                    ) : (
                      'Regional'
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Local: </th>
                  <td>
                    {update ? (
                      // <input
                      //   type="text"
                      //   name="place"
                      //   value={dados2.place}
                      //   onChange={handleChange}
                      // />
                      <TextField
                        // label="Local"
                        id="standard-full-width"
                        defaultValue={dados2.place}
                        style={{ width: '100%' }}
                        variant="standard"
                        onChange={(e) =>
                          setDados2({ ...dados2, place: e.target.value })
                        }
                      />
                    ) : (
                      dados2.place
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Status: </th>
                  <td>
                    {update ? (
                      // <select
                      //   required
                      //   style={{ flex: 1 }}
                      //   onChange={handleChange}
                      //   name="is_published"
                      //   value={dados2.is_published}
                      //   disabled={false}
                      // >
                      //   <option value={true}>Publicado</option>
                      //   <option value={false}>Em rascunho</option>
                      // </select>
                      <FormControl required style={{ width: '100%' }}>
                        <Select
                          labelId="demo-simple-select-required-label"
                          id="demo-simple-select-required"
                          defaultValue={dados2.is_published}
                          onChange={(e) =>
                            setDados2({
                              ...dados2,
                              is_published: e.target.value,
                            })
                          }
                          style={{ marginTop: '1rem' }}
                        >
                          <MenuItem value={true}>Publicado</MenuItem>
                          <MenuItem value={false}>Em rascunho</MenuItem>
                        </Select>
                      </FormControl>
                    ) : dados2.is_published ? (
                      'Publicado'
                    ) : (
                      'Em rascunho'
                    )}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="content__image">
              <div className="img__about1">
                {update && (
                  <>
                    <label
                      for="input-file-image"
                      className="campodemandarimagem"
                    >
                      <PublishIcon className="up" />{' '}
                      {dados2.image_description_url && dados2.image_description
                        ? 'Substituir imagem'
                        : 'Adicionar imagem'}
                    </label>
                    <input
                      type="file"
                      id="input-file-image"
                      onChange={handleChangeImage}
                      accept=".png,.jpg,.jpeg"
                    />
                  </>
                )}{' '}
                {dados.event.image_description_url && files[0] ? (
                  <img src={imageUrl(dados.event?.image_description)} alt="" />
                ) : dados.event.image_description === null && !files ? (
                  <img src={defaultEventImage} alt="" />
                ) : (
                  <img src={imageUrl(dados2.image_description)} alt="" />
                )}
              </div>

              <div className="img__about2">
                {dados.event.image_description2 && filesSecondary[0] ? (
                  <img src={imageUrl(dados.event?.image_description2)} alt="" />
                ) : dados.event.image_description2 === null &&
                  !filesSecondary ? (
                  <img src={defaultEventImage} alt="" />
                ) : (
                  <img src={imageUrl(dados2.image_description2_url)} alt="" />
                )}
                {update && (
                  <>
                    <label
                      for="input-file-image2"
                      className="campodemandarimagem"
                    >
                      <PublishIcon />{' '}
                      {dados2.image_description2_url &&
                      dados2.image_description2
                        ? 'Substituir imagem'
                        : 'Adicionar imagem'}
                    </label>
                    <input
                      type="file"
                      id="input-file-image2"
                      onChange={handleChangeImageSecondary}
                      accept=".png,.jpg,.jpeg"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <br />
          <br />
          {update ? (
            <div
              style={{
                border: '1px solid rgba(0,0,0,0.2)',
                margin: '2rem 0rem',
                padding: '5px',
                borderRadius: '5px',
              }}
            >
              <Editor
                toolbar={{
                  options: [
                    //'fontSize',
                    'inline',
                    'emoji',
                    'list',
                    'textAlign',
                    'colorPicker',
                    'image',
                    'link',
                    'blockType',
                    'fontFamily',
                    'embedded',
                    'remove',
                    'history',
                  ],
                }}
                defaultEditorState={
                  update
                    ? EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML(
                            validateHtml(dados2.description)
                              ? dados2.description
                              : '',
                          ),
                        ),
                      )
                    : textdescription.description
                  // :  dados.description
                }
                // editorState={textdescription.description}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
                style={{ minHeight: '500px' }}
                placeholder="Escreva aqui a descrição..."
              />
            </div>
          ) : // <Editor
          //   apiKey="0plnsczuxljhgcrr0scu99u4eepex4j6sqi02uav93i8514p"
          //   initialValue={
          //     dados2.description ? dados2.description : defaultDescription
          //   }
          //   init={{
          //     height: 500,
          //     menubar: false,
          //     plugins: [
          //       'advlist autolink lists link image',
          //       'charmap print preview anchor help',
          //       'searchreplace visualblocks code',
          //       'insertdatetime media table paste wordcount',
          //     ],
          //     toolbar:
          //       'undo redo | formatselect | bold italic |  alignleft aligncenter alignright |  bullist numlist outdent indent | help',
          //   }}
          //   name="description"
          //   onChange={editorState}
          // />
          dados2.description ? (
            <div
              className="content__description"
              dangerouslySetInnerHTML={{
                __html: dados2.description.replace(/\n/g, '<br />'),
              }}
            ></div>
          ) : (
            <div
              className="content__description"
              dangerouslySetInnerHTML={{
                __html:
                  process.env.REACT_APP_TYPE_SITE === 'isp'
                    ? defaultDescription.replace(/\n/g, '<br />')
                    : defaultDescriptionSolar.replace(/\n/g, '<br />'),
              }}
            ></div>
          )}
        </div>
      </form>
    </Container>
  );
}

export default DetalhesEvento;
