const Al = (props) => {
  return (
    <svg
      width={200}
      height={200}
      viewBox="0 0 52.917 52.917"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        style={{
          fill: 'red',
          strokeWidth: 2.54551,
        }}
        d="M180.39 49.334c-5.1.039-9.857.909-12.925 2.55-3.176 1.7-6.498 1.791-9.244.255-7.373-4.127-22.743 4.795-26.893 15.609-.87 2.27-6.135 5.353-11.7 6.852-5.563 1.498-10.627 4.134-11.253 5.859-.885 2.442-2.273 2.469-6.268.117-5.379-3.167-15.898-1.814-15.898 2.045 0 3.544-5.542 2.628-10.332-1.707-2.404-2.175-6.79-4.137-9.746-4.36-2.957-.222-5.377-1.821-5.377-3.552 0-6.033-16.036-15.807-23.545-14.352-5.178 1.004-7.002.343-7.002-2.539 0-2.338-5.596 1.484-14 9.559-7.7 7.399-14 14.143-14 14.988 0 .846 4.868 3.898 10.818 6.781 5.95 2.884 14.255 7.425 18.455 10.092 4.2 2.667 11.882 6.094 17.069 7.615 5.187 1.522 10.959 4.168 12.826 5.881 5.233 4.801 14.195 9.479 18.16 9.479 1.967 0 4.227 2.59 5.022 5.754 1.417 5.647 13.191 14.538 18.199 13.742 1.4-.222 3.613 2.029 4.92 5.004v.002c2.307 5.252 14.17 7.939 14.17 3.209 0-1.21 5.45-8.227 12.113-15.592 6.662-7.365 14.668-16.988 17.791-21.383 3.123-4.395 9.413-11.891 13.979-16.66 11.329-11.835 17.27-19.298 25.267-31.744l6.797-10.576-5.518-1.48c-3.744-1.005-7.918-1.478-11.884-1.448zm-80.65 43.44h7.569l8.197 22.19v-22.19h6.564v20.572h11.825v4.93h-23.963l-1.772-5.151h-9.45l-1.77 5.15h-6.62l9.42-25.502zm3.696 6.523-3.131 9.146h6.261l-3.13-9.146z"
        transform="scale(.26458)"
      />
    </svg>
  );
};

export default Al;
