import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import SendIcon from '@material-ui/icons/Send';

import { Container } from './styles';
import { format, isAfter, addDays } from 'date-fns';

// dropdown
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

// Ícones
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
// icone do excel
import excelicon from '../../../files/images/excel-icon.png';

import xlsx from 'json-as-xlsx';

// crud
// import Customerscrud from '../Customerscrud';
import Listclientsview from '../../../components/Listclientsview';

// api
import API from '../../../services/api';

// dialogo de confirmacao
import Confirmdialog from '../../../components/Confirmdialog';

// inscricao
// import Inscricao from '../../../components/Inscricao/index_new_old';
import Inscricao from '../../../components/Inscricao';

// backdrop
import Backdrop from '../../../components/Backdrop';

// mascaras
import MaskedInput from 'react-text-mask';

// alert
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { toast } from 'react-toastify';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

// mascara do cnpj

function CnpjMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      // showMask
      // required
    />
  );
}

function SubscriptionMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[A-Z0-9]/,
        /[A-Z0-9]/,
        /[A-Z0-9]/,
        /[A-Z0-9]/,
        /[A-Z0-9]/,
        /[A-Z0-9]/,
        /[A-Z0-9]/,
        '-',
        /[A-Z0-9]/,
        /[A-Z0-9]/,
        /[A-Z0-9]/,
      ]}
      placeholderChar={'\u2000'}
      // showMask
      // required
    />
  );
}

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 900,
  },
  linhas: {
    '& .MuiTableCell-alignLeft': {
      padding: '0rem !important',
    },
    '& .MuiTableRow-root': {
      // borderBottom: '2px solid #151b26',
      margin: ' 1rem !important',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.08)',
        // borderLeft:'5px solid rgba(0,0,0,0.08)'
        borderLeft: '5px solid #151b26',
      },
    },
  },
  listagem: {
    '& .MuiTableCell-root': {
      padding: '0rem 0.8rem',
    },
    '& .MuiIconButton-root': {
      margin: '0rem',
    },
  },
  newClient: {
    display: 'flex',
    padding: '1rem',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    '& a': {
      textDecoration: 'none',
      color: 'initial',
    },
    '& .MuiButton-root': {
      color: 'rgba(255,255,255)',
      backgroundColor: 'rgba(55, 63, 81)',
      //   fontFamily:"'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      fontSize: '12px',
      padding: '0.5rem 1rem',
    },
  },
  newSearch: {
    '& .MuiSelect-root': {
      color: 'rgba(0,0,0,0.5)',
    },
  },
  inputFiltro: {
    color: 'rgba(0,0,0,0.5)',
    '& .MuiOutlinedInput-root': {
      borderRadius: '0px 5px 5px 0px',
    },
  },
});

export default function Customerslist(props) {
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [filtro, setFiltro] = React.useState({
    tipo: '',
    descricao: '',
  });

  const [newFilter, setNewFilter] = React.useState({
    is_exhibitor: null,
    name: null,
    cpf: null,
    id: null,
    state: null,
    city: null,
    category: null,
    event_id: null,
    is_national_event: null,
    participated: null,
    type_inscription: null,
  });

  const [viewclient, setViewclient] = React.useState({
    status: false,
    dados: '',
  });

  const [listagem, setListagem] = React.useState([]);
  const [totalItems, setTotalItems] = React.useState(0);
  const [setLastPage] = React.useState(0);

  const [listaeventos, setListaeventos] = React.useState([]);
  const [listaeventosantigos, setListaeventosantigos] = React.useState([]);
  const [listaeventosnovos, setListaeventosnovos] = React.useState([]);

  // dados informações gerais

  const [backdrop, setBackdrop] = React.useState(true);

  const [confirmacao, setConfirmacao] = React.useState([false, '']);

  const [open, setOpen] = React.useState({
    status: false,
    type: '',
    message: '',
  });

  const [eventoanterior, setEventoanterior] = React.useState(true);
  const [proximoevento, setProximoevento] = React.useState(true);
  const [novainscricao, setNovainscricao] = React.useState(false);
  const [iniciaredicao, setIniciaredicao] = React.useState();
  const [presentes, setPresentes] = React.useState(true);
  const [naopresentes, setNaopresentes] = React.useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    atualizarLista({ pageChange: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    atualizarLista({ rowsPerPageChange: parseInt(event.target.value, 10) });
  };

  const atualizarLista = useCallback(
    (dataPages) => {
      setBackdrop(true);

      console.log('Página: ', dataPages);

      const config = {
        headers: {
          Authorization: 'Bearer ' + props.user.token,
          'Content-Type': 'application/json',
        },
        params: {
          rows: dataPages?.rowsPerPageChange || rowsPerPage,
          page: dataPages?.pageChange >= 0 ? dataPages?.pageChange : page,
          where: JSON.stringify(newFilter),
        },
      };
      API.get('enrollments/paginated', config)
        .then((a) => {
          setListagem(a.data?.enrollments);
          setTotalItems(a.data?.total_items);
          setLastPage(a.data?.last_page);

          setBackdrop(false);
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => setBackdrop(false));
    },
    [props.user.token, setBackdrop, newFilter, page, rowsPerPage, setLastPage]
  );

  const atualizarEventos = useCallback(() => {
    setBackdrop(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };
    API.get('events', config)
      .then((a) => {
        setListaeventosantigos(a.data.previousEvents);
        setListaeventosnovos(a.data.nextEvents);
        setListaeventos([...a.data.nextEvents, ...a.data.previousEvents]);
      })
      .catch((error) => {
        console.log(error.response);
      })
      .finally(() => setBackdrop(false));
  }, [props.user.token, setBackdrop]);

  // excluir paciente
  const submeterDelet = (e) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    API.delete(`enrollments/${e}`, config)
      .then((e) => {
        // console.log(e.data);
        setConfirmacao([false, '']);
        atualizarLista();
      })
      .catch((error) => {
        console.log(error.data);
      });
  };

  const resendMessage = (id) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    const body = {
      enrollment_id: id,
    };

    setBackdrop(true);

    API.post('/enrollments/send-message', body, config)
      .then((response) => {
        console.log(response.data);
        toast.success('Mensagem de confirmação reenviada!');
        setBackdrop(false);
      })
      .catch((erro) => console.log(erro))
      .finally(() => setBackdrop(false));
  };

  let lista2 = [...listagem];

  var convert = function () {
    var columns = [
      {
        label: 'Data de Inscrição',
        value: (row) =>
          format(new Date(row.created_at), 'dd/MM/yyyy') +
          ' às ' +
          format(new Date(row.created_at), 'HH:mm'),
      }, // Top level data
      { label: 'Cód. Inscrição', value: (row) => row.id }, // Top level data
      { label: 'Nome', value: (row) => row.name }, // Run functions
      { label: 'E-mail', value: (row) => row.email }, // Run functions
      { label: 'CPF', value: (row) => row.cpf || ' - ' }, // Run functions
      { label: 'RNE', value: (row) => row.rne || ' - ' }, // Run functions
      { label: 'Telefone', value: (row) => row.phone }, // Run functions
      { label: 'Estado', value: (row) => row.state }, // Run functions
      { label: 'Cidade', value: (row) => row.city }, // Run functions
      { label: 'Bairrro', value: (row) => row.district }, // Run functions
      { label: 'Endereço', value: (row) => row.street }, // Run functions
      { label: 'Número', value: (row) => row.house_number }, // Run functions
      {
        label: 'Complemento',
        value: (row) => row.complement || '-',
      }, // Run functions
      {
        label: 'Categoria',
        value: (row) => row.category || 'Normal',
      },
      {
        label: 'Visitante',
        value: (row) => (row.is_exhibitor ? 'Não' : 'Sim'),
      }, // Run functions
      { label: 'Nome da Credencial', value: (row) => row.credential_name }, // Run functions
      { label: 'Empresa/Instituição', value: (row) => row.credential_company }, // Run functions
      { label: 'Ocupação/Cargo', value: (row) => row.credential_role }, // Run functions
      //{ label: 'Tipo de Inscrição', value: (row) => row.type_inscription }, // Run functions

      {
        label: 'Tipo de Inscrição',
        value: (row) =>
          row.type_inscription
            ? row.type_inscription === 'Outro'
              ? `Outro - ${row.another_type_inscription || 'Não informado'}`
              : row.type_inscription
            : 'Não informado',
      },
      {
        label: 'Aceitou os termos de uso',
        value: (row) => (row.agree_privacy_policies ? 'Sim' : 'Não'),
      },
      {
        label: 'Permissão para usar imagem',
        value: (row) => (row.allow_use_image ? 'Sim' : 'Não'),
      },
      {
        label: 'Quem indicou',
        value: (row) =>
          row.who_indicated
            ? row.who_indicated === 'Outro'
              ? `Outro - ${row.another_indication || 'Não informado'}`
              : row.who_indicated
            : 'Não informado',
      },
      {
        label: 'Presente',
        value: (row) =>
          listaeventos.filter((e) => e.id === row.event_id)[0].start_date
            ? !isAfter(
                new Date(
                  listaeventos.filter(
                    (e) => e.id === row.event_id
                  )[0].start_date
                ),
                new Date()
              )
              ? row.participated
                ? 'Sim'
                : 'Não'
              : ' - '
            : ' - ',
      },
      {
        label: 'Serviços de IPTV, telefonia, etc',
        value: (row) => row.has_service_tv_iptv,
      }, // Run functions
      {
        label: 'Rede de fibra óptica FTTx',
        value: (row) => (row.has_fiber_optic ? 'Sim' : 'Não'),
      }, // Run functions
      { label: 'Porte da empresa', value: (row) => row.company_size }, // Run functions
      {
        label: 'Quantidade de link contratado',
        value: (row) => row.contracted_link,
      }, // Run functions

      // { label: 'Tipo', value: row => (row[2]) }, // Deep props
      // { label: 'Coordenadas', value: row => (row[3])},
      // { label: 'UTMY-N', value: row => (row[4])},
      // { label: 'UTMX-E', value: row => (row[5])}
    ];

    var content = lista2;

    let data = [
      {
        sheet: 'Mailing',
        columns: columns,
        content: content,
      },
    ];

    var settings = {
      fileName: `Relatório ${filtro.tipo ? filtro.tipo : 'Geral'}${
        filtro.descricao ? ' - ' + filtro.descricao : ''
      } gerado em ${
        format(new Date(), 'dd/MM/yyyy hh') + 'h' + format(new Date(), 'mm')
      }`, // The name of the spreadsheet
      extraLength: 3, // A bigger number means that columns should be wider
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };
    var download = true; // If true will download the xlsx file, otherwise will return a buffer

    xlsx(data, settings);
  };

  let evento_filtro_prev = listaeventos.filter((e) => {
    if (e.id === filtro.descricao) return e;
    return false;
  });

  let evento_selecionado =
    filtro.tipo === 'evento' && evento_filtro_prev.length === 1
      ? evento_filtro_prev[0].start_date
      : '';
  let evento_passado =
    filtro.tipo === 'evento' && evento_filtro_prev.length === 1
      ? isAfter(new Date(evento_selecionado), addDays(new Date(), 1))
      : '';

  React.useEffect(
    (e) => {
      if (
        document.querySelector('.MuiTablePagination-caption').textContent !==
        'Linhas por página'
      ) {
        document.querySelector('.MuiTablePagination-caption').textContent =
          'Linhas por página';
      }
      if (listagem.length === 0) atualizarLista();
      if (listaeventos.length === 0) atualizarEventos();
    },
    [atualizarEventos, atualizarLista, listaeventos.length, listagem.length]
  );

  function handleFilter() {
    atualizarLista();
  }

  return (
    <Container>
      <TableContainer
        component={Paper}
        minWidth={915}
        style={{
          backgroundColor: '#fff',
          // backgroundColor:'transparent',
          border: '0px',
          borderRadius: '6px',
          boxShadow: '0px 0px 12px rgba(0,0,0,0.2)',
          //  maxWidth:'1000px',
          margin: 'auto',
        }}
      >
        <div className="campos_de_busca">
          <div className={classes.newClient} style={{ paddingTop: '2rem' }}>
            <div className="filterInput">
              <div className="selectMenu2">
                Evento:
                <div className="selecao">
                  <input
                    type="radio"
                    id="todos"
                    checked={newFilter.is_national_event === null}
                    onChange={() =>
                      setNewFilter({ ...newFilter, is_national_event: null })
                    }
                  />
                  <label for="todos">Todos</label>
                </div>
                <div className="selecao">
                  <input
                    type="radio"
                    id="expedicao"
                    checked={newFilter.is_national_event === false}
                    onChange={() =>
                      setNewFilter({ ...newFilter, is_national_event: false })
                    }
                  />
                  <label for="expedicao">Expedição</label>
                </div>
                <div className="selecao">
                  <input
                    type="radio"
                    id="nacional"
                    checked={newFilter.is_national_event === true}
                    onChange={() =>
                      setNewFilter({ ...newFilter, is_national_event: true })
                    }
                  />
                  <label for="nacional">Nacional</label>
                </div>
              </div>

              <div className="selectMenu2">
                Inscrição:
                <div className="selecao">
                  <input
                    type="radio"
                    id="todos_tipo"
                    name="set_evento"
                    checked={
                      newFilter.category === null &&
                      newFilter.type_inscription === null
                    }
                    onChange={() =>
                      setNewFilter({
                        ...newFilter,
                        category: null,
                        type_inscription: null,
                      })
                    }
                  />
                  <label for="todos_tipo">Todos</label>
                </div>
                <div className="selecao">
                  <input
                    type="radio"
                    id="normal"
                    checked={newFilter.category === 'normal'}
                    onChange={() =>
                      setNewFilter({
                        ...newFilter,
                        category: 'normal',
                        type_inscription: null,
                      })
                    }
                  />
                  <label for="normal">Normal</label>
                </div>
                <div className="selecao">
                  <input
                    type="radio"
                    id="VIP"
                    name="set_evento"
                    checked={newFilter.category === 'vip'}
                    onChange={() =>
                      setNewFilter({
                        ...newFilter,
                        category: 'vip',
                        type_inscription: null,
                      })
                    }
                  />
                  <label for="VIP">VIP</label>
                </div>
                <div className="selecao">
                  <input
                    type="radio"
                    id="Expositor"
                    name="set_evento"
                    checked={newFilter.type_inscription === 'Expositor'}
                    onChange={() =>
                      setNewFilter({
                        ...newFilter,
                        type_inscription: 'Expositor',
                        category: null,
                      })
                    }
                  />
                  <label for="Expositor">Expositor</label>
                </div>
                <div className="selecao">
                  <input
                    type="radio"
                    id="Palestrante"
                    name="set_evento"
                    checked={newFilter.type_inscription === 'Palestrante'}
                    onChange={() =>
                      setNewFilter({
                        ...newFilter,
                        type_inscription: 'Palestrante',
                        category: null,
                      })
                    }
                  />
                  <label for="Palestrante">Palestrante</label>
                </div>
              </div>

              {/* <label style={{fontWeight:'bold', color:'rgba(0,0,0,0.5)', marginTop:'1.25rem', display:'inline-block'}}>Filtro: </label> */}
              <FormControl
                variant=""
                margin="dense"
                style={{
                  margin: '0rem 1rem',
                  marginTop: '-0.0rem',
                  width: '200px',
                  textAlign: 'left',
                }}
                className={classes.newSearch}
              >
                <InputLabel id="id-selctor-zone">
                  {'Tipos de Filtro'}
                </InputLabel>
                <Select
                  labelId="id-selctor-zone"
                  id="id-selctor-zone-select"
                  value={filtro.tipo}
                  onChange={(e) => {
                    setFiltro({
                      ...filtro,
                      tipo: e.target.value,
                      descricao: '',
                    });
                    setNewFilter({
                      is_exhibitor: null,
                      name: null,
                      cpf: null,
                      id: null,
                      state: null,
                      city: null,
                      category: null,
                      event_id: null,
                      is_national_event: null,
                      participated: null,
                    });
                    setPresentes(true);
                    setNaopresentes(true);
                  }}
                  label="Tipo"
                >
                  <MenuItem value="" default>
                    <em>Nenhum</em>
                  </MenuItem>
                  <MenuItem value={'evento'}>Evento</MenuItem>
                  <MenuItem value={'nome'}>Nome</MenuItem>
                  <MenuItem value={'cpf'}>CPF</MenuItem>
                  <MenuItem value={'subscription'}>
                    Numero de inscrição
                  </MenuItem>
                  <MenuItem value={'cidade'}>Cidade</MenuItem>
                  <MenuItem value={'estado'}>Estado</MenuItem>
                  {/* <MenuItem value={'informacoes-gerais'}>
                    Informações Gerais
                  </MenuItem> */}
                </Select>
              </FormControl>

              {filtro.tipo === 'cpf' ? (
                <TextField
                  id="cnpj-campo"
                  label="CPF"
                  //   defaultValue="Hello World"
                  //   variant="outlined"
                  value={newFilter.cpf}
                  onKeyUp={() => setPage(0)}
                  onChange={
                    //filtrodig
                    (e) =>
                      setNewFilter({
                        is_national_event: newFilter.is_national_event,
                        category: newFilter.category,
                        cpf: e.target.value,
                      })
                  } // onChange={e=>setDados({...dados, cpf:e.target.value})}
                  InputProps={{
                    inputComponent: CnpjMaskCustom,
                  }}
                  style={{
                    margin: '0rem 0rem',
                    marginLeft: '0rem',
                    width: '250px',
                  }}
                  margin="dense"
                />
              ) : filtro.tipo === 'subscription' ? (
                <TextField
                  id="subscription-campo"
                  label="Inscrição"
                  value={newFilter.id}
                  onKeyUp={() => setPage(0)}
                  onChange={(e) =>
                    setNewFilter({
                      is_national_event: newFilter.is_national_event,
                      category: newFilter.category,
                      id: e.target.value,
                    })
                  } // onChange={e=>setDados({...dados, cpf:e.target.value})}
                  InputProps={{
                    inputComponent: SubscriptionMaskCustom,
                  }}
                  style={{
                    margin: '0rem 0rem',
                    marginLeft: '0rem',
                    width: '250px',
                  }}
                  margin="dense"
                />
              ) : filtro.tipo === 'evento' ? (
                <FormControl
                  margin="dense"
                  style={{
                    margin: '0rem 0rem',
                    marginTop: '0.0rem',
                    width: '250px',
                  }}
                >
                  <InputLabel id="demo-simple-select-outlined-label-tipo">
                    {'Selecionar'}
                  </InputLabel>
                  <Select
                    labelId="demo-curso-select-outlined-label-tipo"
                    id="demo-simple-curso-outlined-tipo"
                    value={newFilter.event_id}
                    onChange={(e) => {
                      setFiltro({ ...filtro, descricao: e.target.value });
                      setNewFilter({
                        is_national_event: newFilter.is_national_event,
                        category: newFilter.category,
                        participated: newFilter.participated,
                        event_id: e.target.value,
                      });
                      setPresentes(true);
                      setNaopresentes(true);
                    }}
                    label="evento"
                    // style={{padding:'0rem 1rem'}}
                  >
                    <MenuItem value="">
                      <em>Nenhum</em>
                    </MenuItem>
                    {}
                    {(proximoevento && eventoanterior
                      ? listaeventos
                      : proximoevento
                      ? listaeventosnovos
                      : eventoanterior
                      ? listaeventosantigos
                      : []
                    )
                      .filter((value) => {
                        if ('' + newFilter.is_national_event === 'null') {
                          return true;
                        } else if (
                          '' + newFilter.is_national_event ===
                          'false'
                        ) {
                          if (!Boolean(value.is_national_event)) {
                            return true;
                          } else {
                            return false;
                          }
                        } else if (
                          '' + newFilter.is_national_event ===
                          'true'
                        ) {
                          if (Boolean(value.is_national_event)) {
                            return true;
                          } else {
                            return false;
                          }
                        }
                      })
                      .map((e, i) => (
                        <MenuItem value={e.id}>
                          {e.start_date
                            ? format(new Date(e.start_date), 'dd/MM/yyyy') +
                              ' - ' +
                              e.city +
                              (e.is_national_event
                                ? ' - Nacional'
                                : ' - Expedição')
                            : '(Sem Data) - ' +
                              e.city +
                              (e.is_national_event
                                ? ' - Nacional'
                                : ' - Expedição')}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              ) : filtro.tipo === 'estado' ? (
                <FormControl
                  variant="outlined"
                  margin="dense"
                  style={{
                    margin: '0rem 0rem',
                    marginTop: '0.5rem',
                    width: '250px',
                  }}
                >
                  <InputLabel id="demo-simple-select-outlined-label-tipo">
                    {'Estado'}
                  </InputLabel>
                  <Select
                    labelId="demo-curso-select-outlined-label-tipo"
                    id="demo-simple-curso-outlined-tipo"
                    value={newFilter.state}
                    onChange={
                      //filtrodig
                      (e) =>
                        setNewFilter({
                          is_national_event: newFilter.is_national_event,
                          category: newFilter.category,
                          state: e.target.value,
                        })
                    }
                    label="evento"
                    // style={{padding:'0rem 1rem'}}
                  >
                    <MenuItem value="">
                      <em>Nenhum</em>
                    </MenuItem>
                    <MenuItem value="AC">AC</MenuItem>
                    <MenuItem value="AL">AL</MenuItem>
                    <MenuItem value="AP">AP</MenuItem>
                    <MenuItem value="AM">AM</MenuItem>
                    <MenuItem value="BA">BA</MenuItem>
                    <MenuItem value="CE">CE</MenuItem>
                    <MenuItem value="DF">DF</MenuItem>
                    <MenuItem value="ES">ES</MenuItem>
                    <MenuItem value="GO">GO</MenuItem>
                    <MenuItem value="MA">MA</MenuItem>
                    <MenuItem value="MT">MT</MenuItem>
                    <MenuItem value="MS">MS</MenuItem>
                    <MenuItem value="MG">MG</MenuItem>
                    <MenuItem value="PA">PA</MenuItem>
                    <MenuItem value="PB">PB</MenuItem>
                    <MenuItem value="PR">PR</MenuItem>
                    <MenuItem value="PE">PE</MenuItem>
                    <MenuItem value="PI">PI</MenuItem>
                    <MenuItem value="RJ">RJ</MenuItem>
                    <MenuItem value="RN">RN</MenuItem>
                    <MenuItem value="RS">RS</MenuItem>
                    <MenuItem value="RO">RO</MenuItem>
                    <MenuItem value="RR">RR</MenuItem>
                    <MenuItem value="RC">SC</MenuItem>
                    <MenuItem value="SP">SP</MenuItem>
                    <MenuItem value="SE">SE</MenuItem>
                    <MenuItem value="TO">TO</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  onChange={
                    //filtrodig
                    filtro.tipo === 'nome'
                      ? (e) =>
                          setNewFilter({
                            is_national_event: newFilter.is_national_event,
                            category: newFilter.category,
                            name: e.target.value,
                          })
                      : filtro.tipo === 'cidade'
                      ? (e) =>
                          setNewFilter({
                            is_national_event: newFilter.is_national_event,
                            category: newFilter.category,
                            city: e.target.value,
                          })
                      : null
                  }
                  value={
                    filtro.tipo === 'nome'
                      ? newFilter.name
                      : filtro.tipo === 'cidade'
                      ? newFilter.city
                      : filtro.descricao
                  }
                  id="outlined-basic"
                  label={
                    filtro.tipo
                      ? filtro.tipo === 'nome'
                        ? 'Nome'
                        : filtro.tipo === 'cpf'
                        ? 'CPF'
                        : filtro.tipo === 'evento'
                        ? 'evento'
                        : filtro.tipo === 'cidade'
                        ? 'Cidade'
                        : filtro.tipo === 'estado'
                        ? 'Estado'
                        : filtro.tipo === 'inscrição'
                        ? 'Numero de inscrição'
                        : 'Selecionar Filtro'
                      : 'Selecione o filtro'
                  }
                  disabled={filtro.tipo ? false : true}
                  margin="dense"
                  className={classes.inputFiltro}
                  style={{
                    margin: '0rem 0rem',
                    marginLeft: '0rem',
                    width: '250px',
                  }}
                  onKeyUp={() => setPage(0)}
                />
              )}
              <button className="searchButton" onClick={handleFilter}>
                <SearchIcon className="searchButtonIcon" /> Filtrar
              </button>
            </div>

            <div className="MenuDireita">
              <button
                className="botaoBaixarExcel"
                onClick={() => props.user.is_admin && convert()}
                style={{
                  opacity: !props.user.is_admin && 0.5,
                  pointerEvents: !props.user.is_admin && 'none',
                }}
              >
                {' '}
                <img src={excelicon} alt="Baixar" />
                Baixar Relatório
              </button>
              <button
                className="cadastroManual"
                onClick={() => setNovainscricao(true)}
              >
                {' '}
                + Nova Inscrição
              </button>
            </div>
          </div>
          {/* <Link to="/clientes/adicionar" >
              <Button variant="contained" style={{backgroundColor:'#f2a100'}} >Nova Inscrição</Button>
          </Link> */}
        </div>

        {filtro.tipo === 'evento' && (
          <div className="evento_options">
            <div className="selectMenu2">
              Período:
              {/* <div className="selecao">
                <input
                  type="checkbox"
                  id="next_events"
                  name="set_evento"
                  value="next_events"
                  checked={proximoevento}
                  onChange={() => setProximoevento(!proximoevento)}
                />
                <label for="next_events">Todos</label>
              </div> */}
              <div className="selecao">
                <input
                  type="checkbox"
                  id="next_events"
                  name="set_evento"
                  value="next_events"
                  checked={proximoevento}
                  onChange={() => setProximoevento(!proximoevento)}
                />
                <label for="next_events">Próximos Eventos</label>
              </div>
              <div className="selecao">
                <input
                  type="checkbox"
                  id="previews_events"
                  name="set_evento"
                  value="previews_events"
                  checked={eventoanterior}
                  onChange={() => setEventoanterior(!eventoanterior)}
                />
                <label for="previews_events">Eventos Anteriores</label>
              </div>
            </div>

            <div className="selectMenu2">
              Participação:
              <div className="selecao">
                <input
                  type="radio"
                  id="all_events"
                  //name="set_presente"
                  //value="previews_events"
                  checked={newFilter.participated === null}
                  onChange={() => {
                    setPresentes(!presentes);
                    setNewFilter({ ...newFilter, participated: null });
                  }}
                  disabled={
                    filtro.tipo === 'evento' &&
                    evento_filtro_prev.length === 1 &&
                    !evento_passado &&
                    evento_filtro_prev[0].start_date
                      ? false
                      : true
                  }
                />
                <label for="all_events">Todos</label>
              </div>
              <div className="selecao">
                <input
                  type="radio"
                  id="presente_events"
                  //name="set_presente"
                  //value="previews_events"
                  checked={newFilter.participated === true}
                  onChange={() => {
                    setPresentes(!presentes);
                    setNewFilter({ ...newFilter, participated: true });
                  }}
                  disabled={
                    filtro.tipo === 'evento' &&
                    evento_filtro_prev.length === 1 &&
                    !evento_passado &&
                    evento_filtro_prev[0].start_date
                      ? false
                      : true
                  }
                />
                <label for="presente_events">Presentes</label>
              </div>
              <div className="selecao">
                <input
                  type="radio"
                  id="nao_presente_events"
                  //name="set_nao_presente"
                  //value="previews_events"
                  checked={newFilter.participated === false}
                  onChange={() => {
                    setNaopresentes(!naopresentes);
                    setNewFilter({ ...newFilter, participated: false });
                  }}
                  disabled={
                    filtro.tipo === 'evento' &&
                    evento_filtro_prev.length === 1 &&
                    !evento_passado &&
                    evento_filtro_prev[0].start_date
                      ? false
                      : true
                  }
                />
                <label for="nao_presente_events">Não Presentes</label>
              </div>
            </div>
          </div>
        )}

        <br />
        <Table className={classes.table} aria-label="custom pagination table">
          <div
            className="ul tituloTabela"
            style={{ pointerEvents: 'none !important' }}
          >
            <div className="li" style={{ flex: 3 }}>
              Nome
            </div>

            <div className="li" style={{ flex: 1.5 }}>
              Status
            </div>

            <div className="li" style={{ flex: 2 }}>
              CPF
            </div>
            <div className="li" style={{ flex: 2 }}>
              Telefone
            </div>
            <div className="li" style={{ flex: 1 }}>
              Data
            </div>

            <div className="li" style={{ flex: 1 }}>
              Opções
            </div>
          </div>
          <TableBody className={classes.linhas}>
            {lista2.map((row) => (
              <div
                className="ul"
                key={row.id}
                style={
                  {
                    // borderLeft:  '2px solid #52D869',
                  }
                }
              >
                <div className="li" style={{ flex: 3 }}>
                  {row.name} <br />
                  <small
                    className={row.event.is_national_event ? 'smal1' : 'smal2'}
                  >
                    {row.event.is_national_event ? 'nacional' : 'regional'}
                  </small>
                  <small
                    className={
                      row.category === 'VIP' ? 'smallvip' : 'smallnormal'
                    }
                    style={{
                      textTransform:
                        row.category === 'VIP' ? 'uppercase' : 'lowercase',
                    }}
                  >
                    {row.type_inscription === 'Expositor' ||
                    row.type_inscription === 'Palestrante'
                      ? row.type_inscription
                      : row.category
                      ? row.category
                      : 'normal'}
                  </small>
                </div>

                <div className="li" style={{ flex: 1.5 }}>
                  <small
                    className={row.confirmed ? 'smallStatus' : 'smallResend'}
                    onClick={() =>
                      !row.confirmed ? resendMessage(row.id) : null
                    }
                  >
                    {!row.confirmed && <SendIcon />}
                    {row.confirmed ? 'Confirmado' : 'Reenviar'}
                  </small>
                </div>

                <div className="li" style={{ flex: 2 }}>
                  {row.cpf}
                </div>
                <div className="li" style={{ flex: 2 }}>
                  {row.phone}
                </div>
                <div className="li" style={{ flex: 1 }}>
                  {format(new Date(row.created_at), 'dd/MM/yyyy HH:mm')}
                </div>
                <div className="li" style={{ flex: 1 }}>
                  <IconButton
                    onClick={() =>
                      props.user.is_admin
                        ? setViewclient({ status: true, dados: row })
                        : true
                    }
                    disabled={!props.user.is_admin ? true : false}
                    style={{
                      opacity: !props.user.is_admin && 0.5,
                      pointerEvents: !props.user.is_admin && 'none',
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                  {/* <IconButton onClick={()=>setViewdados({status:true, id:row})}>
                   <EqualizerIcon/>
                 </IconButton> */}
                  <IconButton
                    onClick={() => {
                      props.user.is_admin && setIniciaredicao(row);
                      props.user.is_admin && setNovainscricao(true);
                    }}
                    disabled={!props.user.is_admin ? true : false}
                    // disabled={true} // Enquanto ajusto o formulário
                    style={{
                      opacity: !props.user.is_admin && 0.5,
                      pointerEvents: !props.user.is_admin && 'none',
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      props.user.is_admin
                        ? setConfirmacao([true, row.id])
                        : true
                    }
                    disabled={!props.user.is_admin ? true : false}
                    style={{
                      opacity: !props.user.is_admin && 0.5,
                      pointerEvents: !props.user.is_admin && 'none',
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  5, 10, 25, 50, 100, 300, 500,
                  //1000
                  // { label: 'Todos', value: totalItems }
                ]}
                colSpan={3}
                count={totalItems}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'Linhas por página' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
        {backdrop ? <Backdrop /> : ''}

        {viewclient.status ? (
          <Listclientsview
            user={props.user}
            dadosclient={viewclient.dados}
            closeDialog={() => {
              setViewclient({ status: false, dados: '' });
              atualizarLista();
            }}
            listaeventos={listaeventos}
          />
        ) : (
          ''
        )}

        {confirmacao[0] ? (
          <Confirmdialog
            tamanho="xs"
            message={`Tem certeza de que deseja deletar o inscrito ${
              lista2.find((e) => e.id === confirmacao[1]).name
            }?`}
            confirmar={() => {
              submeterDelet(confirmacao[1]);
            }}
            negar={() => setConfirmacao([false, ''])}
          />
        ) : (
          ''
        )}
        {/* {viewdados.status? <Listclientsdados  user={props.user} dados={viewdados.id} fechar={e=>setViewdados({...viewdados, status:false, id:''})}/>:''} */}
        <Snackbar
          open={open.status}
          autoHideDuration={6000}
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setOpen({ status: false, type: '', message: '' });
          }}
        >
          <Alert
            onClose={(event, reason) => {
              if (reason === 'clickaway') {
                return;
              }
              setOpen({ status: false, type: '', message: '' });
            }}
            severity={open.type}
          >
            {open.message}
          </Alert>
        </Snackbar>
      </TableContainer>

      {novainscricao ? (
        <Inscricao
          user={props.user}
          listaeventos={listaeventos}
          onInscricao={novainscricao}
          edicao={iniciaredicao}
          offInscricao={() => {
            setNovainscricao(false);
            atualizarLista();
            setIniciaredicao();
          }}
        />
      ) : (
        ''
      )}
    </Container>
  );
}
